import React, { useState, useContext, useEffect } from "react";
import {
  Col,
  Row,
  Form,
} from "react-bootstrap";
import CustomSelect from "../../../../atoms/CustomSelect";
import Buttons from "../../../../atoms/Buttons";
import { LanguageContext } from "../../../../containers/Language";
import ToggleSwitch from "../../../../atoms/ToggleSwitch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, filterArrayByKeyValue } from "../../../../../utils/utils";
import moment from "moment";
import { monthOptions } from "../../../../../utils/constUtil";
import { useSelector } from "react-redux";

let initialValues = {
  "fromDate": moment().startOf('month').format("YYYY-MM-DD"),
  "toDate": moment().endOf('month').format("YYYY-MM-DD"), 
  "is_compare": false,
  "year1": moment(new Date()).subtract(1, 'y').format("YYYY"),
  "year2": moment(new Date()).format("YYYY"),
  "month1": (new Date()).getMonth() + 1,
  "month2": (new Date()).getMonth() + 1,
  "groupBy": "week",
}
let firstLoad = true;
const JobsRevenueSearch = React.forwardRef(
  ({
    props,
    ref,
    tableColumn,
    setTableColumn,
    searchJobsAndRevenue, resetSearchFilter, setShowWarningModal, setIsCompared, isCompared
  }) => {
    const { localised } = useContext(LanguageContext);
    const groupbyOptions = [
      { value: "week", label: localised.weeks },
      { value: "quarter", label: localised.quarter },
    ];
    const [groupby, setGroupBy] = useState(groupbyOptions[0]);
    const [searchValues, setSearchValues] = useState(initialValues);
    const [fromDate, setFromDate] = React.useState(new Date(initialValues?.fromDate));
    const [toDate, setToDate] = React.useState(new Date(initialValues?.toDate));
    const [convFromDate, setconvFromDate] = useState(null);
    const [convtoDate, setconvtoDate] = useState(null);
    const [fromMonth, setFromMonth] = useState((filterArrayByKeyValue('value', (new Date()).getMonth() + 1, monthOptions)[0]));
    const [toMonth, setToMonth] = useState((filterArrayByKeyValue('value', (new Date()).getMonth() + 1, monthOptions)[0]));
    const dateFormat = useSelector((state) => state.authentication.profile.date_format);
    const globalRegionAreaFilter = useSelector((state) => state.global.globalRegionAreaFilter) || {};
    const [rangeFromMonth, setRangeFromMonth] = useState(
      monthOptions.find((m) => m.value === moment().month() + 1)
    );
    const [rangeFromYear, setRangeFromYear] = useState(
      new Date(moment().format("YYYY"), 0, 1)
    );
    const [rangeToMonth, setRangeToMonth] = useState(
      monthOptions.find((m) => m.value === moment().month() + 1)
    );
    const [rangeToYear, setRangeToYear] = useState(
      new Date(moment().format("YYYY"), 0, 1)
    );

    const onToggleChange1 = (checked) => {
      setTableColumn(checked);
      setIsCompared(!isCompared);
      setSearchValues({
        ...searchValues,
        is_compare: checked,
      });
    };

    const [showStartDate, setStartDate] = useState(new Date(initialValues.year1));
    const [showEndDate, setEndDate] = useState(new Date(initialValues.year2));
    const selectFromDateHandler = (selectedDate) => {
      let converted = '', finalbtndate = '', date = '';
      let new_date;
      converted = convertDate(selectedDate);
      setFromDate(selectedDate);
      if (convtoDate?.length === 0) {
        date = converted;
      } else {
        date = convtoDate
      }
      setconvFromDate(converted);
      if (toDate === null || selectedDate > toDate) {
        new_date = moment(selectedDate, "DD-MM-YYYY").add(1, 'days').format('YYYY/MM/DD');
        setToDate(new Date(new_date));
        setconvtoDate(new Date(new_date));
        date = new_date;
        setSearchValues({
          ...searchValues,
          fromDate: selectedDate ? moment(selectedDate).format('YYYY/MM/DD') : '',
          toDate: date ? moment(date).format('YYYY/MM/DD') : '',
        });
        return;
      }

      setSearchValues({
        ...searchValues,
        fromDate: selectedDate ? moment(selectedDate).format('YYYY/MM/DD') : '',
      });
    }

    const selectToDateHandler = (selectedDate) => {
      let converted = '', finalbtndate = '', date = '';
      let new_date;
      setToDate(selectedDate);
      converted = convertDate(selectedDate);
      if (convFromDate?.length === 0) {
        date = converted;
      } else {
        date = convFromDate
      }
      if (fromDate === null) {
        new_date = moment(selectedDate, "DD-MM-YYYY").subtract(1, 'days').format('YYYY/MM/DD');
        setFromDate(new Date(new_date));
        setconvFromDate(new Date(new_date));
        date = new_date;
      }
      setconvtoDate(converted);

      setSearchValues({
        ...searchValues,
        toDate: selectedDate ? moment(selectedDate).format('YYYY/MM/DD') : '',
      });
    }

    useEffect(() => {
        if (globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
          searchJobsRevenue();
        }
    }, [globalRegionAreaFilter]);

    const searchJobsRevenue = (e) => {
      e?.preventDefault();
      e?.stopPropagation();

      if (!tableColumn) {
        const fromYearVal = moment(rangeFromYear).format("YYYY");
        const toYearVal   = moment(rangeToYear).format("YYYY");

        const computedFromDate = moment(`${fromYearVal}-${rangeFromMonth?.value || 1}-01`, "YYYY-M-DD")
          .startOf("month")
          .format("YYYY-MM-DD");

        const computedToDate = moment(`${toYearVal}-${rangeToMonth?.value || 1}-01`, "YYYY-M-DD")
          .endOf("month")
          .format("YYYY-MM-DD");

        const newValues = {
          ...searchValues,
          fromDate: computedFromDate,
          toDate: computedToDate,
          is_compare: false,
          groupBy: groupby.value,
          area_id: globalRegionAreaFilter.area_id ? globalRegionAreaFilter.area_id.map(item => item.value) : [],
          region_id: globalRegionAreaFilter.region_id ? globalRegionAreaFilter.region_id.map(item => item.value) : [],       
        };
        
        setSearchValues(newValues);
        searchJobsAndRevenue(newValues);

      } else {
        const newValues = {
          ...searchValues,
          is_compare: true,
          groupBy: groupby.value,
          area_id: globalRegionAreaFilter.area_id ? globalRegionAreaFilter.area_id.map(item => item.value) : [],
          region_id: globalRegionAreaFilter.region_id ? globalRegionAreaFilter.region_id.map(item => item.value) : [], 
        };
        setSearchValues(newValues);
        searchJobsAndRevenue(newValues);
      }
    };

    const resetFilter = (e) => {
      setSearchValues(initialValues);
      setFromDate(new Date(initialValues?.fromDate));
      setToDate(new Date(initialValues?.toDate))
      setconvFromDate(null);
      setconvtoDate(null);
      setTableColumn(false);
      setFromMonth((filterArrayByKeyValue('value', (new Date()).getMonth() + 1, monthOptions)[0]));
      setToMonth((filterArrayByKeyValue('value', (new Date()).getMonth() + 1, monthOptions)[0]));
      setStartDate(new Date(initialValues.year1));
      setEndDate(new Date(initialValues.year2));
      setIsCompared(false);
      setRangeFromMonth(monthOptions.find(m => m.value === moment().month() + 1));
      setRangeToMonth(monthOptions.find(m => m.value === moment().month() + 1));
      setGroupBy(groupbyOptions[0]);
      resetSearchFilter();
    }


    const calculateDateDifference = (from, to) => {
      // To calculate the time difference of two dates 
      let Difference_In_Time = to.getTime() - from.getTime();

      // To calculate the no. of days between two dates 
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (!tableColumn && (Difference_In_Days > 30)) {
        setShowWarningModal(true);
      }
    }

    const handleMonth1Change = (selectedValue) => {
      setFromMonth(selectedValue);
      setSearchValues({
        ...searchValues,
        month1: selectedValue ? selectedValue?.value : null,
      });
    }

    const handleMonth2Change = (selectedValue) => {
      setToMonth(selectedValue);
      setSearchValues({
        ...searchValues,
        month2: selectedValue ? selectedValue?.value : null,
      });
    }

    const changeStartYear = (selectedValue) => {
      setStartDate(selectedValue)
      setSearchValues({
        ...searchValues,
        year1: moment(selectedValue).format('YYYY'),
      });
    }

    const changeEndYear = (selectedValue) => {
      setEndDate(selectedValue)
      setSearchValues({
        ...searchValues,
        year2: moment(selectedValue).format('YYYY'),
      });
    }

    const handleRangeFromMonth = (selected) => {
      setRangeFromMonth(selected);
    };

    const handleRangeFromYear = (dateObj) => {
      setRangeFromYear(dateObj);
    };

    const handleRangeToMonth = (selected) => {
      setRangeToMonth(selected);
    };

    const handleRangeToYear = (dateObj) => {
      setRangeToYear(dateObj);
    };

    const handleGroupByChange = (option) => {
      setGroupBy(option);
      setSearchValues({
        ...searchValues,
        groupBy: option?.value, 
      });
    };

    
    
    useEffect(() => {
      return () => {
        firstLoad = true;
      };
    }, []);


    useEffect(() => {
      if (!firstLoad) {
        calculateDateDifference(fromDate, toDate);
        searchJobsAndRevenue({ ...searchValues });
      }
      firstLoad = false;
    }, [tableColumn]);


    return (
      <div className="card">
      <div className="card-header">
        <h3 className="card-title">{localised.search}</h3>
      </div>
      <div className="card-body analytics-search">
        <Form onSubmit={searchJobsRevenue} noValidate>
        {!tableColumn && (
          <>
          <Row>
            <Col md="3" lg="3">
            <Form.Group className="form-group">
              <Form.Label>
              {localised.from} {localised.month}
              </Form.Label>
              <div className="month-year-select">
              <CustomSelect
                id="rangeFromMonth"
                name="rangeFromMonth"
                closeMenuOnSelect
                isMulti={false}
                options={monthOptions.filter(
                (option) =>
                  option.value <= rangeToMonth?.value ||
                  moment(rangeFromYear).year() <
                  moment(rangeToYear).year()
                )}
                placeholder="Month"
                value={rangeFromMonth}
                onChange={handleRangeFromMonth}
                isClearable={false}
              />
              <DatePicker
                selected={rangeFromYear}
                onChange={handleRangeFromYear}
                showYearPicker
                dateFormat="yyyy"
                placeholderText="Year"
                maxDate={rangeToYear}
              />
              </div>
            </Form.Group>
            </Col>

            <Col md="3" lg="3">
            <Form.Group className="form-group">
              <Form.Label>
              {localised.to} {localised.month}
              </Form.Label>
              <div className="month-year-select">
              <CustomSelect
                id="rangeToMonth"
                name="rangeToMonth"
                closeMenuOnSelect
                isMulti={false}
                options={monthOptions}
                placeholder="Month"
                value={rangeToMonth}
                onChange={handleRangeToMonth}
                isClearable={false}
              />
              <DatePicker
                minDate={rangeFromYear}
                selected={rangeToYear}
                onChange={handleRangeToYear}
                showYearPicker
                dateFormat="yyyy"
                placeholderText="Year"
              />
              </div>
            </Form.Group>
            </Col>
            <Col md="3" lg="3">
            <Form.Group className="form-group">
              <Form.Label>{localised.groupBy}</Form.Label>
              <CustomSelect
              id={"groupby"}
              name={"groupby"}
              closeMenuOnSelect={true}
              isMulti={false}
              options={groupbyOptions}
              value={groupby}
              isClearable={false}
              onChange={handleGroupByChange}
              />
            </Form.Group>
            </Col>
          </Row>
          </>
        )}
        <Row>
          <Col md="3" lg="3">
          <Form.Group className="form-group">
            <Form.Label>{localised.compare}</Form.Label>
            <div>
            <ToggleSwitch
              id="switchToggle1"
              checked={tableColumn}
              onChange={onToggleChange1}
              optionLabels={["ON", "OFF"]}
            />
            </div>
          </Form.Group>
          </Col>

          {!tableColumn && (
          <Col
            md="12"
            lg="9"
            className="d-flex align-items-end justify-content-md-end form-btns mt-3"
          >
            <Form.Group className="form-group">
            <div className="search-btn-link">
              <Buttons
              variant="secondary"
              title={localised.reset}
              className="button-icon"
              onClick={resetFilter}
              />
              <Buttons
              variant="primary"
              title={localised.search}
              className="ms-2"
              type="submit"
              />
            </div>
            </Form.Group>
          </Col>
          )}
        </Row>
        {tableColumn && (
          <>
          <Row>
            <Col md="3" lg="3">
            <Form.Group className="form-group">
              <Form.Label>{localised.monthYear1}</Form.Label>
              <div className="month-year-select">
              <CustomSelect
                id={"months"}
                name={"months"}
                closeMenuOnSelect={true}
                isMulti={false}
                options={monthOptions.filter(
                (option) =>
                  option.value <= toMonth?.value ||
                  moment(showStartDate).year() <
                  moment(showEndDate).year()
                )}
                placeholder="Month"
                value={fromMonth}
                onChange={handleMonth1Change}
                isClearable={false}
              />
              <DatePicker
                selected={showStartDate}
                onChange={(date) => {
                changeStartYear(date);
                }}
                showYearPicker
                dateFormat="yyyy"
                placeholderText="Year"
                maxDate={showEndDate}
              />
              </div>
            </Form.Group>
            </Col>
            <Col md="3" lg="3">
            <Form.Group className="form-group">
              <Form.Label>{localised.monthYear2}</Form.Label>
              <div className="month-year-select">
              <CustomSelect
                id={"months"}
                name={"months"}
                closeMenuOnSelect={true}
                isMulti={false}
                options={monthOptions}
                placeholder="Month"
                value={toMonth}
                onChange={handleMonth2Change}
                isClearable={false}
              />
              <DatePicker
                selected={showEndDate}
                onChange={(date) => {
                changeEndYear(date);
                }}
                showYearPicker
                dateFormat="yyyy"
                placeholderText="Year"
                minDate={showStartDate}
              />
              </div>
            </Form.Group>
            </Col>
            <Col
            md="12"
            lg="6"
            className="d-flex align-items-end justify-content-md-end form-btns mt-3"
            >
            <Form.Group className="form-group">
              <div className="search-btn-link">
              <Buttons
                variant="secondary"
                title={localised.reset}
                className="button-icon"
                onClick={resetFilter}
              />
              <Buttons
                variant="primary"
                title={localised.search}
                className="ms-2"
                type="submit"
              />
              </div>
            </Form.Group>
            </Col>
          </Row>
          </>
        )}
        </Form>
      </div>
      </div>
    );
  }
);

export default JobsRevenueSearch;
