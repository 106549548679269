import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import { updateAppointmentData, updateAppointmentInfoData } from "../appointment/action";
import { isArray, isObjEmpty } from "../../utils/utils";
import { setGridFilter } from "../../utils/gridUtil";

/**
 * AppointmentMiddleware can be used to call Appointment related API services
 * @param {*} store
 * @returns
 */
const AppointmentMiddleware = (store) => (next) => async (action) => {
  const result = next(action);
  let endpoint = microServices.base + "/v1/appointments";
  switch (action.type) {
    case "FETCH_APPOINTMENT_LIST":
      APIservice.get(action.payload.apptUrl)
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: "SET_APPOINTMENT_LIST",
              payload: {
                data: response.data.data,
                meta: response.data.meta,
                unassigned:(response?.data?.meta?.group_count)?response?.data?.meta?.group_count?.unassigned:0,
              },
            });
          }
        })
        .catch((error) => {
          let err = {
            componentName: "AppointmentList",
            statusCode: error.response.status,
            data: error.response.data,
          };
          store.dispatch(setError(err));
        })
      break;

    case "UPDATE_APPOINTMENT_ATTRIBUTES":
      endpoint = microServices.base + "/v1/user/additional-appointment-attributes";
      store.dispatch(isLoader(true));
      APIservice.post(endpoint, action.payload.formData)
        .then((response) => {
          store.dispatch({ type: "GET_LOGGED_USER_DETAILS" });
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: "Column updated successfully!",
              componentName: "EditAdmin",
            },
          });
        })
        .catch((error) => {
          let err = {
            componentName: "AppointmentList",
            statusCode: error.response.status,
            data: error.response.data,
          };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;

    case "FETCH_APPOINTMENT_NOTES":
      endpoint = microServices.base + "/v1/appointment";
      store.dispatch(isLoader(true));
      APIservice.get(endpoint + "/" + action.payload.id + "/notes")
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: "SET_APPOINTMENT_NOTES",
              payload: {
                data: response.data.data,
              },
            });
          }
        })
        .catch((error) => {
          let err = {
            componentName: "AppointmentNotes",
            statusCode: error.response.status,
            data: error.response.data,
          };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;
    case "APPT_SCHEDULE":
      store.dispatch(isLoader(true));

      endpoint = microServices.base + "/v1/appointment";
      APIservice.post(endpoint, action.payload)
        .then((response) => {
         store.dispatch({
              type: "SET_APPOINTMENT_SCHEDULE_STATUS",
              payload: {
                success:response.data.success,
                data:response?.data?.data,
                componentName: "Appointment",
              },
            });
            if(action.is_upload){
              store.dispatch({
                type: "SHOW_ALERT",
                payload: {
                  variant: "success",
                  success:response.data.success,
                  data:response?.data?.data,
                  componentName: "Appointment",
                },
              });
            }
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: "Unable to schedule appointment!",
              },
            });
          } else {
            let err = {
              componentName: "Appointment",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;
    case "CREATE_APPOINTMENT_NOTES":
      endpoint = microServices.base + "/v1/appointment/notes";
      store.dispatch(isLoader(true));
      APIservice.post(endpoint, action.payload)
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: "Note added successfully.",
              componentName: "AddAppointmentNotes",
              appt_id: action.payload?.appointment_id
            },
          });

          if(typeof action.callback === 'function'){
            action.callback(response);
          }
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: "Unable to save notes information!",
              },
            });
          } else {
            let err = {
              componentName: "AddAppointmentNotes",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;
    case "FETCH_SINGLE_APPOINTMENT_NOTE":
      endpoint = microServices.base + "/v1/appointment/notes";
      store.dispatch(isLoader(true));
      APIservice.get(endpoint + "/" + action.payload.id)
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: "SET_SINGLE_APPOINTMENT_NOTES",
              payload: {
                data: response.data.data,
              },
            });
          }
        })
        .catch((error) => {
          let err = {
            componentName: "AppointmentNotes",
            statusCode: error.response.status,
            data: error.response.data,
          };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;
    case "UPDATE_APPOINTMENT_NOTE":
      endpoint = microServices.base + "/v1/appointment/notes";
      store.dispatch(isLoader(true));
      APIservice.put(
        endpoint + "/" + action.payload.appointment_note_id,
        action.payload
      )
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: "Note Updated Successfully.",
              componentName: "EditAppointmentNote",
            },
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: "Unable to save note information!",
              },
            });
          } else {
            let err = {
              componentName: "EditAppointmentNote",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;
    case "DELETE_APPOINTMENT_NOTE":
      endpoint =
        microServices.base + "/v1/appointment/notes/" + action.payload.id;
      store.dispatch(isLoader(true));
      APIservice.delete(endpoint)
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: "Note deleted successfully.",
              componentName: "DeleteAppointmentNote",
              appt_id: action.payload.appt_id
            },
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: "Unable to delete Appointment Note!",
              },
            });
          } else {
            let err = {
              componentName: "DeleteAppointmentNote",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;
    case "SHARE_APPOINTMENT_NOTE":
      endpoint =
        microServices.base +
        "/v1/appointment/notes/share/" +
        action.payload.id;
      store.dispatch(isLoader(true));
      APIservice.put(endpoint)
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: `Note ${
                action.payload.isShare ? "unshared" : "shared"
              } successfully.`,
              componentName: "SharedAppointmentNote",
            },
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: "Unable to share Appointment Note!",
              },
            });
          } else {
            let err = {
              componentName: "SharedAppointmentNote",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });

      break;
    case "UPDATE_APPOINTMENT_STATUS":
      endpoint =
        microServices.base +
        "/v1/appointment/" +
        action.payload.id +
        "/" +
        action.payload.status;
      store.dispatch(isLoader(true));
      APIservice.put(endpoint)
        .then((response) => {
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: `Appointment ${action.payload.status} successfully.`,
              componentName: "UpdateAppointmentsStatus",
              isMessageShow: action?.payload?.isMessageShow
            },
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: "SHOW_ALERT",
              payload: {
                variant: "danger",
                message: "Unable to update Appointment status!",
              },
            });
          } else {
            let err = {
              componentName: "UpdateAppointmentsStatus",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;
      case "FETCH_APPOINTEMENT_PHOTO":
        endpoint = microServices.base+'/v1/appointment';
        store.dispatch(isLoader(true));
        APIservice
        .get(endpoint+'/'+action.payload.id+'/photos?per_page=0')
        .then((response)=>{
          if(response && response.data){
            store.dispatch({
              type: 'SET_APPOINTEMENT_PHOTOS',
              payload: {
                data:response.data.data,
                no_of_photos: (response.data.data).length
                // no_of_photos:response.data.meta.total
              }
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:'AppointmentPhotos',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;
      case "SHARE_APPOINTEMENT_PHOTO" :
            endpoint = microServices.base+'/v1/appointment/photos/share/'+action.payload.id;
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint)
            .then((response)=>{
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant:'success',
                  message: `Photo ${action.payload.msg} successfully.`,
                  componentName:'SharedAppointmentPhoto'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant:'danger',
                    message: 'Unable to share Appointment Photo!'
                  }
                });
              } else {
                let err = {componentName:'SharedAppointmentPhoto',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "DELETE_APPOINTEMENT_PHOTO" :
          endpoint = microServices.base+'/v1/appointment/photos/'+action.payload.id;
          store.dispatch(isLoader(true));
          APIservice
          .delete(endpoint)
          .then((response)=>{
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant:'success',
                message: 'Photo deleted successfully.',
                componentName:'DeleteAppointmentPhoto',
                appt_id: action.payload.appt_id
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant:'danger',
                  message: 'Unable to delete Appointment Photo!'
                }
              });
            } else {
              let err = {componentName:'DeleteAppointmentPhoto',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;
        case "ROTATE_APPOINTEMENT_PHOTO":
          endpoint = microServices.base+'/v1/appointment/photos/rotate/'+action.payload.direction+'/'+action.payload.id;
          store.dispatch(isLoader(true));
          APIservice
          .patch(endpoint)
          .then((response)=>{
            if(response && response.data){
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant:'success',
                  message: 'Photo rotated successfully.',
                  componentName:'RotateAppointmentPhoto'
                }
              });
            }
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant:'danger',
                  message: 'Unable to rotate Appointment Photo!'
                }
              });
            } else {
              let err = {componentName:'RotateAppointmentPhoto',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;
      case "GET_RESCHEDULE_APPOINTMENT_DETAILS":
        endpoint = microServices.base+'/v1/appointment/reschedule';
        store.dispatch(isLoader(true));
        APIservice
        .get(endpoint+'/'+action.payload.id)
        .then((response)=>{
          if(response && response.data){
            store.dispatch({
              type: 'SET_RESCHEDULE_APPOINTMENT_DETAILS',
              payload: {
                data:response.data.data
              }
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:'ViewRescheduleAppointment',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;
      case "RESCHEDULE_APPOINTMENT":
        endpoint = microServices.base+'/v1/appointment/reschedule';
        store.dispatch(isLoader(true));
        APIservice
        .put(endpoint+'/'+action.payload.id, action.payload.formData)
        .then((response)=>{
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant:'success',
              message: 'Appointment has been rescheduled successfully.',
              componentName:'RescheduleAppointment'
            }
          });
        })
        .catch((error)=>{
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant:'danger',
                message: 'Unable to reschedule appointment!',
              }
            });
          } else {
            let err = {componentName:'RescheduleAppointment',statusCode: error.response.status,data: error.response.data};
            store.dispatch(setError(err));
          }
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;
      case "UPDATE_APPOINTMENT_TIME":
        endpoint = microServices.base +"/v1/appointment/"+action.payload.apptId+"/"+action.payload.timeActionType;
        store.dispatch(isLoader(true));
        APIservice.post(endpoint, action.payload.formData)
          .then((response) => {
            store.dispatch({ type: "GET_LOGGED_USER_DETAILS" });
          })
          .catch((error) => {
            let err = {
              componentName: "AppointmentList",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          });
        break;
        case "UPDATE_APPOINTMENT_ICON_DATA":
          endpoint = microServices.base +"/v1/appointment/"+action.payload.apptId;
          APIservice.put(endpoint, action.payload.formData)
            .then((response) => {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant:'success',
                  message: 'Appointment Icons Updated',
                  componentName: "AppointmentIconList",
                }
              });
            })
            .catch((error) => {
              let err = {
                componentName: "AppointmentIconList",
                statusCode: error.response.status,
                data: error.response.data,
              };
              store.dispatch(setError(err));
            })
            .finally(() => {
            });
          break;
        case "UPDATE_APPOINTMENT_SERVICE":

          endpoint = microServices.base +"/v1/appointment/"+action.payload.apptId+"/service";
          APIservice
            .put(endpoint, action.payload.formData)
            .then((response)=>{
              if(action?.payload?.staff) {
                store.dispatch(updateAppointmentInfoData({
                  apptId: action.payload.apptId, "info": action.payload.info, "staff": action.payload.staff
                }));
              }
              else if(action?.payload?.info) {
                store.dispatch(updateAppointmentData({
                  apptId: action.payload.apptId, formData: action.payload.info
                }));
              }
              store.dispatch({
                type: 'SET_APPOINTMENT_SERVICE_PRICE',
                payload: {
                  data:response.data.data
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SET_APPOINTMENT_SERVICE_PRICE',
                  payload: {
                    data:{},
                    }
                });
              } else {
                let err = {componentName:'Appointment schedule service',statusCode: error.response.status,data: {status:'false'}};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
          case "UPDATE_APPOINTMENT":

            endpoint = microServices.base +"/v1/appointment/"+action.payload.apptId;
            APIservice
              .put(endpoint, action.payload.formData)
              .then((response)=>{
                store.dispatch({
                  type: 'SET_APPOINTMENT_DETAILS',
                  payload: {
                    data:response.data.data
                    }
                });

                store.dispatch({
                  type: "SHOW_ALERT",
                  payload: {
                    variant: "success",
                    message: "Appointment Updated!",
                    componentName: "AppointmentUpdated",
                    refreshGrid : (action?.payload?.notRefreshGrid) ? action?.payload?.notRefreshGrid : false
                  },
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({
                    type: 'SET_APPOINTMENT_DETAILS',
                    payload: {
                      data:[]
                      }
                  });
                } else {
                  let err = {componentName:'AppointmentUpdated',statusCode: error.response.status,data: {status:'false'}};
                  store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "UPDATE_APPOINTMENT_STAFF":

            endpoint = microServices.base +"/v1/appointment/"+action.payload.apptId+"/staff";
            APIservice
              .put(endpoint, action.payload.formData)
              .then((response)=>{
                store.dispatch({
                  type: 'SET_APPOINTMENT_STAFF',
                  payload: {
                    data:response.data.data
                    }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({
                    type: 'SET_APPOINTMENT_STAFF',
                    payload: {
                      data:[]
                      }
                  });
                } else {
                  let err = {componentName:'Appointment schedule staff',statusCode: error.response.status,data: {status:'false'}};
                  store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "FETCH_APPT_SERVICE_CHECKLIST":
              APIservice.get(action.payload.checklistUrl)
                .then((response) => {
                  if (response && response.data) {
                    store.dispatch({
                      type: "SET_APPT_SERVICE_CHECKLIST",
                      payload: {
                        data: response.data.data,
                      },
                    });
                  }
                })
                .catch((error) => {
                  let err = {
                    componentName: "AppointmentList",
                    statusCode: error.response.status,
                    data: error.response.data,
                  };
                  store.dispatch(setError(err));
                })
                .finally(() => {
                });
            break;
            case "APPT_INTERNAL_SCHEDULE":
              store.dispatch(isLoader(true));

              endpoint = microServices.base + "/v1/appointment/internal";
              APIservice.post(endpoint, action.payload)
                .then((response) => {
                  store.dispatch({
                    type: "SET_APPOINTMENT_SCHEDULE_STATUS",
                    payload: {
                      success:response.data.success,
                      data:response?.data?.data,
                      componentName: "Appointment",
                    },
                  });
                })
                .catch((error) => {
                  if (error.response.status !== 422) {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "danger",
                        message: "Unable to schedule appointment!",
                      },
                    });
                  } else {
                    let err = {
                      componentName: "Appointment",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
              break;
              case "UPDATE_SERVICE_CHECKLIST_ITEM":
                endpoint = microServices.inspection +"/v1/checklist/appointment/"+action.payload.apptId+"/item/"+action.payload.itemId+"/complete";
                  APIservice.put(endpoint, action.payload.formData)
                  .then((response) => {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "success",
                        componentName: "ChecklistUpdated",
                        appt_id: action.payload?.apptId
                      },
                    });
                  })
                  .catch((error) => {
                    let err = {
                      componentName: "AppointmentIconList",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  })
              break;
              case "ADD_APPOINTMENT_DND":
                endpoint = microServices.base + "/v1/appointment/dnd";
                APIservice.post(endpoint, action.payload.formData)
                .then((response) => {
                  store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      variant: "success",
                      componentName: "DNDStatusUpdated",
                    },
                  });
                })
                .catch((error) => {
                  let err = {
                    componentName: "AppointmentDndList",
                    statusCode: error.response.status,
                    data: error.response.data,
                  };
                  store.dispatch(setError(err));
                })
                .finally(() => {
                });
              break;
              case "FETCH_AUTOMATION_APPT_LIST":
                store.dispatch(isLoader(true));
                APIservice.get(action.payload.apptUrl)
                  .then((response) => {
                    if (response && response.data) {
                      store.dispatch({
                        type: "SET_AUTOMATION_APPT_LIST",
                        payload: {
                          data: response.data.data,
                          meta: response.data.meta,
                        },
                      });
                    }
                  })
                  .catch((error) => {
                    let err = {
                      componentName: "AppointmentAutoList",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                break;


            case "INITIALIZE_UPLOAD_TEMP_APPOINTMENT":
                  endpoint = microServices.base+action.payload.url;
                  store.dispatch(isLoader(true));
                  APIservice
                  .post(endpoint, action.payload.formData)
                    .then((response)=>{
                      store.dispatch({
                        type: 'UPLOAD_TEMP_APPOINTMENT',
                        payload: {
                          'uploadUrl':response.data.data.upload,
                          'uploadFiledata':action.payload.formData
                        }
                        });
                    })
                    .catch((error)=>{
                      let err = {componentName:'Upload_Appoinment',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                      store.dispatch(isLoader(false));
                    });
            break;
            case "UPLOAD_TEMP_APPOINTMENT":
              endpoint = action.payload.uploadUrl;
              store.dispatch(isLoader(true));
              APIservice
              .post(endpoint,action.payload.uploadFiledata)
              .then((response)=>{
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant:'success',
                    componentName:'UploadTempAppoinment',
                    data : response
                  }
                });
               })
              .catch((error)=>{
                  let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
              }).finally(()=>{
                  store.dispatch(isLoader(false));
              });
              break;

              case "FETCH_TEMP_APPOINTMENT":
                endpoint = microServices.base+'/v1/appointment/temp/uploads';
                store.dispatch(isLoader(true));
                APIservice
                  .get(endpoint+'/'+action.payload.session_id)
                  .then((response)=>{
                    if(response && response.data){
                      store.dispatch({
                      type: 'SET_TEMP_APPOINTMENT_DATA',
                      payload: {
                        data:response.data
                        }
                      });
                    }
                  })
                  .catch((error)=>{
                    let err = {componentName:'AppointmentUploads',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  })
              break;
              case "REMOVE_APPOINTMENT_DND":
                endpoint =
                  microServices.base + "/v1/appointment/"+action.payload.apptId+"/dnd";
                  APIservice.delete(endpoint)
                  .then((response) => {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "success",
                        message: "Dnd deleted successfully.",
                        componentName: "DeleteApptDnds",
                      },
                    });
                  })
                  .catch((error) => {
                  })
                  .finally(() => {
                  });
                break;

                case "CANCEL_BULK_APPOINTMENT":
              store.dispatch(isLoader(true));
              endpoint = microServices.base + "/v1/appointment/bulk-cancel";
              APIservice.put(endpoint, action.payload)
                .then((response) => {
                  store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      variant: "success",
                      message: "Appointment schedule successfully",
                      componentName: "CancelBulkAppointment",
                    },
                  });
                })
                .catch((error) => {
                  if (error.response.status !== 422) {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "danger",
                        message: "Unable to cancel appointments!",
                      },
                    });
                  } else {
                    let err = {
                      componentName: "CancelBulkAppointment",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
              break;

                case "DELETE_TEMP_APPOINTMENT":
                  endpoint =
                    microServices.base + "/v1/appointment/temp/uploads/delete";
                    store.dispatch(isLoader(true));
                    APIservice.delete(endpoint, action.payload.formData)
                    .then((response) => {
                        store.dispatch({
                          type: 'CLEAR_ALERT'
                        });
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success" ,
                            message:  "Declined Successfully.",
                            componentName: "DeclineAppointment",
                            declined : action.payload.newSave
                          },
                        });
                    })
                    .catch((error) => {
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;

              case "FETCH_SINGLE_APPOINTMENT":
                APIservice.get(action.payload.apptUrl)
                  .then((response) => {
                    if (response && response.data) {
                      store.dispatch({
                        type: "SET_SINGLE_APPOINTMENT",
                        payload: {
                          data: response?.data?.data,
                        },
                      });
                    }
                  })
                  .catch((error) => {
                  })
                break;

              case "CREATE_SERVICE_REQUEST":
              store.dispatch(isLoader(true));
              endpoint = microServices.base + "/v1/appointment/service-requests";
              APIservice.post(endpoint, action.payload.formData)
                .then((response) => {
                  store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      variant: "success",
                      message: action.payload.message,
                      componentName: "CreateServiceRequest",
                    },
                  });
                })
                .catch((error) => {
                  if (error.response.status !== 422) {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "danger",
                        message: action.payload.failMessage,
                      },
                    });
                  } else {
                    let err = {
                      componentName: "CreateServiceRequest",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
              break;

              case "FETCH_APPOINTMENT_TASK":
                endpoint = microServices.base+'/v1/appointment';
                store.dispatch(isLoader(true));
                APIservice
                  .get(`${endpoint}/${action.payload.appointment_id}/view-task`)
                  .then((response)=>{
                    if(response && response.data){
                      store.dispatch({
                        type: 'SET_APPOINTMENT_TASK',
                        payload: {
                          data:response.data.data,
                        }
                      });
                    }
                  })
                  .catch((error)=>{
                    let err = {componentName:'AppointmentTask',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  })
              break;

              case "CREATE_SUPPORT_TASK": {
                store.dispatch(isLoader(true));
                endpoint = microServices.base + "/v1/appointment/ha/task";
                APIservice.post(endpoint, action.payload.formData)
                  .then((response) => {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "success",
                        message: 'Support Task added successfully!',
                        componentName: "AddSupportTask",
                      },
                    });
                  })
                  .catch((error) => {
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "danger",
                          message: "Unable to add support task!",
                          componentName: "AddSupportTask",
                        },
                      });
                    } else {
                      let err = {
                        componentName: "AddSupportTask",
                        statusCode: error.response.status,
                        data: error.response.data,
                      };
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                  break;
                }

                case "DELETE_SUPPORT_TASK" : {
                  endpoint = microServices.base+"/v1/appointment/ha/task/"+action.payload.params.ha_task_id;
                    store.dispatch(isLoader(true));
                    APIservice.delete(endpoint)
                    .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success" ,
                            message:  "Support task deleted successfully!",
                            componentName: "DeleteSupportTask",
                          },
                        });
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "danger",
                            message: "Unable to delete support task!",
                            componentName: "DeleteSupportTask",
                          },
                        });
                      } else {
                        let err = {
                          componentName: "DeleteSupportTask",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;
                }

                case "UPDATE_SUPPORT_TASK": {
                  store.dispatch(isLoader(true));
                  endpoint = microServices.base + "/v1/appointment/ha/task/" + action.payload.params.ha_task_id;
                  APIservice.put(endpoint, action.payload.formData)
                    .then((response) => {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "success",
                          message: action.payload.message,
                          componentName: action.payload.componentName,
                        },
                      });
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "danger",
                            message: action.payload.failedMessage,
                            componentName: action.payload.componentName,
                          },
                        });
                      } else {
                        let err = {
                          componentName: action.payload.componentName,
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;
                }

                case "ACCEPT_SERVICE_REQUEST":
                  store.dispatch(isLoader(true));
                  endpoint = microServices.base + "/v1/appointment/service-requests/accept";
                  APIservice.post(endpoint+'/'+action.payload.service_request_id, action.payload.formData)
                    .then((response) => {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "success",
                          message: action.payload.message,
                          componentName: "AcceptServiceRequest",
                        },
                      });
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "danger",
                            message: action.payload.failMessage,
                          },
                        });
                      } else {
                        let err = {
                          componentName: "AcceptServiceRequest",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;

                case "DROPDOWN_SUPPORT_TASKS": {
                  endpoint = `${microServices.base}/v1/dropdown/supportTasks/${action.payload.params.company_id}`;
                    APIservice.get(endpoint)
                      .then((response) => {
                        let suppoertTaskDropdown = prepareSuppoertTaskDropdown(response.data);
                        store.dispatch({
                          type: 'SET_DROPDOWN_SUPPORT_TASKS',
                          payload: suppoertTaskDropdown,
                        });
                      })
                      .catch((error)=>{
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: 'SET_DROPDOWN_SUPPORT_TASKS',
                            payload: [],
                          });
                        }
                      })
                  break;
                }

                case "COMPLETE_SERVICE_REQUEST":
                  store.dispatch(isLoader(true));
                  endpoint = microServices.base + "/v1/appointment/service-requests/complete";
                  APIservice.put(endpoint+'/'+action.payload.service_request_id, action.payload.formdata)
                    .then((response) => {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "success",
                          message: action.payload.message,
                          componentName: "CompleteServiceRequest",
                        },
                      });
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "danger",
                            message: action.payload.failMessage,
                          },
                        });
                      } else {
                        let err = {
                          componentName: "CompleteServiceRequest",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;

                case "CANCEL_SERVICE_REQUEST":
                  store.dispatch(isLoader(true));
                  endpoint = microServices.base + "/v1/appointment/service-requests/cancel";
                  APIservice.put(endpoint+'/'+action.payload.service_request_id, action.payload.formdata)
                    .then((response) => {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "success",
                          message: action.payload.message,
                          componentName: "CancelServiceRequest",
                        },
                      });
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "danger",
                            message: action.payload.failMessage,
                          },
                        });
                      } else {
                        let err = {
                          componentName: "CancelServiceRequest",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;

                  case "UPDATE_MULTI_SERVICE_CHECKLIST_ITEM":
                    endpoint = microServices.inspection +"/v1/checklist/appointment/"+action.payload.apptId+"/item/complete-multiple";
                      APIservice.post(endpoint, action.payload.formData)
                      .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            componentName: "ChecklistUpdated",
                            appt_id: action.payload?.apptId
                          },
                        });
                      })
                      .catch((error) => {
                        let err = {
                          componentName: "AppointmentIconList",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      })
                  break;
                  case "CREATE_SUB_TASKS":
                    endpoint = microServices.base + "/v1/appointment/subtasks";
                    store.dispatch(isLoader(true));
                    const formData = action.payload.formData;
                    const data = {
                      ...formData,
                      // workaround: without a price the cost is not saved
                      ...(!formData.price?.toString().length && formData.cost?.toString().length > 0 && { price: 0 }),
                    };
                    APIservice.post(endpoint, data)
                      .then((response) => {
                        if (action.showAlerts === true) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: action.payload.successMessage,
                              componentName: action.payload.type === "create" ? "AddSubTasks" : "CreateAssignSubtask",
                              data: response.data.data
                            },
                          });
                        }

                        if(typeof action.callback === 'function'){
                          action.callback(response.data);
                        }
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: action.payload.failMessage,
                            },
                          });
                        } else {
                          let err = {
                            componentName: "AddSubTasks",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                  break;
                  case "ASSIGN_SUBTASKS_LIST":
                    endpoint = `${microServices.base}/v1/appointment/subtasks/${action.payload.properties_id}/list&per_page=0`;
                    if(action.payload.subtaskType){
                      endpoint = `${microServices.base}/v1/appointment/subtasks/${action.payload.properties_id}/list?filter[reported_type]=${action.payload.subtaskType}&per_page=0`;
                    }
                    APIservice.get(endpoint)
                    .then((response) => {
                      if (response && response.data) {
                        store.dispatch({
                          type: "SET_APPOINTMENT_SUBTASKS_LIST",
                          payload: {
                            data: response.data.data
                          },
                        });

                        store.dispatch({
                          type: "SET_WORKORDER_SUBTASKS_LIST",
                          payload: {
                            data: response.data.data
                          },
                        });

                      }
                    })
                    .catch((error) => {
                      let err = {
                        componentName: "AssignSubtaskList",
                        statusCode: error.response.status,
                        data: error.response.data,
                      };
                      store.dispatch(setError(err));
                    })
                  break;
                  case "CREATE_APPOINTMENT_ASSIGN_SUBTASKS":
                    endpoint = `${microServices.base}/v1/appointment/subtasks/assign`;
                    APIservice.post(endpoint, action.payload.formData)
                    .then((response) => {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "success",
                          message: action.payload.successMessage,
                          componentName: "CreateAppointmentmentAssignSubtask",
                          appt_id: action.payload?.formData?.appointment_id
                        },
                      });
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "danger",
                            message: "Unable to assign subtask!",
                            componentName: "CreateAppointmentmentAssignSubtask",
                          },
                        });
                      } else {
                        let err = {
                          componentName: "CreateAppointmentmentAssignSubtask",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      }
                    })
                  break;
                    case "CANCEL_SUB_TASKS": {
                      endpoint = microServices.base + "/v1/appointment/subtasks/cancel";
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint, action.payload.formData)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: 'Subtask(s) cancelled successfully!',
                              componentName: "CancelSubTasks",
                            },
                          });

                          if(typeof action.callback === 'function'){
                            action.callback();
                          }
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: 'Unable to cancel subtask(s)!',
                              },
                            });
                          } else {
                            let err = {
                              componentName: "CancelSubTasks",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }
                    case "COMPLETE_SUB_TASKS": {
                      endpoint = microServices.base + "/v1/appointment/subtasks/complete";
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint, action.payload.formData)
                        .then((response) => {
                          if (action.showAlerts === true) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "success",
                                message: 'Subtask(s) completed successfully!',
                              },
                            });
                          }

                          if(typeof action.callback === 'function'){
                            action.callback();
                          }
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: 'Unable to complete subtask(s)!',
                              },
                            });
                          } else {
                            let err = {
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }
                    case "POSTPONE_SUB_TASKS": {
                      endpoint = microServices.base + "/v1/appointment/subtasks/postpone";
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint, action.payload.formData)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: 'Subtask(s) postponed successfully!',
                            },
                          });

                          if(typeof action.callback === 'function'){
                            action.callback();
                          }
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: 'Unable to postpone subtask(s)!',
                              },
                            });
                          } else {
                            let err = {
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }
                    case "FETCH_SUB_TASKS":
                      endpoint = microServices.base+'/v1/appointment/subtasks/'+action.payload.subtask_id;
                      store.dispatch(isLoader(true));
                      APIservice
                        .get(endpoint)
                        .then((response)=>{
                          if(response && response.data){
                            store.dispatch({
                            type: 'SET_SUB_TASK',
                            payload: {
                              data:response.data.data
                              }
                            });
                          }
                        })
                        .catch((error)=>{
                          let err = {componentName:'fetchSubtasks',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                    break;

                  case "UPDATE_SUB_TASKS":
                    endpoint = microServices.base + "/v1/appointment/subtasks/" + action.payload.subtask_id;
                    store.dispatch(isLoader(true));
                    APIservice.put(endpoint, action.payload.formData)
                      .then((response) => {
                        if (action.showAlerts === true) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: action.payload.successMessage,
                              componentName: "EditSubtasks",
                              showSuccessMessage: action.payload.showSuccessMessage
                            },
                          });
                        }

                        if (typeof action.callback === 'function') {
                          action.callback(response);
                        }
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: action.payload.failMessage,
                            },
                          });
                        } else {
                          let err = {
                            componentName: "EditSubtasks",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                    break;

                    case "ALERT_SUB_TASKS": {
                      endpoint = microServices.base + "/v1/appointment/subtasks/alert";
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint, action.payload.formData)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: 'Request sent successfully!',
                              componentName: "AlertSubTasks",
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: 'Unable to sent request!',
                              },
                            });
                          } else {
                            let err = {
                              componentName: "AlertSubTasks",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }

                    case "APPROVE_SUB_TASKS": {
                      endpoint = microServices.base + "/v1/appointment/subtasks/approve";
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint, action.payload.formData)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: 'Subtask approved successfully!',
                              componentName: "ApproveSubTasks",
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: 'Unable to approve subtask!',
                              },
                            });
                          } else {
                            let err = {
                              componentName: "ApproveSubTasks",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }

                    case "DISAPPROVE_SUB_TASKS": {
                      endpoint = microServices.base + "/v1/appointment/subtasks/disapprove";
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint, action.payload.formData)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: 'Subtask disapproved successfully!',
                              componentName: "DisapproveSubTasks",
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: 'Unable to disapprove subtask!',
                              },
                            });
                          } else {
                            let err = {
                              componentName: "DisapproveSubTasks",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }

                    case "CONVERT_INTERNAL_SUB_TASK": {
                      endpoint = `${microServices.base}/v1/appointment/subtasks/convert-internal/${action.payload.params.subtask_id}`;
                      store.dispatch(isLoader(true));
                      APIservice.put(endpoint, action.payload.formData)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: action.payload.successMessage,
                              componentName: action.payload.componentName,
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: error?.response?.data?.message || action.payload.failedMessage,
                              },
                            });
                          } else {
                            let err = {
                              componentName: action.payload.componentName,
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }

                    case "CONVERT_OUTSIDE_SUB_TASK": {
                      endpoint = `${microServices.base}/v1/appointment/subtasks/convert-outside/${action.payload.params.subtask_id}`;
                      store.dispatch(isLoader(true));
                      APIservice.put(endpoint, action.payload.formData)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: action.payload.successMessage,
                              componentName: action.payload.componentName,
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: action.payload.failedMessage,
                              },
                            });
                          } else {
                            let err = {
                              componentName: action.payload.componentName,
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }

                    case "INITIALIZE_SUB_TASK_PHOTOS": {
                      endpoint = microServices.base+'/v1/appointment/subtasks-photos';
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint, action.payload)
                        .then((response)=>{
                            store.dispatch({
                                type: 'UPLOAD_SUB_TASK_PHOTOS',
                                payload: {
                                    'uploadUrl':response.data.data.upload,
                                    'uploadImagedata':action.payload
                                }
                            });
                        })
                        .catch((error)=>{
                            let err = {
                              componentName:'SubtaskPhotos',
                              statusCode: error.response.status,data:
                              error.response.data
                            };
                            store.dispatch(setError(err));
                        })
                        .finally(()=>{
                            store.dispatch(isLoader(false));
                        })
                      break;
                    }

                    case "UPLOAD_SUB_TASK_PHOTOS": {
                      endpoint = action.payload.uploadUrl;
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint,action.payload.uploadImagedata)
                        .then(()=>{
                            store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant:'success',
                                    message: 'Photo uploaded successfully.',
                                    componentName:'UploadSubtaskPhotos'
                                }
                            });
                        })
                        .catch((error)=>{
                            if (error.response.status !== 422) {
                                store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant:'danger',
                                        message: 'Unable to upload Subtask Photo!',
                                        componentName:'UploadSubtaskPhotos'
                                    }
                                });
                            }
                            else {
                                let err = {
                                  componentName:action.
                                  componentName,statusCode: error.response.status,
                                  data: error.response.data
                                };
                                store.dispatch(setError(err));
                            }
                        })
                        .finally(()=>{
                            store.dispatch(isLoader(false));
                        })
                      break;
                    }

                    case "FETCH_SUB_TASK_PHOTO": {
                      endpoint = `${microServices.base}/v1/appointment/subtasks-photos/${action.payload.subtask_id}`;
                      store.dispatch(isLoader(true));
                      APIservice.get(endpoint)
                      .then((response)=>{
                        if(response && response.data){
                          store.dispatch({
                            type: 'SET_SUB_TASK_PHOTOS',
                            payload: {
                              data:response.data.data,
                              no_of_photos:response.data.meta.total
                            }
                          });
                        }
                      })
                      .catch((error)=>{
                        let err = {
                          componentName:'SubtaskPhotos',
                          statusCode: error.response.status,
                          data: error.response.data
                        };
                        store.dispatch(setError(err));
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                      break;
                    }

                    case "DELETE_SUB_TASK_PHOTO" : {
                      endpoint = microServices.base+'/v1/appointment/subtasks-photos/'+action.payload.subtask_photo_id;
                      store.dispatch(isLoader(true));
                      APIservice.delete(endpoint)
                      .then((response)=>{
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant:'success',
                            message: 'Photo deleted successfully.',
                            componentName:'DeleteSubtaskPhoto'
                          }
                        });
                      })
                      .catch((error)=>{
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant:'danger',
                              message: 'Unable to delete Subtask Photo!'
                            }
                          });
                        } else {
                          let err = {componentName:'DeleteSubtaskPhoto',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                      break;
                    }

                    case "COPY_OVER_SUB_TASK_PHOTOS" : {
                      endpoint = microServices.base+'/v1/appointment/subtasks-photos/copy-over/'+action.payload.from_subtask_id+'/'+action.payload.to_subtask_id;
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint)
                        .then((response) => {
                          if (typeof action.callback === 'function') {
                            action.callback(response);
                          }
                        })
                      .catch((error)=>{
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant:'danger',
                              message: 'Unable to copy over Subtask photos!'
                            }
                          });
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                      break;
                    }

                    case "FETCH_REQUESTED_SUBTASKS": {
                      store.dispatch(isLoader(true));
                      APIservice.get(action.payload.url)
                        .then((response) => {
                          if (response && response.data) {
                            store.dispatch({
                              type: "SET_REQUESTED_SUBTASKS",
                              payload: {
                                data: response.data.data,
                              },
                            });
                          }
                        })
                        .catch((error) => {
                          let err = {
                            componentName: "AppointmentList",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }

                    case "FETCH_ASSIGNED_SUBTASKS": {
                      APIservice.get(action.payload.url)
                        .then((response) => {
                          if (response && response.data) {
                            store.dispatch({
                              type: "SET_ASSIGNED_SUBTASKS",
                              payload: {
                                data: response.data.data,
                              },
                            });
                          }
                        })
                        .catch((error) => {
                          let err = {
                            componentName: "AppointmentList",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        })
                      break;
                    }

                    case "COMPLETE_ASSIGNED_SUBTASKS": {
                      endpoint = microServices.base+'/v1/appointment/subtasks/complete-uncomplete';
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint, action.payload.formData)
                        .then(()=>{
                          store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                  variant:'success',
                                  message: 'Subtask completed successfully!.',
                                  componentName:'CompleteAssignedSubtask',
                                  appt_id: action.payload?.appointment_id
                              }
                          });
                        })
                        .catch((error)=>{
                            if (error.response.status !== 422) {
                                store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant:'danger',
                                        message: 'Unable to complete subtask!',
                                        componentName:'CompleteAssignedSubtask'
                                    }
                                });
                            }
                            else {
                                let err = {
                                  componentName:action.
                                  componentName,statusCode: error.response.status,
                                  data: error.response.data
                                };
                                store.dispatch(setError(err));
                            }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }

                    case "FETCH_SUBTASKS_APPROVAL_LIST": {
                      store.dispatch(isLoader(true));
                      APIservice.get(action.payload.url)
                        .then((response) => {
                          if (response && response.data) {
                            store.dispatch({
                              type: "SET_SUBTASKS_APPROVAL_LIST",
                              payload: {
                                data: response.data.data,
                              },
                            });
                          }
                        })
                        .catch((error) => {
                          let err = {
                            componentName: "SubtaskApprovalList",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                    }
                    case "APPOINTMENT_PRINT":
                    endpoint = microServices.base + '/v1/'+action.payload.selectedLanguage+'/appointment/print';
                    store.dispatch(isLoader(true));

                    APIservice.
                    getBlobDataWithParams(endpoint+action.payload.endpointfilter)
                    .then((response)=>{
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "View Appointments"}_${Date.now()}.pdf`);
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                    })
                    .catch((error)=>{
                        if (error.response.status !== 422) {
                            store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                }
                            });
                        } else {
                            let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;
                    case "APPOINTMENT_EXPORT":
                    endpoint = microServices.base + '/v1/'+action.payload.selectedLanguage+'/appointment/appointmentexport';
                    store.dispatch(isLoader(true));

                    APIservice.
                    getBlobDataWithParams(endpoint+action.payload.endpointfilter)
                    .then((response)=>{
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Appointments_Report"}_${Date.now()}.xlsx`);
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                    })
                    .catch((error)=>{
                        if (error.response.status !== 422) {
                            store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                }
                            });
                        } else {
                            let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));

                    });
                  break;
                    case "SERVICE_CHECKLIST_EXPORT":
                    endpoint = microServices.base + '/v1/'+action.payload.selectedLanguage+'/appointment/appointmentexport/serviceReq';
                    APIservice.
                    getBlobDataWithParams(endpoint+action.payload.endpointfilter)
                    .then((response)=>{
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Service_Checklist"}_${Date.now()}.xlsx`);
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                    })
                    .catch((error)=>{
                        if (error.response.status !== 422) {
                            store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                }
                            });
                        } else {
                            let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                    });
                  break;
                  case "FETCH_SERVICE_REQUEST_LIST":
                    APIservice.get(action.payload.apptUrl)
                      .then((response) => {
                        if (response && response.data) {
                          store.dispatch({
                            type: "SET_SERVICE_REQUEST_LIST",
                            payload: {
                              data: response.data.data,
                            },
                          });
                        }
                      })
                      .catch((error) => {
                        let err = {
                          componentName: "ServiceRequestList",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      })
                    break;
              case "RECURRING_APPT_SCHEDULE":
                store.dispatch(isLoader(true));
                endpoint = microServices.base + action.payload.url;
                APIservice.post(endpoint, action.payload)
                  .then((response) => {
                    store.dispatch({
                      type: "SET_APPOINTMENT_SCHEDULE_STATUS",
                      payload: {
                        success:response.data.success,
                        data:response?.data?.data,
                        componentName: "Appointment",
                      },
                    });
                  })
                  .catch((error) => {
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "danger",
                          message: "Unable to schedule appointment!",
                        },
                      });
                    } else {
                      let err = {
                        componentName: "Recurring Appointment",
                        statusCode: error.response.status,
                        data: error.response.data,
                      };
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                break;

                case "COMPLETE_BULK_APPOINTMENT":
                  store.dispatch(isLoader(true));
                  endpoint = microServices.base + "/v1/appointment/bulk-complete";
                  APIservice.put(endpoint, action.payload)
                  .then((response) => {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "success",
                        message: "Appointments completed successfully",
                        componentName: "CompleteBulkAppointment",
                      },
                    });
                  })
                  .catch((error) => {
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "danger",
                          message: "Unable to complete appointments!",
                        },
                      });
                    } else {
                      let err = {
                        componentName: "CompleteBulkAppointment",
                        statusCode: error.response.status,
                        data: error.response.data,
                      };
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                break;
                case "CONFIRM_BULK_APPOINTMENT":
                      store.dispatch(isLoader(true));
                      endpoint = microServices.base + "/v1/appointment/bulk-confirm";
                      APIservice.put(endpoint, action.payload)
                      .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            message: "Appointments confirmed successfully",
                            componentName: "ConfirmBulkAppointment",
                          },
                        });
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: "Unable to confirm appointments!",
                            },
                          });
                        } else {
                          let err = {
                            componentName: "ConfirmBulkAppointment",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                    break;

                  case "PRODUCTIVITY_LIST_EXPORT":
                    endpoint = action.payload?.selectedLanguage ? microServices.base + '/v1/'+action.payload?.selectedLanguage+'/reports/export/' : microServices.base + '/v1/reports/export/';
                    APIservice.
                    getBlobDataWithParams(endpoint+action.payload?.url)
                    .then((response)=>{
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Productivity_Report"}_${Date.now()}.xlsx`);
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                    })
                    .catch((error)=>{
                        if (error.response.status !== 422) {
                            store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                }
                            });
                        } else {
                            let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                    });
                  break;

                  case "PRODUCTIVITY_LIST_PRINT":
                    endpoint = action?.payload?.selectedLanguage ? microServices.base + '/v1/'+action?.payload?.selectedLanguage+'/' : microServices.base + '/v1/';
                    APIservice.
                    getBlobDataWithParams(endpoint+action.payload.url)
                    .then((response)=>{
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Productivity_Report"}_${Date.now()}.pdf`);
                          document.body.appendChild(link);
                          link.click();
                          link.parentNode.removeChild(link);
                    })
                    .catch((error)=>{
                        if (error.response.status !== 422) {
                            store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                }
                            });
                        } else {
                            let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                    });
                  break;
                case "APPOINTMENT_EMAIL":
                  endpoint = microServices.base + '/v1/appointment/sendpdfEmail';
                  APIservice.get(endpoint+ action.payload)
                    .then((response) => {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "success",
                          message: "Appointment Report Mailed successfully",
                          componentName: "AppointmentEmail",
                        },
                      });
                  })
                  .catch((error)=>{
                      if (error.response.status !== 422) {
                          store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                  variant:'danger',
                                  message: 'Unable to Download Report!',
                              }
                          });
                      } else {
                          let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                      }
                  })
                  .finally(()=>{
                  });
                break;

                  case "CHANGE_APPOINTMENT_CLEANER":
                    endpoint = microServices.base + "/v1/appointment/update/cleaner/";
                    store.dispatch(isLoader(true));
                    APIservice.put(endpoint + action.payload.appointment_id, action.payload.formData)
                      .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            message: action.payload.successMessage,
                            componentName: "updateCleaner",
                            data:response.data.data
                          },
                        });
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: action.payload.failMessage,
                            },
                          });
                        } else {
                          let err = {
                            componentName: "updateCleaner",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                  break;


                  case "CONVERT_INTERNAL_MULTI_SUBTASK": {
                    endpoint = microServices.base + "/v1/appointment/subtasks/convert-multi-internal";
                    store.dispatch(isLoader(true));
                    APIservice.post(endpoint, action.payload.formData)
                      .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            message: action.payload.successMessage,
                            componentName: "ConvertInternalSubtask",
                          },
                        });
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: 'Unable to convert subtask!',
                            },
                          });
                        } else {
                          let err = {
                            componentName: "ConvertInternalSubtask",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                    break;
                  }
                  case "QUICK_ASSIGN_STAFF":
                    endpoint = `${microServices.base}/v1/appointment/quickAssignStaff`;
                    store.dispatch(isLoader(true));
                    APIservice.post(endpoint, action.payload.formData)
                    .then((response) => {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "success",
                          message: action.payload.successMessage,
                          componentName: "QuickAssignApmtStaff",
                          data:response.data.data
                        },
                      });
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "danger",
                            message: "Unable to assign staff!",
                            componentName: "QuickAssignApmtStaff",
                          },
                        });
                      } else {
                        let err = {
                          componentName: "QuickAssignApmtStaff",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;


                  case "UPDATE_APPOINTMENT_INFO":
                    endpoint = microServices.base +"/v1/appointment/"+action.payload.apptId+"/staff";
                    APIservice
                      .put(endpoint, action.payload.staff)
                      .then((response)=>{
                        store.dispatch(updateAppointmentData({ apptId: action.payload.apptId, formData: action.payload.info }));
                      })
                      .catch((error)=>{
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: 'Unable to update appointment!',
                            },
                          });
                        } else {
                          let err = {componentName:'AppointmentInfoUpdated',statusCode: error.response.status,data: {status:'false'}};
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(()=>{

                      })
                    break;

                    case "RESET_APPOINTMENT_INFO":
                      endpoint = microServices.base +"/v1/appointment/reset/"+action.payload.apptId;
                      APIservice
                        .put(endpoint)
                        .then((response)=>{
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: 'Appointment Reset Successfully!',
                              componentName: "ResetAppointmentInfo",
                            },
                          });
                        })
                        .catch((error)=>{
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: 'Unable to reset appointment!',
                              },
                            });
                          } else {
                            let err = {componentName:'ResetAppointmentInfo',statusCode: error.response.status,data: {status:'false'}};
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{

                        })
                    break;

                  case "ROLL_APPOINTMENTS": {
                    endpoint = microServices.base +"/v1/roll-appointments";
                    store.dispatch(isLoader(true));
                    APIservice.post(endpoint, action.payload.formData)
                      .then((response)=>{
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            message: 'Appointment(s) rolled successfully.',
                            componentName: "RollAppointments",
                          },
                        });
                      })
                      .catch((error)=>{
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: 'Unable to roll appointment(s)',
                            },
                          });
                        } else {
                          let err = {componentName:'RollAppointments',statusCode: error.response.status,data: {status:'false'}};
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                    break;
                  }

                  case "GET_ROLL_APPOINTMENTS_LIST": {
                    endpoint = microServices.base + "/v1/roll-appointments" + action.payload.filters + "&per_page=0";
                    store.dispatch(isLoader(true));
                    APIservice.get(endpoint)
                      .then((response) => {
                        if (response && response.data) {
                          store.dispatch({
                            type: "SET_ROLL_APPOINTMENTS_LIST",
                            payload: {
                              data: response.data.data,
                            },
                          });
                        }
                      })
                      .catch((error) => {
                        let err = {
                          componentName: "RollAppointmentsList",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      })
                    break;
                  }

                  case "INITIALIZE_EVOLVE_INTEGRATION":
                    endpoint = microServices.integration+action.payload.url;
                    store.dispatch(isLoader(true));
                    APIservice
                    .post(endpoint, action.payload.formData)
                      .then((response)=>{
                        store.dispatch({
                          type: 'UPLOAD_EVOLVE_INTEGRATION',
                          payload: {
                            'uploadUrl':response.data.data.upload,
                            'uploadFiledata':action.payload.formData
                          }
                          });
                      })
                      .catch((error)=>{
                        let err = {componentName:'Upload_evolve_integration',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                        store.dispatch(isLoader(false));
                      });
                      break;

                      case "UPLOAD_EVOLVE_INTEGRATION":
                        endpoint = action.payload.uploadUrl;
                        store.dispatch(isLoader(true));
                        APIservice
                        .post(endpoint,action.payload.uploadFiledata)
                        .then((response)=>{
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant:'success',
                              componentName:'UploadEvolveIntegration',
                              message : 'Uploaded Successfully!' ,
                              data : response
                            }
                          });
                        })
                        .catch((error)=>{
                            let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }).finally(()=>{
                            store.dispatch(isLoader(false));
                        });
                        break;
                  case "GET_APPOINTMENT_TOTAL": {
                    endpoint = action.payload.endpoint + "&per_page=0";
                    store.dispatch(isLoader(true));
                    APIservice.get(endpoint)
                      .then((response) => {
                        if (response && response.data) {
                          store.dispatch({
                            type: "SET_APPOINTMENT_TOTAL",
                            payload: {
                              data: response.data,
                            },
                          });
                        }
                      })
                      .catch((error) => {
                        let err = {
                          componentName: "RollAppointmentsList",
                          statusCode: error.response.status,
                          data: error.response.data,
                        };
                        store.dispatch(setError(err));
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                      break;
                    }
                    case "SCHEDULE_INSPECTION_APPOINTMENT": {
                      endpoint = microServices.base +"/v1/appointment/scheduleInspection";
                      store.dispatch(isLoader(true));
                      APIservice.post(endpoint, action.payload.formData)
                        .then((response)=>{
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: action.payload.successMessage,
                              componentName: "ScheduleInspection",
                            },
                          });
                        })
                        .catch((error)=>{
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message:  action.payload.failMessage,
                              },
                            });
                          } else {
                            let err = {componentName:'ScheduleInspection',statusCode: error.response.status,data: {status:'false'}};
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                      break;
                    }
                    case "FETCH_LOG_LIST": {
                      store.dispatch(isLoader(true));
                      APIservice.get(action.payload.url)
                        .then((response) => {
                          if (response && response?.data) {
                            let logData = [];
                            response?.data?.data.map((list) => {
                                if(list?.description?.length) {
                                  logData.push(list);
                                }
                          })
                            store.dispatch({
                              type: "SET_LOG_LIST",
                              payload: {
                                data: logData,
                              },
                            });
                          }
                        })
                        .catch((error) => {
                          let err = {
                            componentName: "LogList",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        })
                      break;
                    }
                      case "SCHEDULE_MASS_INSPECTION_APPOINTMENT": {
                        endpoint = microServices.base +"/v1/appointment/bulk/scheduleInspection";
                        store.dispatch(isLoader(true));
                        APIservice.post(endpoint, action.payload.formData)
                          .then((response)=>{
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "success",
                                message: action.payload.successMessage,
                                componentName: "ScheduleBulkInspection",
                              },
                            });
                          })
                          .catch((error)=>{
                            if (error.response.status !== 422) {
                              store.dispatch({
                                type: "SHOW_ALERT",
                                payload: {
                                  variant: "danger",
                                  message:  action.payload.failMessage,
                                },
                              });
                            } else {
                              let err = {componentName:'ScheduleBulkInspection',statusCode: error.response.status,data: {status:'false'}};
                              store.dispatch(setError(err));
                            }
                          })
                          .finally(()=>{
                            store.dispatch(isLoader(false));
                          })
                        break;
                      }
                      case "BULK_PHOTO_UPLOAD_NOTIFY":
                        endpoint = microServices.base + "/v1/appointment/bulk-photo/notify";
                        APIservice.post(endpoint, action.payload.formData)
                          .then((response) => {
                          })
                          .catch((error) => {
                          });
                      break;
                      case "FETCH_APPOINTMENT_ASSIGN_STAFF_LIST":
                      APIservice.get(action.payload.apptUrl)
                        .then((response) => {
                          if (response && response.data) {
                            store.dispatch({
                              type: "SET_APPOINTMENT_ASSIGN_STAFF_LIST",
                              payload: {
                                data: response.data.data,
                              },
                            });
                          }
                        })
                        .catch((error) => {
                          let err = {
                            componentName: "AppointmentList",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        })
                      break;
                      case "EXPORT_SUPPORT_TASKS":
                        store.dispatch(isLoader(true));
                        APIservice.
                        getBlobDataWithParams(action.payload.exportApi,"")
                        .then((response)=>{
                            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = blobUrl;
                            link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Support Tasks"}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        })
                        .catch((error)=>{
                            if (error.response.status !== 422) {
                                store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant:'danger',
                                        message: 'Unable to Export Support Tasks Report!',
                                    }
                                });
                            } else {
                                let err = {componentName:'SupportTaskExportReport',statusCode: error.response.status,data: error.response.data};
                                store.dispatch(setError(err));
                            }
                        })
                        .finally(()=>{
                            store.dispatch(isLoader(false));
                        });
                      break;

                      case "PRINT_SUPPORT_TASKS":
                        store.dispatch(isLoader(true));
                        APIservice.
                          getBlobDataWithParams(action.payload.printApi,"")
                          .then((response)=>{
                              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = blobUrl;
                              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Support Task"}.pdf`);
                              document.body.appendChild(link);
                              link.click();
                              link.parentNode.removeChild(link);
                          })
                          .catch((error)=>{
                              if (error.response.status !== 422) {
                                  store.dispatch({
                                      type: 'SHOW_ALERT',
                                      payload: {
                                          variant:'danger',
                                          message: 'Unable to Download Report!',
                                      }
                                  });
                              } else {
                                  let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                                  store.dispatch(setError(err));
                              }
                          })
                          .finally(()=>{
                              store.dispatch(isLoader(false));
                          });
                      break;

                      case "PRINT_SUPPORT_TASKS":
                        store.dispatch(isLoader(true));
                        APIservice.
                          getBlobDataWithParams(action.payload.printApi,"")
                          .then((response)=>{
                              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = blobUrl;
                              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Support Task"}.pdf`);
                              document.body.appendChild(link);
                              link.click();
                              link.parentNode.removeChild(link);
                          })
                          .catch((error)=>{
                              if (error.response.status !== 422) {
                                  store.dispatch({
                                      type: 'SHOW_ALERT',
                                      payload: {
                                          variant:'danger',
                                          message: 'Unable to Download Report!',
                                      }
                                  });
                              } else {
                                  let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                                  store.dispatch(setError(err));
                              }
                          })
                          .finally(()=>{
                              store.dispatch(isLoader(false));
                          });
                        break;
                      case "EXPORT_SUBTASKS":
                        store.dispatch(isLoader(true));
                        APIservice.
                        getBlobDataWithParams(action.payload.exportApi,"")
                        .then((response)=>{
                            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = blobUrl;
                            link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Subtasks"}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        })
                        .catch((error)=>{
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                  variant:'danger',
                                  message: 'Unable to Download Report!',
                              }
                            });
                          } else {
                            let err = {componentName:'DownloadPropertyXlsx',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                            store.dispatch(isLoader(false));
                        });
                      break;

                      case "PRINT_SUBTASKS":
                        store.dispatch(isLoader(true));
                        APIservice.
                        getBlobDataWithParams(action.payload.printApi,"")
                        .then((response)=>{
                            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = blobUrl;
                            link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Subtasks"}.pdf`);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        })
                        .catch((error)=>{
                            if (error.response.status !== 422) {
                              store.dispatch({
                                type: 'SHOW_ALERT',
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                }
                              });
                            } else {
                              let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                              store.dispatch(setError(err));
                            }
                        })
                        .finally(()=>{
                            store.dispatch(isLoader(false));
                        });
                      break;

                      case "EXPORT_APPT_TASKS":
                        store.dispatch(isLoader(true));
                        APIservice.
                        getBlobDataWithParams(action.payload.exportApi,"")
                        .then((response)=>{
                            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = blobUrl;
                            link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Appointment Tasks"}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        })
                        .catch((error)=>{
                            if (error.response.status !== 422) {
                                store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant:'danger',
                                        message: 'Unable to Export Appointment Tasks Report!',
                                    }
                                });
                            } else {
                                let err = {componentName:'AppointmentTasksExportReport',statusCode: error.response.status,data: error.response.data};
                                store.dispatch(setError(err));
                            }
                        })
                        .finally(()=>{
                            store.dispatch(isLoader(false));
                        });
                      break;

                      case "PRINT_APPT_TASKS":
                        let printApi = action.payload.printApi;
                        store.dispatch(isLoader(true));
                        APIservice.
                          getBlobDataWithParams(printApi,"")
                          .then((response)=>{
                              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = blobUrl;
                              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Appointment Tasks"}.pdf`);
                              document.body.appendChild(link);
                              link.click();
                              link.parentNode.removeChild(link);
                          })
                          .catch((error)=>{
                              if (error.response.status !== 422) {
                                  store.dispatch({
                                      type: 'SHOW_ALERT',
                                      payload: {
                                          variant:'danger',
                                          message: 'Unable to Download Appointment Tasks Report!',
                                      }
                                  });
                              } else {
                                  let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                                  store.dispatch(setError(err));
                              }
                          })
                          .finally(()=>{
                              store.dispatch(isLoader(false));
                          });
                      break;

                      case "EXPORT_SERVICE_REQUESTS":
                        store.dispatch(isLoader(true));
                        APIservice.
                        getBlobDataWithParams(action.payload.exportApi,"")
                        .then((response)=>{
                            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = blobUrl;
                            link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Service Requests"}.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode.removeChild(link);
                        })
                        .catch((error)=>{
                            if (error.response.status !== 422) {
                                store.dispatch({
                                    type: 'SHOW_ALERT',
                                    payload: {
                                        variant:'danger',
                                        message: 'Unable to Export Service Requests Report!',
                                    }
                                });
                            } else {
                                let err = {componentName:'ServiceRequestsExportReport',statusCode: error.response.status,data: error.response.data};
                                store.dispatch(setError(err));
                            }
                        })
                        .finally(()=>{
                            store.dispatch(isLoader(false));
                        });
                      break;

                      case "PRINT_SERVICE_REQUESTS":
                        store.dispatch(isLoader(true));
                        APIservice.
                          getBlobDataWithParams(action.payload.printApi,"")
                          .then((response)=>{
                              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = blobUrl;
                              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Service Requests"}.pdf`);
                              document.body.appendChild(link);
                              link.click();
                              link.parentNode.removeChild(link);
                          })
                          .catch((error)=>{
                              if (error.response.status !== 422) {
                                  store.dispatch({
                                      type: 'SHOW_ALERT',
                                      payload: {
                                          variant:'danger',
                                          message: 'Unable to Download Service Requests Report!',
                                      }
                                  });
                              } else {
                                  let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                                  store.dispatch(setError(err));
                              }
                          })
                          .finally(()=>{
                              store.dispatch(isLoader(false));
                          });
                      break;

                      case "RECONCILE_BULK_APPOINTMENT": {
                        store.dispatch(isLoader(true));
                        endpoint = microServices.base + "/v1/appointment/bulk-reconcile";
                        APIservice.post(endpoint, action.payload)
                          .then((response) => {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "success",
                                message: "Appointments reconciled successfully",
                                componentName: "ReconcileBulkAppointment",
                              },
                            });
                          })
                          .catch((error) => {
                            if (error.response.status !== 422) {
                              store.dispatch({
                                type: "SHOW_ALERT",
                                payload: {
                                  variant: "danger",
                                  message: "Unable to reconcile appointments!",
                                },
                              });
                            } else {
                              let err = {
                                componentName: "ReconcileBulkAppointment",
                                statusCode: error.response.status,
                                data: error.response.data,
                              };
                              store.dispatch(setError(err));
                            }
                          })
                          .finally(() => {
                            store.dispatch(isLoader(false));
                          });
                        break;
                      }

                      case "FETCH_APPOINTMENT_TASK_LIST":
                        store.dispatch(isLoader(true));
                        APIservice.get(action.payload.apptUrl)
                          .then((response) => {
                            if (response && response.data) {
                              store.dispatch({
                                type: "SET_APPOINTMENT_TASK_LIST",
                                payload: {
                                  data: response.data.data
                                },
                              });
                            }
                          })
                          .catch((error) => {
                            let err = {
                              componentName: "AppointmentList",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          })
                        break;
                      case "EDIT_APPOINTMENT_TASKS":
                        endpoint = `${microServices.base}/v1/appointment/appointment-tasks/edit`;
                        store.dispatch(isLoader(true));
                        APIservice.put(endpoint, action.payload)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: "Appointment tasks edited successfully.",
                              componentName: "EditAppointmentTasks",
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: "Unable to edit appointment tasks!",
                                componentName: "EditAppointmentTasks",
                              },
                            });
                          } else {
                            let err = {
                              componentName: "EditAppointmentTasks",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                      case "FETCH_MULTIPLE_SUBTASK_PHOTO": {
                        endpoint = `${microServices.base}/v1/appointment/subtasks-photos`;
                        if(!isObjEmpty(action.payload))
                        {
                          endpoint = setGridFilter(endpoint, action.payload);
                        }
                        store.dispatch(isLoader(true));
                        APIservice.get(endpoint)
                        .then((response)=>{
                          if(response && response.data){
                            store.dispatch({
                              type: 'SET_MULTIPLE_SUBTASK_PHOTOS',
                              payload: {
                                data:response.data.data,
                                no_of_photos:response.data.meta.total
                              }
                            });
                          }
                        })
                        .catch((error)=>{
                          let err = {
                            componentName:'SubtaskPhotos',
                            statusCode: error.response.status,
                            data: error.response.data
                          };
                          store.dispatch(setError(err));
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                        break;
                      }

                      case "SHARE_ALL_APPOINTMENT_PHOTOS":
                        endpoint = microServices.base+'/v1/appointment/'+action.payload.id+'/photos/share-all';
                        store.dispatch(isLoader(true));
                        APIservice
                        .put(endpoint)
                        .then((response)=>{
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant:'success',
                              message: 'Photos shared successfully.',
                              componentName:'SharedAllAppointmentPhotos'
                            }
                          });
                        })
                        .catch((error)=>{
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant:'danger',
                                message: 'Unable to share Appointment Photos!'
                              }
                            });
                          } else {
                            let err = {componentName:'ShareAllAppointmentPhoto',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                      break;

                      case "FETCH_NEXT_ARRIVAL_DETAILS": {
                        endpoint = `${microServices.base}/v1/appointment/next-arrival-details/${action.payload.apptId}`;
                        store.dispatch(isLoader(true));
                        APIservice.get(endpoint)
                          .then((response) => {
                            if(response?.data) {
                              store.dispatch({
                                type: 'SET_NEXT_ARRIVAL_DETAILS',
                                payload: {
                                  data:response.data.data[0],
                                }
                              });
                            }
                          })
                          .catch((error) => {
                            let err = {
                              componentName:'NextArrivalDetails',
                              statusCode: error.response.status,
                              data: error.response.data
                            };
                            store.dispatch(setError(err));
                          })
                          .finally(() => {
                            store.dispatch(isLoader(false));
                          })
                        ;
                        break;
                      }
                      case "GET_APPOINTMENT_SERVICE_STATS": {
                        endpoint = action.payload.endpoint + "&per_page=0";
                        store.dispatch(isLoader(true));
                        APIservice.get(endpoint)
                          .then((response) => {
                            if (response && response.data) {
                              store.dispatch({
                                type: "SET_APPOINTMENT_SERVICE_STATS",
                                payload: {
                                  data: response.data,
                                },
                              });
                            }
                          })
                          .catch((error) => {
                            let err = {
                              componentName: "serviceStatsList",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          })
                          .finally(() => {
                            store.dispatch(isLoader(false));
                          });
                        break;
                      }

                      case "SCHEDULE_UPLOADED_APPTS": {
                        endpoint = `${microServices.base}/v1/appointments/schedule-uploaded`;
                        store.dispatch(isLoader(true));
                        APIservice.post(endpoint, action.payload.formData)
                          .then((response) => {
                            // do nothing
                          })
                          .catch((error) => {
                            if (error.response.status !== 422) {
                              store.dispatch({
                                type: "SHOW_ALERT",
                                payload: {
                                  variant: "danger",
                                  message: action.payload.errorMessage,
                                },
                              });
                            } else {
                              let err = {
                                componentName: action.payload.componentName,
                                statusCode: error.response.status,
                                data: error.response.data,
                              };
                              store.dispatch(setError(err));
                            }
                          })
                          .finally(() => {
                            store.dispatch(isLoader(false));
                          })
                        ;
                        break;
                      }

                case "BULK_TURN_AROUND_APPOINTMENT":
                  store.dispatch(isLoader(true));
                  endpoint = microServices.base + "/v1/appointment/bulk-turn-around";
                  APIservice.put(endpoint, action.payload.formData)
                  .then((response) => {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "success",
                        message: action?.payload?.successMessage,
                        componentName: "TurnAroundBulkAppointment",
                      },
                    });
                  })
                  .catch((error) => {
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "danger",
                          message: action?.payload?.failMessage,
                        },
                      });
                    } else {
                      let err = {
                        componentName: "TurnAroundBulkAppointment",
                        statusCode: error.response.status,
                        data: error.response.data,
                      };
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                break;


                case "UPDATE_CHECKIN_CHECKOUT_TIME_STATUS_CENTER":
                  endpoint = action.payload.api;
                  APIservice.put(endpoint, action.payload.formData)
                  .then((response) => {

                  })
                  .catch((error) => {

                  })
                  .finally(() => {
                  });
                break;

                case "FETCH_CL_NEW_INVENTORY":
                  endpoint = `${microServices.base}/v2/appointment/${action.payload.id}/appointment-inventory?per_page=0`;
                  APIservice
                      .get(endpoint)
                      .then((response) => {
                          if (response && response.data) {
                              store.dispatch({
                                  type: 'SET_CL_NEW_INVENTORY',
                                  payload: { data: response.data.data },
                              });
                          }
                      })
                      .catch((error) => {
                          if (error.response.status !== 422) {
                              store.dispatch({
                                  type: 'SET_CL_NEW_INVENTORY',
                                  payload: { data: [] },
                              });
                          }
                      })
                      .finally(() => {
                          store.dispatch({ type: 'HIDE_LOADER' });
                      });
                  break;

              case "FETCH_PM_NEW_INVENTORY":
                  endpoint = `${microServices.base}/v2/appointment/${action.payload.id}/manager/appointment-inventory?per_page=0`;
                  APIservice
                      .get(endpoint)
                      .then((response) => {
                          if (response && response.data) {
                              store.dispatch({
                                  type: 'SET_PM_NEW_INVENTORY',
                                  payload: { data: response.data.data },
                              });
                          }
                      })
                      .catch((error) => {
                          if (error.response.status !== 422) {
                              store.dispatch({
                                  type: 'SET_PM_NEW_INVENTORY',
                                  payload: { data: [] },
                              });
                          }
                      })
                      .finally(() => {
                          store.dispatch({ type: 'HIDE_LOADER' });
                      });
                  break;
                  case "DISMISS_APPOINTMENT":
                    endpoint = `${microServices.base}/v1/appointments/${action.payload.appointmentId}/dismiss`;
                    APIservice.post(endpoint, action.payload.formData)
                      .then((response) => {
                        store.dispatch({
                          type: "SHOW_ALERT",
                          payload: {
                            variant: "success",
                            message: action.payload.successMessage,
                            componentName: "DismissAppointment",
                          },
                        });
                        if (typeof action.payload.onSuccess === 'function') {
                          action.payload.onSuccess();
                        }
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "danger",
                              message: action.payload.failMessage,
                            },
                          });
                        } else {
                          let err = {
                            componentName: "DismissAppointment",
                            statusCode: error.response.status,
                            data: error.response.data,
                          };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(() => {
                        
                      });
                    break;
                    case "BULK_SHARE_APPOINTMENT_PHOTO":
                      endpoint =
                        microServices.base +
                        "/v1/appointment/" +
                        action.payload.appointmentId +
                        "/photos/bulk-share";
                      store.dispatch(isLoader(true));
                      APIservice.put(endpoint, action.payload.formData)
                        .then((response) => {
                          store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                              variant: "success",
                              message: action.payload.successMessage,
                              componentName: "bulkShareAppointmentPhoto",
                            },
                          });
                        })
                        .catch((error) => {
                          if (error.response.status !== 422) {
                            store.dispatch({
                              type: "SHOW_ALERT",
                              payload: {
                                variant: "danger",
                                message: action.payload.failMessage,
                              },
                            });
                          } else {
                            let err = {
                              componentName: "bulkShareAppointmentPhoto",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                      break;
                      case "FETCH_APPOINTMENT_SERVICE_CHECKLIST":
                        APIservice.get(action.payload.checklistUrl)
                          .then((response) => {
                            if (response && response.data) {
                              store.dispatch({
                                type: "SET_APPOINTMENT_SERVICE_CHECKLIST",
                                payload: {
                                  data: response.data.data,
                                },
                              });
                            }
                          })
                          .catch((error) => {
                            let err = {
                              componentName: "AppointmentList",
                              statusCode: error.response.status,
                              data: error.response.data,
                            };
                            store.dispatch(setError(err));
                          })
                          .finally(() => {
                          });
                      break;
                
                   

      default:
        break;
  }
};

export default AppointmentMiddleware;


const prepareSuppoertTaskDropdown = (response) => {
  let preparedSuppoertTaskDropdown = [];
  response.data.map((item) => {
    preparedSuppoertTaskDropdown.push({
        value: item?.house_attendant_task_id,
        label: item?.task,
      });
  });
  return preparedSuppoertTaskDropdown;
}
