export const generateInvoice = (data) => {
    return {
        type: 'GENERATE_INVOICE',
        payload: data,
    };
}

export const downloadInvoicePDF = (data) => {
    return {
        type: 'INVOICES_PDF',
        payload: data
    }
}
  

export const generatePayroll = (data) => {
    return {
        type: 'GENERATE_PAYROLL',
        payload: data,
    };
}

export const downloadExcelReport = (data) => {
    return {
        type: 'INVOICES_EXCEL',
        payload: data
    }
}

export const invoiceTriggerPayment = (data) => {
    return {
        type: 'INVOICE_TRIGGER_PAYMENT',
        payload: data
    }
}

export const rcInvoiceTriggerPayment = (data) => {
    return {
        type: 'RC_INVOICE_TRIGGER_PAYMENT',
        payload: data
    }
}

export const fetchRcInvoiceNotification = (data) => {
    return {
        type: 'FETCH_RC_INVOICE_NOTIFICATION',
        payload: data
    }
}

export const getPayrollTotal =  (data) => {
  return {
    type: "GET_PAYROLL_TOTAL",
    payload: data,
  };
};

export const getInternalInvoiceTotal =  (data) => {
    return {
        type: "GET_INTERNAL_INVOICE_TOTAL",
        payload: data,
    };
};

export const getQuickBookInvoice =  (data) => {
    return {
        type: "GET_QUICKBOOK_INVOICE",
        payload: data,
    };
}

export const quickBookInvoiceMappedToService =  (data) => {
    return {
        type: "STORE_QUICKBOOK_INVOICE",
        payload: data,
    };
}

export const quickBookInvoiceMappedToWoItem =  (data) => {
    return {
        type: "STORE_QUICKBOOK_WOITEM",
        payload: data,
    };
}

export const frogfunddownloadInvoicePDF = (data) => {
    return {
        type: 'FROG_FUND_INVOICES_PDF',
        payload: data
    }
}

export const downloadExcelPayrollReport = (data) => {
    return {
        type: 'PAYROLL_REPORT_EXCEL',
        payload: data
    }
}
