import React, { useState, useContext, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { FiChevronRight, FiChevronDown, FiHome, FiUser, FiUsers, FiLifeBuoy, FiFileText, FiCreditCard, FiCalendar, FiBook, FiClipboard, FiTool, FiAirplay } from "react-icons/fi";
import { RiBuilding2Line, RiBuildingLine } from "react-icons/ri";
import Logo from './../../../../assets/img/brand.png';
import MobileLogo from './../../../../assets/img/mobile-logo.png';
import Collapse from 'react-bootstrap/Collapse';
import { LanguageContext } from '../../../containers/Language';
import { useSelector } from 'react-redux';
import { wizardStatusDisplayIds} from '../../../../utils/constUtil';
import {
  AdditionalFeatureNames,
  inventoryAdditionalFeatureId,
  QuickbooksAdditionalFeatureId,
  staffPlannerAdditionalFeatureId
} from '../../../../utils/additionalFeatureUtil';
import {useProfile} from "../../../../hooks/useProfile";

const appointmentSubmenu = ['/cleaner-appointments', '/schedule-appointments', '/cleaner-cancel-appointments', '/cleaner-subtasks', '/cleaner-service-request', '/cleaner-schedule-recurring'];
const inspectionSubmenu = ['/cleaner-inspections', '/cleaner-property-inspection', '/cleaner-facility-inspection'];
const clientSubmenu = ['/client-list','/cleaner-assign-collection-method','/cleaner-property-manager-config'];
const clSubmenu = ['/cleaner-properties', '/cleaner-price-config'];
const staffSubmenu = ['/staff-management', '/hkgroups', '/cleaner-schedule-staff','/cleaner-staff-planner','/cleaner-assign-staff-list','/cleaner-board-assignment','/cleaner-region-area-config'];
const setupSubmenu = [
  {
    submenu: ['/cleaner-region'],
    submenuNumber: 5,
    submenuChildNumber: 1
  },
  {
    submenu: ['/cleaner-area'],
    submenuNumber: 5,
    submenuChildNumber: 2
  },
  {
    submenu: ['/cleaner-service-config'],
    submenuNumber: 5,
    submenuChildNumber: 3
  },
  {
    submenu: ['/cleaner-property-messaging'],
    submenuNumber: 5,
    submenuChildNumber: 4
  },
  {
    submenu: ['/cleaner-inspection-template'],
    submenuNumber: 1
  },
  {
    submenu: ['/cleaner-inventory'],
    submenuNumber: 2
  },
  {
    submenu: ['/cleaner-service-checklist'],
    submenuNumber: 3
  },
  {
    submenu: ['/services', '/services-automation'],
    submenuNumber: 4
  },
  {
    submenu: ['/cleaner-work-order-items'],
    submenuNumber: 6
  },
  {
    submenu: ['/cleaner-appointment-messaging'],
    submenuNumber: 7,
  },
  {
    submenu: ['/cleaner-evolve'],
    submenuNumber: 15,
    submenuChildNumber: 1
  },
  {
    submenu: ['/cleaner-vacasa'],
    submenuNumber: 15,
    submenuChildNumber: 2
  },
  {
    submenu: ['/cleaner-itrip-workorder'],
    submenuNumber: 15,
    submenuChildNumber: 3
  },
  {
    submenu: ['/cleaner-inventory-smart-form-page'],
    submenuNumber: 15,
    submenuChildNumber: 4
  },
  {
    submenu: ['/cleaner-sojo-integration'],
    submenuNumber: 15,
    submenuChildNumber: 5
  },
  {
    submenu: ['/cleaner-ical-aribnb'],
    submenuNumber: 15,
    submenuChildNumber: 6
  },
  {
    submenu: ['/cleaner-ical-avhr'],
    submenuNumber: 15,
    submenuChildNumber: 7
  },
  {
    submenu: ['/cleaner-ical-avantio'],
    submenuNumber: 15,
    submenuChildNumber: 8
  },
  {
    submenu: ['/cleaner-alt-ical-bookingauto'],
    submenuNumber: 15,
    submenuChildNumber: 9
  },
  {
    submenu: ['/cleaner-alt-ical-bookingcom'],
    submenuNumber: 15,
    submenuChildNumber: 10
  },{
    submenu: ['/cleaner-ical-bookingsync'],
    submenuNumber: 15,
    submenuChildNumber: 11
  },
  {
    submenu: ['/cleaner-alt-ical-brightside'],
    submenuNumber: 15,
    submenuChildNumber: 12
  },
  {
    submenu: ['/cleaner-ical-directpms'],
    submenuNumber: 15,
    submenuChildNumber: 13
  },
  {
    submenu: ['/cleaner-alt-ical-escapia'],
    submenuNumber: 15,
    submenuChildNumber: 14
  },
  {
    submenu: ['/cleaner-alt-ical-guesty'],
    submenuNumber: 15,
    submenuChildNumber: 15
  },
  {
    submenu: ['/cleaner-alt-ical-guestydirect'],
    submenuNumber: 15,
    submenuChildNumber: 16
  },
  {
    submenu: ['/cleaner-alt-ical-hospitable'],
    submenuNumber: 15,
    submenuChildNumber: 17
  },
  {
    submenu: ['/cleaner-alt-ical-hostaway'],
    submenuNumber: 15,
    submenuChildNumber: 18
  },
  {
    submenu: ['/cleaner-alt-ical-hostfully'],
    submenuNumber: 15,
    submenuChildNumber: 19
  },
  {
    submenu: ['/cleaner-alt-ical-hostify'],
    submenuNumber: 15,
    submenuChildNumber: 20
  },
  {
    submenu: ['/cleaner-alt-ical-houfy'],
    submenuNumber: 15,
    submenuChildNumber: 21
  },
  {
    submenu: ['/cleaner-alt-ical-igms'],
    submenuNumber: 15,
    submenuChildNumber: 22
  },
  {
    submenu: ['/cleaner-inventory-smart-form-page'],
    submenuNumber: 15,
    submenuChildNumber: 23
  },
  {
    submenu: ['/cleaner-alt-ical-itrip'],
    submenuNumber: 15,
    submenuChildNumber: 24
  },
  {
    submenu: ['/cleaner-alt-ical-ivacation'],
    submenuNumber: 15,
    submenuChildNumber: 25
  },
  {
    submenu: ['/cleaner-alt-ical-janiis'],
    submenuNumber: 15,
    submenuChildNumber: 26
  },
  {
    submenu: ['/cleaner-alt-ical-kigo'],
    submenuNumber: 15,
    submenuChildNumber: 27
  },
  {
    submenu: ['/cleaner-alt-ical-livrez'],
    submenuNumber: 15,
    submenuChildNumber: 28
  },
  {
    submenu: ['/cleaner-alt-ical-liverezdirect'],
    submenuNumber: 15,
    submenuChildNumber: 29
  },
  {
    submenu: ['/cleaner-alt-ical-lmpm'],
    submenuNumber: 15,
    submenuChildNumber: 30
  },
  {
    submenu: ['/cleaner-alt-ical-lodgify'],
    submenuNumber: 15,
    submenuChildNumber: 31
  },
  {
    submenu: ['/cleaner-alt-ical-lodgix'],
    submenuNumber: 15,
    submenuChildNumber: 32
  },
  {
    submenu: ['/cleaner-alt-ical-myvr'],
    submenuNumber: 15,
    submenuChildNumber: 33
  },
  {
    submenu: ['/cleaner-alt-ical-onerooftop'],
    submenuNumber: 15,
    submenuChildNumber: 34
  },
  {
    submenu: ['/cleaner-alt-ical-oscar'],
    submenuNumber: 15,
    submenuChildNumber: 35
  },
  {
    submenu: ['/cleaner-alt-ical-ownerrezsetup'],
    submenuNumber: 15,
    submenuChildNumber: 36
  },
  {
    submenu: ['/cleaner-alt-ical-pmbo'],
    submenuNumber: 15,
    submenuChildNumber: 37
  },
  {
    submenu: ['/cleaner-alt-ical-rezkey'],
    submenuNumber: 15,
    submenuChildNumber: 38
  },
  {
    submenu: ['/cleaner-alt-ical-skyRun'],
    submenuNumber: 15,
    submenuChildNumber: 39
  },
  {
    submenu: ['/cleaner-alt-ical-smartBnb'],
    submenuNumber: 15,
    submenuChildNumber: 40
  },
  {
    submenu: ['/cleaner-alt-ical-smoobu'],
    submenuNumber: 15,
    submenuChildNumber: 41
  },
  {
    submenu: ['/cleaner-alt-ical-streamlinedirect'],
    submenuNumber: 15,
    submenuChildNumber: 42
  },
  {
    submenu: ['/cleaner-alt-ical-streamline'],
    submenuNumber: 15,
    submenuChildNumber: 43
  },
  {
    submenu: ['/cleaner-alt-ical-tokeet'],
    submenuNumber: 15,
    submenuChildNumber: 44
  },
  {
    submenu: ['/cleaner-alt-ical-track'],
    submenuNumber: 15,
    submenuChildNumber: 45
  },
  {
    submenu: ['/cleaner-alt-ical-trackdirectsetup'],
    submenuNumber: 15,
    submenuChildNumber: 46
  },
  {
    submenu: ['/cleaner-alt-ical-tripadvisor'],
    submenuNumber: 15,
    submenuChildNumber: 47
  },
  {
    submenu: ['/cleaner-alt-ical-uplisting'],
    submenuNumber: 15,
    submenuChildNumber: 48
  },
  {
    submenu: ['/cleaner-alt-ical-vacationrentaldesk'],
    submenuNumber: 15,
    submenuChildNumber: 49
  },
  {
    submenu: ['/cleaner-alt-ical-vacationrentpro'],
    submenuNumber: 15,
    submenuChildNumber: 50
  },
  {
    submenu: ['/cleaner-alt-ical-v12'],
    submenuNumber: 15,
    submenuChildNumber: 51
  },
  {
    submenu: ['/cleaner-alt-ical-vrbo'],
    submenuNumber: 15,
    submenuChildNumber: 52
  },
  {
    submenu: ['/cleaner-alt-ical-vrm'],
    submenuNumber: 15,
    submenuChildNumber: 53
  },
  {
    submenu: ['/cleaner-alt-ical-webrezpro'],
    submenuNumber: 15,
    submenuChildNumber: 54
  },
  {
    submenu: ['/cleaner-alt-ical-wphotel'],
    submenuNumber: 15,
    submenuChildNumber: 55
  },
  {
    submenu: ['/cleaner-alt-ical-yourporter'],
    submenuNumber: 15,
    submenuChildNumber: 56
  },
  {
    submenu: ['/cleaner-alt-ical-escapia'],
    submenuNumber: 15,
    submenuChildNumber: 57
  },
  {
    submenu: ['/cleaner-alt-ical-365villas'],
    submenuNumber: 15,
    submenuChildNumber: 58
  },
  {
    submenu: ['/cleaner-alt-ical-cirrus'],
    submenuNumber: 15,
    submenuChildNumber: 59
  },
  {
    submenu: ['/cleaner-alt-ical-beds24'],
    submenuNumber: 15,
    submenuChildNumber: 60
  },
  {
    submenu: ['/cleaner-alt-ical-uplisting-integration'],
    submenuNumber: 15,
    submenuChildNumber: 61
  },
  {
    submenu: ['/cleaner-alt-ical-hostawaydirect', '/cleaner-alt-ical-hostawaydirectsetup'],
    submenuNumber: 15,
    submenuChildNumber: 62
  },
  {
    submenu: ['/cleaner-workorder-department'],
    submenuNumber:6,
    submenuChildNumber: 4
  },
  {
    submenu: ['/cleaner-alt-ical-supercontrol'],
    submenuNumber: 15,
    submenuChildNumber: 63
  },
  {
    submenu: ['/cleaner-inventory-template'],
    submenuNumber: 1
  },
  {
    submenu: ['/cleaner-alt-ical-alluradirect'],
    submenuNumber: 15,
    submenuChildNumber: 64
  },
];
const billingSubmenu = ['/cleaner-create-payroll', '/cleaner-view-payroll', '/cleaner-create-invoices', '/cleaner-view-invoices', '/cleaner-rc-invoices', '/cleaner-stripe-connect', '/cleaner-tilled-connect','/cleaner-cardpointe-connect'];

const reportsSubmenu    = ['/cleaner-inventory-report', '/cleaner-property-inventory-report','/cleaner-productivity-report','/cleaner-inspector-productivity-report','/cleaner-failed-inspection-items-reports','/cleaner-completed-times-reports', '/cleaner-timesheet-report','/cleaner-hhs-weekly-invoice-report','/cleaner-daily-closeout-report','/cleaner-hhs-service-tracker-report','/cleaner-hhs-employee-piece-report','/cleaner-merged-tracker-report','/cleaner-property-manager-inventory-report','/cleaner-new-inventory-report','/cleaner-hhs-employee-piece-rate-report','/cleaner-jobs-and-revenue-report', '/cleaner-payroll-report'];
const workorderSubmenu  = ['/cleaner-work-orders','/cleaner-analytics'];
const dashboardSubmenu = ['/cleaner-dashboard', '/cleaner-status-center','/cleaner-unit-status-view']
const commonAreaSubmenu = ['/cleaner-common-areas-list', '/cleaner-add-common-area','/cleaner-common-areas-template']
const commonAreaAppointmentsSubmenu = ['/cleaner-common-areas-appointments', '/cleaner-common-areas-schedule-appointments']
const CleanerSidebar = ({props, ref, setHoveredMenuIcon}) => {
  const location = useLocation();
  const { hasAccessToFeature } = useProfile();
  const [open, setOpen] = useState(-1);
  const [subOpen, setSubOpen] = useState(-1);
  const [subChildOpen, setSubChildOpen] = useState(-1);

  const toggleMenu = (x) => {
    setOpen(open === x ? -1 : x);
  }
  const toggleSubMenu = (x) => setSubOpen(subOpen === x ? -1 : x);
  const toggleSubChildMenu = (x) => setSubChildOpen(subChildOpen === x ? -1 : x);

  const { localised } = useContext(LanguageContext);
  const loggedInUser = useSelector((state) => state.authentication?.profile) || '';
  const isProxy = useSelector((state) => state.authentication?.isProxy) || false;
  const qbCompanyInfo = useSelector((state) => state.billing.getQBCompanyInfo) || [];
  const isUserActive = useSelector((state) => state.user?.user?.Status) || false;
  const wizard_status = useSelector((state) => state.global?.wizard_status);


  let inspectorAdditionalFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 1);
  let workorderAdditionalFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 2);
  let serviceAutomationOnOff = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 10);
  let serviceChecklistAdditionalFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 13);
  let supportTaskAdditionalFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 3);
  let subTasksAdditionalFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 11);
  let reportedIssuesAdditionalFeature = hasAccessToFeature(AdditionalFeatureNames.ReportedIssues);
  let appointmentMessagingAdditionalFeature = hasAccessToFeature(AdditionalFeatureNames.AppointmentMessaging);
  let scheduleStaffFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 16);
  let housekeeperTimeAdditionalFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 5);
  let timeClockFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 15);
  let sojoFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 23);
  let dailyCloseoutReportFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 14);

  let adaptiveAssignmentAdditionalFeature = loggedInUser?.additional_feature_access?.some((item) => item.additional_features_id === 8);
  let commonAreaAdditionalFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 4);
  let propelrPaymentsFeature = loggedInUser.additional_feature_access.some(item => item.additional_features_id === 25);
  let unitStatusViewAdditionalFeature = loggedInUser?.additional_feature_access?.some((item) => item.additional_features_id === 26);
  let inventorySmartAdditionalFeature = loggedInUser?.additional_feature_access?.some((item) => item.additional_features_id === 17);

  let hasHHSFeaturesAccess = loggedInUser?.has_hhs_features_access || false;
  const sidebarCount = useSelector((state) => state.company.sidebarCount) || '';
  let apptAcceptFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === 34);
  let newInventoryFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === inventoryAdditionalFeatureId);
  let oldInventoryFeature = !newInventoryFeature;
  let staffPlannerFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === staffPlannerAdditionalFeatureId);
  let quickbookAdditionalFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === QuickbooksAdditionalFeatureId);

  const subQuickBookSubMenu = [
    {
      submenu: ['/cleaner-quickbook'],
      submenuNumber: 98,
      submenuChildNumber: 0,
    },
   {
      submenu: ['/cleaner-service-mapping'],
      submenuNumber: 98,
      submenuChildNumber: 1
    },
    {
      submenu: ['/cleaner-property-manager-mapping'],
      submenuNumber:98,
      submenuChildNumber: 2
    },
    {
      submenu: ['/cleaner-staff-mapping'],
      submenuNumber:98,
      submenuChildNumber: 3
    },
    {
      submenu: ['/cleaner-workorder-item-mapping'],
      submenuNumber:98,
      submenuChildNumber: 3
    }
  ];

  const activeMenu = () => {
    if (location) {
      let selectedSetupMenu = setupSubmenu.find(obj => {
        return obj.submenu.includes(location.pathname);
      });

      let selecteQuickBookMenu = subQuickBookSubMenu.find(obj => {
        return obj.submenu.includes(location.pathname);
      });

      if (appointmentSubmenu.includes(location.pathname))
        toggleMenu(1);
      else if (workorderSubmenu.includes(location.pathname))
        toggleMenu(2);
      else if (inspectionSubmenu.includes(location.pathname))
        toggleMenu(3);
      else if (clientSubmenu.includes(location.pathname))
        toggleMenu(4);
      else if (clSubmenu.includes(location.pathname))
        toggleMenu(5);
      else if (staffSubmenu.includes(location.pathname))
        toggleMenu(6);
      else if (billingSubmenu.includes(location.pathname))
        toggleMenu(7);
      else if (reportsSubmenu.includes(location.pathname))
        toggleMenu(8);
      else if (selectedSetupMenu) {
        toggleMenu(10);
        toggleSubMenu(selectedSetupMenu.submenuNumber);
        toggleSubChildMenu(selectedSetupMenu.submenuChildNumber);
      }
      else if (dashboardSubmenu.includes(location.pathname)) {
        toggleMenu(0);
      }else if(selecteQuickBookMenu){
        toggleMenu(7);
        toggleSubMenu(selecteQuickBookMenu?.submenuNumber);
        toggleSubChildMenu(selecteQuickBookMenu.submenuChildNumber);
      }
      else if(commonAreaSubmenu.includes(location.pathname)) {
        toggleMenu(44);
      }
      else if(commonAreaAppointmentsSubmenu.includes(location.pathname)) {
        toggleMenu(55);
      }
    }
  }

  useEffect(() => {
    activeMenu();
  }, []);

  useEffect(() => {
    if (open === -1) {
      activeMenu();
    }
  }, [open]);

  return (
    <div className="sidebar custome-scrollbar">
      <div className="sidebar-header">
        <NavLink to="" className="brand" >
          <img className="desktop-logo img-fluid" src={Logo} alt="ResortCleaning Inc. Logo" />
          <img className="mobile-logo" src={MobileLogo} alt="ResortCleaning Inc. Logo" />
        </NavLink >
      </div>
      {(isProxy || (loggedInUser.status_id != 4 && loggedInUser.owner_status_id != 4 && (loggedInUser.companyData.wizard_progress!=1 || loggedInUser.companyData.wizard_progress ==1 && wizard_status == 4)))  &&
      <ul className='menu-list'>

        {
          (loggedInUser?.resource_access?.dashboard?.roles?.includes('manage_dashboard')) && (
            <li className='menu-item'>
              <NavLink to="/cleaner-dashboard" activeClassName="is-active" isActive={(match, location) => {
                if (match != null) {
                  return true;
                }
                return false;
              }}>
                <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiHome /></span>
                <span className='menu-label'>{localised.dashboard}</span>
              </NavLink >
            </li>
          )
        }

        {/* <li className="menu-item">
          <Link to="#" activeClassName="is-active">
            <span className="menu-icon">
              <FiHome />
            </span>
            <span className="menu-label" onClick={() => toggleMenu(0)}>
              {localised.dashboard}
            </span>
            <span className="menu-arrow">
              {open === 0 ? <FiChevronDown /> : <FiChevronRight />}
            </span>
          </Link>

          <Collapse in={open === 0}>
            <ul className="sub-menu">
              <li>
                <NavLink to="/cleaner-dashboard" activeClassName="is-active">
                  <span className="menu-label">{localised.view}</span>
                </NavLink>
              </li>
            </ul>
          </Collapse>
        </li> */}

{
          (loggedInUser?.resource_access?.dashboard?.roles?.includes('manage_status_center')) && (
            <li className='menu-item'>
              <NavLink to="/cleaner-status-center" activeClassName="is-active" isActive={(match, location) => {
                if (match != null) {
                  return true;
                }
                return false;
              }}>
                <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiLifeBuoy /></span>
                <span className='menu-label'>{localised.statusCenter}</span>
              </NavLink >
            </li>
          )
        }

        {
          (unitStatusViewAdditionalFeature && loggedInUser?.resource_access?.property?.roles?.includes('manage_unit_status_view')) && (
            <li className='menu-item'>
              <NavLink to="/cleaner-unit-status-view" activeClassName="is-active" isActive={(match, location) => {
                if (match != null) {
                  return true;
                }
                return false;
              }}>
                <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiAirplay /></span>
                <span className='menu-label'>{localised.unitStatusView}</span>
              </NavLink >
            </li>
          )
        }


        {/* {(loggedInUser?.resource_access?.dashboard?.roles?.includes('manage_dashboard')
            || loggedInUser?.resource_access?.dashboard?.roles?.includes('manage_status_center')
          ) && (
            <li className='menu-item'>
              <Link to="#" activeClassName="is-active" onClick={() => toggleMenu(0)}>
                <span className='menu-icon' onMouseEnter={setHoveredMenuIcon}><FiHome /></span>
                <span className='menu-label' >{localised.dashboard}</span>
                <span className='menu-arrow'>
                  {
                    open === 0 ? (
                      < FiChevronDown />
                    ) : (
                      <FiChevronRight />
                    )
                  }
                </span>
              </Link >
              <Collapse in={open === 0}>
                <ul className='sub-menu'>
                  {loggedInUser?.resource_access?.dashboard?.roles?.includes('manage_dashboard') &&
                    <li>
                      <NavLink to="/cleaner-dashboard" activeClassName="is-active">
                        <span className='menu-label'>{localised.view}</span>
                      </NavLink >
                    </li>
                  }
                  {loggedInUser?.resource_access?.dashboard?.roles?.includes('manage_status_center') &&
                    <li>
                      <NavLink to="/cleaner-status-center" activeClassName="is-active">
                        <span className='menu-label'>{localised.statusCenter}</span>
                      </NavLink >
                    </li>
                  }
                </ul>
              </Collapse>
            </li>
          )
        } */}


        {(loggedInUser?.resource_access?.appointments) && (
          <li className='menu-item'>
            <Link to="#" onClick={() => toggleMenu(1)} className={open === 1 ? 'is-active' : ''}>
              <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiCalendar /> </span>
              <span className='menu-label' onClick={() => toggleMenu(1)}>{localised.appointments}</span>
              <span className='menu-arrow'>
                {
                  open === 1 ? (
                    < FiChevronDown />
                  ) : (
                    <FiChevronRight />
                  )
                }
              </span>
            </Link >
            <Collapse in={open === 1}>
              <ul className='sub-menu'>
                {(loggedInUser?.resource_access?.appointments?.roles?.includes('manage_appointments')) && (

                  <li>
                    <NavLink to="/cleaner-appointments" activeClassName="is-active">
                      <span className='menu-label'>{localised.view}</span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.appointments?.roles?.includes('schedule_appointment')) && (
                  <li>
                    <NavLink to="/schedule-appointments" activeClassName="is-active">
                      <span className='menu-label'>{localised.schedule}  </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.appointments?.roles?.includes('cancel_appointment')) && (
                  <li>
                    <NavLink to="/cleaner-cancel-appointments" activeClassName="is-active">
                      <span className='menu-label'>{localised.cancelAppointments} </span>
                    </NavLink >
                  </li>
                )}

                {(loggedInUser?.resource_access?.appointments?.roles?.includes('manage_subtask') && subTasksAdditionalFeature) && (
                  <li>
                    <NavLink to="/cleaner-subtasks" activeClassName="is-active">
                      <span className='menu-label'>{localised.subtasks} <span className='badge'>{sidebarCount?.subtask}</span> </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.appointments?.roles?.includes('manage_subtask') && reportedIssuesAdditionalFeature) && (
                  <li>
                    <NavLink to="/cleaner-reported-issues" activeClassName="is-active">
                      <span className='menu-label'>{localised.reportedIssuesHistory}</span>
                    </NavLink >
                  </li>
                )}
                {((loggedInUser?.resource_access?.appointments?.roles?.includes('manage_service_request')) && supportTaskAdditionalFeature) && (
                  <li>
                    <NavLink to="/cleaner-service-request" activeClassName="is-active">
                      <span className='menu-label'>{localised.serviceRequests} <span className='badge'>{sidebarCount?.serviceRequest}</span></span>
                    </NavLink >
                  </li>)}

                <li>
                  <NavLink to="/cleaner-schedule-recurring" activeClassName="is-active">
                    <span className='menu-label'>{localised.scheduleRecurring}  </span>
                  </NavLink >
                </li>
                {sidebarCount?.evolve_url &&
                  <li>
                    <NavLink to="/cleaner-upload-evolve" activeClassName="is-active">
                      <span className='menu-label'>{localised.uploadEvolve}  </span>
                    </NavLink >
                  </li>
                }
              </ul>
            </Collapse>
          </li>
        )}


       {/*  {
          (loggedInUser?.resource_access?.workorder && workorderAdditionalFeature && loggedInUser?.resource_access?.workorder?.roles?.includes("manage_workorder")) && (
            <li className='menu-item'>
              <NavLink to="/cleaner-work-orders" activeClassName="is-active" isActive={(match, location) => {
                if (match != null) {
                  return true;
                }
                return false;
              }}>
                <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiBook /></span>
                <span className='menu-label'>{localised.workOrders}</span>
              </NavLink >
            </li>
          )
        }
             */}
         {
          (loggedInUser?.resource_access?.workorder && workorderAdditionalFeature) &&
          (<li className='menu-item'>
            <Link to="#" onClick={() => toggleMenu(2)} className={open === 2 ? 'is-active' : ''}>
              <span className='menu-icon'><FiBook /></span>
              <span className='menu-label' >{localised.workOrders}</span>
              <span className='menu-arrow'>
                {
                  open === 2 ? (
                    < FiChevronDown />
                  ) : (
                    <FiChevronRight />
                  )
                }
              </span>
            </Link >

            <Collapse in={open === 2}>
              <ul className='sub-menu'>
                {
                  (loggedInUser?.resource_access?.workorder?.roles?.includes("manage_workorder")) && (
                    <>
                      <li>
                        <NavLink to="/cleaner-work-orders" activeClassName="is-active">
                          <span className='menu-label'>{localised.view}</span>
                        </NavLink >
                      </li>
                      </>

                  )
                  }
                  {
                  (loggedInUser?.resource_access?.workorder?.roles?.includes("workorder_analytics")) && (<>
                      <li className='menu-item'>
                        <NavLink to="/cleaner-analytics"  activeClassName="is-active">
                          <span className="menu-label">{localised.analytics}</span>
                        </NavLink>
                      </li>
                    </>)

                }
              </ul>
            </Collapse>
          </li>)
        }

        {(loggedInUser?.resource_access?.inspection) && (inspectorAdditionalFeature) && <li className='menu-item'>
          <Link to="#" onClick={() => toggleMenu(3)} className={open === 3 ? 'is-active' : ''}>
            <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiClipboard /></span>
            <span className='menu-label' onClick={() => toggleMenu(3)}>{localised.inspections}</span>
            <span className='menu-arrow'>
              {
                open === 3 ? (
                  < FiChevronDown />
                ) : (
                  <FiChevronRight />
                )
              }
            </span>
          </Link >

          <Collapse in={open === 3}>
            <ul className='sub-menu'>

              {(loggedInUser?.resource_access?.inspection?.roles?.includes('manage_inspection')) && (
                <li>
                  <NavLink to="/cleaner-inspections" activeClassName="is-active">
                    <span className='menu-label'>{localised.view}</span>
                  </NavLink >
                </li>
              )}
              {(loggedInUser?.resource_access?.inspection?.roles?.includes('manage_schedule')) && (
                <>
                  <li>
                    <NavLink to="/cleaner-property-inspection" activeClassName="is-active">
                      <span className='menu-label'>{localised.propertyInspection} </span>
                    </NavLink >
                  </li>
                  <li>
                    <NavLink to="/cleaner-facility-inspection" activeClassName="is-active">
                      <span className='menu-label'>{localised.facilityInspection} </span>
                    </NavLink >
                  </li>
                </>
              )}
            </ul>
          </Collapse>
        </li>}
        {(loggedInUser?.resource_access?.common_area) && commonAreaAdditionalFeature &&
          <li className="menu-item">
            <Link to="#" onClick={() => toggleMenu(55)} className={open === 55 ? 'is-active' : ''}>
              <span className="menu-icon" onMouseEnter={setHoveredMenuIcon}>
                <RiBuildingLine />
              </span>
              <span className="menu-label" onClick={() => toggleMenu(55)}>
                {localised.commonAreasAppointments}
              </span>
              <span className="menu-arrow">
                {open === 55 ? <FiChevronDown /> : <FiChevronRight />}
              </span>
            </Link>

            <Collapse in={open === 55}>
              <ul className="sub-menu">
                <li>
                  <NavLink to="/cleaner-common-areas-appointments" activeClassName="is-active">
                    <span className="menu-label">{localised.view}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/cleaner-common-areas-schedule-appointments" activeClassName="is-active">
                    <span className="menu-label">{localised.schedule} </span>
                  </NavLink>
                </li>
              </ul>
            </Collapse>
          </li>
        }
        {(loggedInUser?.resource_access?.common_area) && commonAreaAdditionalFeature && 
          <li className="menu-item">
            <Link to="#" onClick={() => toggleMenu(44)} className={open === 44 ? 'is-active' : ''}>
              <span className="menu-icon" onMouseEnter={setHoveredMenuIcon}>
                <RiBuildingLine />
              </span>
              <span className="menu-label" onClick={() => toggleMenu(44)}>
                {localised.commonAreas}
              </span>
              <span className="menu-arrow">
                {open === 44 ? <FiChevronDown /> : <FiChevronRight />}
              </span>
            </Link>
          
            <Collapse in={open === 44}>
              <ul className="sub-menu">
              {(loggedInUser?.resource_access?.common_area?.roles?.includes('manage_common_area')) && (
                <li>
                  <NavLink to="/cleaner-common-areas-list" activeClassName="is-active">
                    <span className="menu-label">{localised.view}</span>
                  </NavLink>
                </li>
              )}
                <li>
                  <NavLink to="/cleaner-add-common-area" activeClassName="is-active">
                    <span className="menu-label">{localised.addCommonArea} </span>
                  </NavLink>
                </li>
                {(loggedInUser?.resource_access?.common_area?.roles?.includes('template')) && 
                (<li>
                  <NavLink to="/cleaner-common-areas-template" activeClassName="is-active">
                    <span className="menu-label">{localised.commonAreaTemplate} </span>
                  </NavLink>
                </li>)
                }
              </ul>
            </Collapse>
          </li>
        }
        {/* {
          (loggedInUser?.resource_access?.cp_propertymanager && loggedInUser?.resource_access?.cp_propertymanager?.roles?.includes('manage_propertymanger')) && (
            <li className='menu-item'>
              <NavLink to="/client-list" activeClassName="is-active" isActive={(match, location) => {
                if (match != null) {
                  return true;
                }
                return false;
              }}>
                <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiUser /></span>
                <span className='menu-label'>{localised.propertyManagers}</span>
              </NavLink >
            </li>
          )
        } */}

        {(loggedInUser?.resource_access?.cp_propertymanager) && (
          <li className='menu-item'>
            <Link to="#" className={open === 4 ? 'is-active' : ''}>
              <span className='menu-icon'><FiUser /></span>
              <span className='menu-label' onClick={() => toggleMenu(4)}>{localised.propertyManagers}</span>
              <span className='menu-arrow'>
                {
                  open === 4 ? (
                    < FiChevronDown />
                  ) : (
                    <FiChevronRight />
                  )
                }
              </span>
            </Link >

            <Collapse in={open === 4}>
              <ul className='sub-menu'>
                {(loggedInUser?.resource_access?.cp_propertymanager?.roles?.includes('manage_propertymanger')) && (
                  <li>
                    <NavLink to="/client-list" activeClassName="is-active" isActive={(match, location) => {
                      if (match != null) {
                        return true;
                      }
                      return false;
                    }}>
                      <span className='menu-label'>{localised.view}</span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.cp_propertymanager?.roles?.includes('manage_assign_collection_method')) && (
                <><li>
                  <NavLink to="/cleaner-assign-collection-method" activeClassName="is-active">
                      <span className="menu-label">
                        {localised.assignCollectionMethod}
                      </span>
                  </NavLink>
                </li>
               </>
                )}
                {(loggedInUser?.resource_access?.cp_propertymanager?.roles?.includes('manage_property_manager_config')) && subTasksAdditionalFeature &&
                  <li>
                    <NavLink to="/cleaner-property-manager-config" activeClassName="is-active">
                      <span className="menu-label">
                        {localised.propertyManagerConfig}
                      </span>
                  </NavLink>
                </li>
                }
                {/* {(loggedInUser?.resource_access?.cp_propertymanager?.roles?.includes('pm_config')) && (
                  <></>
                )} */}
              </ul>
            </Collapse>
          </li>
        )}

        {(loggedInUser?.resource_access?.property) && (
          <li className='menu-item'>
            <Link to="#" className={open === 5 ? 'is-active' : ''}>
              <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><RiBuilding2Line /></span>
              <span className='menu-label' onClick={() => toggleMenu(5)}>{localised.properties}</span>
              <span className='menu-arrow'>
                {
                  open === 5 ? (
                    < FiChevronDown />
                  ) : (
                    <FiChevronRight />
                  )
                }
              </span>
            </Link >

            <Collapse in={open === 5}>
              <ul className='sub-menu'>
                {(loggedInUser?.resource_access?.property?.roles?.includes('manage_property')) && (
                  <li>
                    <NavLink to="/cleaner-properties" activeClassName="is-active" isActive={(match, location) => {
                      if (match != null) {
                        return true;
                      }
                      return false;
                    }}>
                      <span className='menu-label'>{localised.view}</span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.property?.roles?.includes('create_property')) && (
                  <></>
                )}
                {(loggedInUser?.resource_access?.property?.roles?.includes('service_config_property')) && (
                  <></>
                )}
                {(loggedInUser?.resource_access?.property?.roles?.includes('price_config_property')) && (
                  <li>
                    <NavLink to="/cleaner-price-config" activeClassName="is-active" >
                      <span className='menu-label'>{localised.priceConfig}   </span>
                    </NavLink >
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        {(loggedInUser?.resource_access?.staff) && (
          <li className='menu-item'>
            <Link to="#" className={open === 6 ? 'is-active' : ''}>
              <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiUsers /></span>
              <span className='menu-label' onClick={() => toggleMenu(6)}>{localised.staff} </span>
              <span className='menu-arrow'>
                {
                  open === 6 ? (
                    < FiChevronDown />
                  ) : (
                    <FiChevronRight />
                  )
                }
              </span>
            </Link >

            <Collapse in={open === 6}>
              <ul className='sub-menu'>
                {(loggedInUser?.resource_access?.staff?.roles?.includes('manage_staff')) && (
                  <li>
                    <NavLink activeClassName="is-active" to="/staff-management" isActive={(match, location) => {
                      if (match != null) {
                        return true;
                      }
                      return false;
                    }}>
                      <span className='menu-label'>{localised.view} </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.staff?.roles?.includes('manage_assign_staff')) && (
                  <li>
                    <NavLink to="/cleaner-assign-staff-list" activeClassName="is-active">
                      <span className='menu-label'>{localised.assignStaff}
                       {
                        apptAcceptFeature &&
                        <span className='badge'>{sidebarCount?.rejected_appointment_count}</span>

                       }

                       </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.staff?.roles?.includes('manage_staff_schedule') && scheduleStaffFeature) && (
                  <li>
                    <NavLink to="/cleaner-schedule-staff" activeClassName="is-active"
                      isActive={(match, location) => {
                        if (match != null) {
                          return true;
                        }
                        return false;
                      }}
                    >
                      <span className='menu-label'>
                        {localised.scheduleStaff}<span className='badge'>{sidebarCount?.rejected_schedule_shifts_count}</span>
                      </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.staff?.roles?.includes('manage_schedule')) && (
                  <></>
                )}
                {(loggedInUser?.resource_access?.staff?.roles?.includes('manage_schedule')) && (
                  <></>
                )}
                {(loggedInUser?.resource_access?.staff?.roles?.includes('manage_staff_planner') && staffPlannerFeature) && (
                  <li>
                  <NavLink to="/cleaner-staff-planner" activeClassName="is-active">
                    <span className='menu-label'>{localised.staffPlanner}</span>
                  </NavLink>
                  </li>
                )}
                {(loggedInUser?.resource_access?.staff?.roles?.includes('manage_group')) && (
                  <li>
                    <NavLink to="/hkgroups" activeClassName="is-active">
                      <span className='menu-label'> {localised.hkGroups}   </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.appointments?.roles?.includes('adaptive_assignment') && adaptiveAssignmentAdditionalFeature) && (
                  <li>
                    <NavLink to="/cleaner-board-assignment" activeClassName="is-active">
                      <span className="menu-label">
                        {localised.boardAssignment}{" "}
                      </span>
                    </NavLink>
                  </li>
                )}
                {(loggedInUser?.resource_access?.staff?.roles?.includes('manage_region_area_config')) && (
                  <li>
                    <NavLink to="/cleaner-region-area-config" activeClassName="is-active">
                      <span className='menu-label'>{localised.regionAndAreaConfig}   </span>
                    </NavLink >
                  </li>
                )}
              </ul>
            </Collapse>
          </li>
        )}
        {(loggedInUser?.resource_access?.cp_billing) && (

          <li className='menu-item'>
              <Link  to="#" className={open === 7 ? 'is-active': ''}>
              <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiCreditCard /></span>
              <span className='menu-label' onClick={() => toggleMenu(7)}>{localised.billing}</span>
              <span className='menu-arrow'>
                {
                  (open === 7 || sidebarCount?.rcInvoice) ? (
                    < FiChevronDown />
                  ) : (
                    <FiChevronRight />
                  )
                }
              </span>
            </Link >

            <Collapse in={(open === 7 || sidebarCount?.rcInvoice)}>
              <ul className='sub-menu'>
                {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('create_payroll')) && (
                  <li>
                    <NavLink to="/cleaner-create-payroll" activeClassName="is-active">
                      <span className='menu-label'>{localised.createPayroll}</span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_payroll')) && (
                  <li>
                    <NavLink to="/cleaner-view-payroll" activeClassName="is-active">
                      <span className='menu-label'>{localised.viewPayroll}  </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('create_invoice')) && (
                  <li>
                    <NavLink to="/cleaner-create-invoices" activeClassName="is-active">
                      <span className='menu-label'>{localised.createInvoices}  </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_invoice')) && (
                  <li>
                    <NavLink to="/cleaner-view-invoices" activeClassName="is-active">
                      <span className='menu-label'>{localised.viewInvoices}</span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_rc_invoice')) &&
                  (<li>
                    <NavLink to="/cleaner-rc-invoices" activeClassName="is-active">
                      <span className='menu-label'>{localised.rcInvoices}<span className='badge'>{sidebarCount?.rcInvoice}</span></span>
                    </NavLink >
                  </li>)
                }
                {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_stripe')) && (
                  <li>
                    <NavLink to="/cleaner-stripe-connect" activeClassName="is-active">
                      <span className='menu-label'>{localised.stripeSetup}  </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_tilled') && (sidebarCount?.paymentGtw?.tilled)) && (
                  <li>
                    <NavLink to="/cleaner-tilled-connect" activeClassName="is-active">
                      <span className='menu-label'>{localised.tilledSetUp} </span>
                    </NavLink >
                  </li>
                )}
                 {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_cardpointe')) && propelrPaymentsFeature && (
                  <li>
                    <NavLink to="/cleaner-cardpointe-connect" activeClassName="is-active">
                      <span className='menu-label'>{localised.cardpointeSetUp} </span>
                    </NavLink >
                  </li>
                )}
                {/* {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_quickbook')) && (
                <li>
                  <NavLink to="/cleaner-quickbook" activeClassName="is-active">
                    <span className='menu-label'>{localised.quickbooksSetUp}</span>
                  </NavLink >
                </li>
                 )} */}
                     {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_quickbook')) && quickbookAdditionalFeature && (

<li>
                <NavLink to="#" activeClassName={subOpen === 98 ? 'is-active' : ''}>
                  <span
                    className="menu-label"
                    onClick={() => toggleSubMenu(98)}
                  >
                    {localised.quickbooks}
                  </span>
                  <span className="menu-arrow">
                    {subOpen === 98 ? <FiChevronDown /> : <FiChevronRight />}
                  </span>
                </NavLink>
                <Collapse in={subOpen === 98}>
                  <ul className="sub-menu sub-menu-level">
                    <li>
                      <NavLink to="/cleaner-quickbook" activeClassName="is-active" isActive={(match, location) => {
                            if (match != null) {
                              return true;
                            }
                            return false;
                          }}>
                        <span className="menu-label">{localised.setup}</span>
                      </NavLink>
                    </li>
                    {qbCompanyInfo?.data?.data?.companyInfo!=null &&
                    <>
                    <li>
                      <NavLink to="/cleaner-service-mapping" activeClassName="is-active" isActive={(match, location) => {
                            if (match != null) {
                              return true;
                            }
                            return false;
                          }}>
                        <span className="menu-label">
                          {localised.serviceMapping}
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/cleaner-property-manager-mapping" activeClassName="is-active" isActive={(match, location) => {
                            if (match != null) {
                              return true;
                            }
                            return false;
                          }}>
                        <span className="menu-label">
                          {localised.propertyManagerMapping}
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/cleaner-workorder-item-mapping" activeClassName="is-active" isActive={(match, location) => {
                            if (match != null) {
                              return true;
                            }
                            return false;
                          }}>
                        <span className="menu-label">
                          {localised.workorderItemMapping}
                        </span>
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/cleaner-staff-mapping" activeClassName="is-active" isActive={(match, location) => {
                            if (match != null) {
                              return true;
                            }
                            return false;
                          }}>
                        <span className="menu-label">
                          {localised.staffMapping}
                        </span>
                      </NavLink>
                    </li>
                    </>
                    }
                  </ul>
                </Collapse>
              </li>
)}
              </ul>
            </Collapse>
          </li>
        )}
        {(loggedInUser?.resource_access?.reports) && (

          <li className='menu-item'>
            <Link to="#" onClick={() => toggleMenu(8)} className={open === 8 ? 'is-active' : ''}>
              <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiFileText /></span>
              <span className='menu-label' >{localised.reports}</span>
              <span className='menu-arrow'>
                {
                  open === 8 ? (
                    < FiChevronDown />
                  ) : (
                    <FiChevronRight />
                  )
                }
              </span>
            </Link >

            <Collapse in={open === 8}>
              <ul className='sub-menu'>
                {((loggedInUser?.resource_access?.reports?.roles?.includes('inventory_report')) && oldInventoryFeature) && (
                  <li>
                    <NavLink to="/cleaner-inventory-report" activeClassName="is-active">
                      <span className='menu-label'>{localised.inventoryReport}  </span>
                    </NavLink >
                  </li>
                )}
                {((loggedInUser?.resource_access?.reports?.roles?.includes('inventory_report')) && newInventoryFeature) && (
                  <li>
                    <NavLink to="/cleaner-new-inventory-report" activeClassName="is-active">
                      <span className='menu-label'>{localised.inventoryReport}  </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.reports?.roles?.includes('property_report') && oldInventoryFeature) && (
                  <li>
                    <NavLink to="/cleaner-property-inventory-report" activeClassName="is-active">
                      <span className='menu-label'>{localised.propertyInventoryReport}  </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.reports?.roles?.includes('property_report') && newInventoryFeature) && (
                  <li>
                    <NavLink to="/cleaner-property-new-inventory-report" activeClassName="is-active">
                      <span className='menu-label'>{localised.propertyInventoryReport}  </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.reports?.roles?.includes('pm_property_inventory_report')) && (
                  <li>
                    <NavLink to="/cleaner-property-manager-inventory-report" activeClassName="is-active">
                      <span className='menu-label'>{localised.propertyManagers + ' ' + localised.inventoryReport}  </span>
                    </NavLink >
                  </li>
                )}
                {(loggedInUser?.resource_access?.inspection) && (inspectorAdditionalFeature) && (
                  ((loggedInUser?.resource_access?.reports?.roles?.includes('failed_inspection_item_report')) && (loggedInUser.role === 'CL' || loggedInUser.role === 'ST')) && (
                  <li>
                    <NavLink to="/cleaner-failed-inspection-items-reports" activeClassName="is-active">
                      <span className='menu-label'>{localised.failedInspectionItemReport}  </span>
                    </NavLink >
                  </li>
                  )
                )}
                {(housekeeperTimeAdditionalFeature) && (
                ((loggedInUser?.resource_access?.reports?.roles?.includes('complete_time_report'))) && (
                  <li>
                    <NavLink to="/cleaner-completed-times-reports" activeClassName="is-active">
                      <span className='menu-label'>{localised.completedTimesReport}</span>
                    </NavLink >
                  </li>
                )
                )}
              {(housekeeperTimeAdditionalFeature) && (
                ((loggedInUser?.resource_access?.reports?.roles?.includes('productivity_report')) && (loggedInUser.role === 'CL' || loggedInUser.role === 'ST')) && (
                  <li>
                    <NavLink to="/cleaner-productivity-report" activeClassName="is-active">
                      <span className='menu-label'>{localised.productivityReport}</span>
                    </NavLink >
                  </li>
                )
              )}
              {(loggedInUser?.resource_access?.inspection) && (inspectorAdditionalFeature && housekeeperTimeAdditionalFeature) && (
                ((loggedInUser?.resource_access?.reports?.roles?.includes('inspector_report')) && (loggedInUser.role === 'CL' || loggedInUser.role === 'ST')) && (
                  <li>
                    <NavLink to="/cleaner-inspector-productivity-report" activeClassName="is-active">
                      <span className='menu-label'>{localised.inspectorReport}  </span>
                    </NavLink >
                  </li>
                )
              )}
              {(loggedInUser?.resource_access?.reports?.roles?.includes('timesheet_report')
                  && loggedInUser?.resource_access?.staff?.roles?.includes('manage_staff_schedule')
                  && scheduleStaffFeature && timeClockFeature
                ) && (
                <li>
                  <NavLink to="/cleaner-timesheet-report" activeClassName="is-active">
                    <span className='menu-label'>{localised.timesheetReport}  </span>
                  </NavLink >
                </li>
              )}
              {(
                <li>
                  <NavLink to="/cleaner-appointments?arvBetween=arvBetween" activeClassName="is-active">
                    <span className='menu-label'>{localised.arrivalReport}  </span>
                  </NavLink >
                </li>
              )}
              {(dailyCloseoutReportFeature && loggedInUser?.resource_access?.reports?.roles?.includes('daily_closeout_reports')) && (<li>
                <NavLink to="/cleaner-daily-closeout-report" activeClassName="is-active" isActive={(match, location)=> {
                    if (match != null) {
                      return true;
                    }
                    return false;
                  }}>
                  <span className="menu-label">
                    {localised.dailyCloseoutReport}
                  </span>
                </NavLink>
              </li>)}
              {(loggedInUser?.resource_access?.reports?.roles?.includes('jobs_and_revenue_report')) && (
                <li>
                    <NavLink to="/cleaner-jobs-and-revenue-report" activeClassName="is-active">
                      <span className='menu-label'>{localised.jobsandRevenueReport}</span>
                    </NavLink >
                  </li>
               )}
              {(loggedInUser?.resource_access?.cp_billing?.roles?.includes('manage_payroll')) && (
                <li>
                    <NavLink to="/cleaner-payroll-report" activeClassName="is-active">
                      <span className='menu-label'>{localised.payrollReport}</span>
                    </NavLink >
                  </li>
              )}
              {(hasHHSFeaturesAccess && loggedInUser?.resource_access?.reports?.roles?.includes('hhs_weekly_invoice_report')) && <li>
                <NavLink to="/cleaner-hhs-weekly-invoice-report" activeClassName="is-active" isActive={(match, location)=> {
                    if (match != null) {
                      return true;
                    }
                    return false;
                  }}>
                  <span className="menu-label">{localised.hhsWeeklyInvoiceReport}</span>
                </NavLink>
              </li>}
              {(hasHHSFeaturesAccess && loggedInUser?.resource_access?.reports?.roles?.includes('hhs_weekly_invoice_report')) && <li>
                <NavLink to="/cleaner-hhs-weekly-invoice-new-report" activeClassName="is-active" isActive={(match, location)=> {
                    if (match != null) {
                      return true;
                    }
                    return false;
                  }}>
                  <span className="menu-label">{localised.hhsWeeklyInvoiceNewReport}</span>
                </NavLink>
              </li>}
              {
                ((hasHHSFeaturesAccess) && loggedInUser?.resource_access?.reports?.roles?.includes('hhs_service_tracker_report')) && <li>
                  <NavLink to="/cleaner-hhs-service-tracker-report" activeClassName="is-active" isActive={(match, location)=> {
                    if (match != null) {
                      return true;
                    }
                    return false;
                  }}>
                    <span className="menu-label">
                      {localised.hhsServiceTrackerReport}
                    </span>
                  </NavLink>
                </li>
              }
              {
                (hasHHSFeaturesAccess && loggedInUser?.resource_access?.reports?.roles?.includes('hhs_employee_piece_report')) && <li>
                  <NavLink to="/cleaner-hhs-employee-piece-report" activeClassName="is-active" isActive={(match, location)=> {
                    if (match != null) {
                      return true;
                    }
                    return false;
                  }}>
                    <span className="menu-label">
                      {localised.hhsEmployeePieceReport}
                    </span>
                  </NavLink>
                </li>
              }
              {
                (hasHHSFeaturesAccess && loggedInUser?.resource_access?.reports?.roles?.includes('hhs_employee_piece_rate_report')) && <li>
                  <NavLink to="/cleaner-hhs-employee-piece-rate-report" activeClassName="is-active" isActive={(match, location)=> {
                    if (match != null) {
                      return true;
                    }
                    return false;
                  }}>
                    <span className="menu-label">
                      {localised.hhsEmployeePieceRateReport}
                    </span>
                  </NavLink>
                </li>
              }
              </ul>
            </Collapse>
          </li>
        )}
        {(loggedInUser?.resource_access?.setup) && (
          <li className='menu-item'>
            <Link to="#" className={open === 10 ? 'is-active' : ''}>
              <span className='menu-icon'  onMouseEnter={setHoveredMenuIcon}><FiTool /></span>
              <span className='menu-label' onClick={() => toggleMenu(10)}>{localised.setup}</span>
              <span className='menu-arrow'>
                {
                  open === 10 ? (
                    < FiChevronDown />
                  ) : (
                    <FiChevronRight />
                  )
                }
              </span>
            </Link >
            <Collapse in={open === 10}>
              <ul className='sub-menu'>
                {(loggedInUser?.resource_access?.setup?.roles?.includes('manage_properties')) && (
                  <li>
                    <NavLink to="#" activeClassName={subOpen === 5 ? 'is-active' : ''}>
                      <span className='menu-label' onClick={() => toggleSubMenu(5)}>{localised.properties}</span>
                      <span className='menu-arrow'>{
                        subOpen === 5 ? (
                          <FiChevronDown/>
                        ) : (
                          <FiChevronRight/>
                        )
                      }
                      </span>
                    </NavLink>
                    <Collapse in={subOpen === 5}>
                      <ul className='sub-menu sub-menu-level'>
                        <li>
                          <NavLink to="/cleaner-region" activeClassName="is-active" isActive={(match, location) => {
                            if (match != null) {
                              return true;
                            }
                            return false;
                          }}>
                            <span className='menu-label'>{localised.regions}</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/cleaner-area" activeClassName="is-active" isActive={(match, location) => {
                            if (match != null) {
                              return true;
                            }
                            return false;
                          }}>
                            <span className='menu-label'>{localised.areas} </span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/cleaner-service-config" className={subChildOpen === 3 ? 'is-active' : ''}>
                            <span className='menu-label'
                                  onClick={() => toggleSubChildMenu(3)}>{localised.propertyConfig}  </span>
                          </NavLink>
                        </li>
                        {appointmentMessagingAdditionalFeature &&
                          <li>
                            <NavLink to="/cleaner-property-messaging" activeClassName="is-active">
                              <span className='menu-label'>Property Messaging</span>
                            </NavLink>
                          </li>
                        }
                      </ul>
                    </Collapse>
                  </li>
                )}

                { loggedInUser?.resource_access?.setup?.roles?.includes('manage_appointment_messaging') && appointmentMessagingAdditionalFeature && 
                  <li>
                    <NavLink to="/cleaner-appointment-messaging" activeClassName="is-active">
                      <span className='menu-label' onClick={() => toggleSubChildMenu(7)}>{localised.appointmentMessaging}</span>
                    </NavLink>
                  </li>
                }

                {
                  (loggedInUser?.resource_access?.setup?.roles?.includes('manage_inspections')) && inspectorAdditionalFeature && (
                    <li className='menu-item'>
                      <NavLink to="/cleaner-inspection-template" activeClassName="is-active"
                               isActive={(match, location) => {
                                 return match != null;
                               }}>

                        <span className='menu-label'>{localised.inspectionsTemplates}</span>
                      </NavLink>
                    </li>
                  )
                }


                {/* {(loggedInUser?.resource_access?.setup?.roles?.includes('manage_inspections')) && inspectorAdditionalFeature && (
                  <li>
                    <NavLink to="#">
                      <span className='menu-label' onClick={() => toggleSubMenu(1)}>{localised.inspections}</span>
                      <span className='menu-arrow'>
                        {
                          subOpen === 1 ? (
                            < FiChevronDown />
                          ) : (
                            <FiChevronRight />
                          )
                        }
                      </span>
                    </NavLink >
                    <Collapse in={subOpen === 1}>
                      <ul className='sub-menu sub-menu-level'>
                        <li>
                          <NavLink to="/cleaner-inspection-template" activeClassName="is-active" isActive={(match, location) => {
                            return match != null;
                          }}>
                            <span className='menu-label'>{localised.inspectionsTemplates}</span>
                          </NavLink >
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )} */}

                {(loggedInUser?.resource_access?.setup?.roles?.includes('manage_integrations')) && <li>
                  <NavLink to="#" onClick={() => toggleSubMenu(15)}>
                    <span className='menu-label'>{localised.integrations}</span>
                    <span className='menu-arrow'>
                      {
                        subOpen === 15 ? (
                          < FiChevronDown/>
                        ) : (
                          <FiChevronRight/>
                        )
                      }
                    </span>
                  </NavLink>
                  <Collapse in={subOpen === 15}>
                    <ul className='sub-menu sub-menu-level'>


                      <li>
                        <NavLink to="/cleaner-ical-aribnb" activeClassName="is-active" isActive={(match, location) => {
                          if (match != null) {
                            return true;
                          }
                          return false;
                        }}>
                          <span className='menu-label'>AirBnb Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-ical-avhr" activeClassName="is-active" isActive={(match, location) => {
                          if (match != null) {
                            return true;
                          }
                          return false;
                        }}>
                          <span className='menu-label'> Alabama VHR Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-alluradirect" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'> AlluraDirect Ical</span>
                        </NavLink>
                      </li>
                      {sidebarCount?.old_rc_id && sidebarCount?.old_rc_id == 13074 &&
                        <li>
                          <NavLink to="/cleaner-ical-avantio" activeClassName="is-active"
                                   isActive={(match, location) => {
                                     if (match != null) {
                                       return true;
                                     }
                                     return false;
                                   }}>
                            <span className='menu-label'>Avantio Ical</span>
                          </NavLink>
                        </li>}


                      <li>
                        <NavLink to="/cleaner-alt-ical-beds24" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>beds24 Ical</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/cleaner-alt-ical-bookingcom" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Booking.com Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-ical-bookingsync" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>BookingSync Ical</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/cleaner-alt-ical-cirrus" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Cirrus Ical</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/cleaner-ical-directpms" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Direct PMS Ical</span>
                        </NavLink>
                      </li>

                      {sidebarCount?.evolve_url && <li>
                        <NavLink to="/cleaner-evolve" activeClassName="is-active" isActive={(match, location) => {
                          if (match != null) {
                            return true;
                          }
                          return false;
                        }}>
                          <span className='menu-label'>Evolve</span>
                        </NavLink>
                      </li>}
                      <li>
                        <NavLink to="/cleaner-alt-ical-guesty" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Guesty Ical</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/cleaner-alt-ical-yourporter" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Guesty for Hosts Ical</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/cleaner-alt-ical-hostaway" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Hostaway Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-hospitable" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Hospitable Ical</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/cleaner-alt-ical-hostfully" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Hostfully Ical</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/cleaner-alt-ical-hostify" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Hostify Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-houfy" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Houfy Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-igms" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>IGMS Ical</span>
                        </NavLink>
                      </li>

                      {inventorySmartAdditionalFeature && <li>
                        <NavLink to="/cleaner-inventory-smart-form-page" activeClassName="is-active">
                          <span className='menu-label'>InventorySmart</span>
                        </NavLink>
                      </li>}

                      {sidebarCount?.is_itrip_workorder && <li>
                        <NavLink to="/cleaner-itrip-workorder" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>{localised.itripWorkorder}</span>
                        </NavLink>
                      </li>}

                      <li>
                        <NavLink to="/cleaner-alt-ical-ivacation" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Ivacation Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-janiis" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Janiis Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-kigo" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Kigo Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-livrez" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Liverez Ical</span>
                        </NavLink>
                      </li>


                      <li>
                        <NavLink to="/cleaner-alt-ical-lodgify" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Lodgify Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-lodgix" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Lodgix Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-myvr" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>MyVR Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-onerooftop" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>OneRooftop Ical</span>
                        </NavLink>
                      </li>


                      <li>
                        <NavLink to="/cleaner-alt-ical-pmbo" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>PMBO Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-rezkey" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Rezkey Ical</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/cleaner-alt-ical-skyRun" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>SkyRun Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-smartBnb" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>SmartBnb Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-smoobu" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Smoobu Ical</span>
                        </NavLink>
                      </li>

                      {(sojoFeature) && (loggedInUser.role === 'CL' || loggedInUser.role === 'ST') && (
                        <li>
                          <NavLink to="/cleaner-sojo-integration" activeClassName="is-active"
                                   isActive={(match, location) => {
                                     if (match != null) {
                                       return true;
                                     }
                                     return false;
                                   }}>
                            <span className='menu-label'>Sojo</span>
                          </NavLink>
                        </li>)}

                      <li>
                        <NavLink to="/cleaner-alt-ical-streamline" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Streamline Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-supercontrol" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>SuperControl Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-tokeet" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Tokeet Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-track" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Track Ical</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/cleaner-alt-ical-tripadvisor" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Trip Advisor Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-uplisting" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Uplisting Ical</span>
                        </NavLink>
                      </li>

                      {sidebarCount?.is_vacasa && <li>
                        <NavLink to="/cleaner-vacasa" activeClassName="is-active" isActive={(match, location) => {
                          if (match != null) {
                            return true;
                          }
                          return false;
                        }}>
                          <span className='menu-label'>Vacasa</span>
                        </NavLink>
                      </li>}

                      <li>
                        <NavLink to="/cleaner-alt-ical-vacationrentaldesk" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>Vacation Rental Desk Ical</span>
                        </NavLink>
                      </li>

                      {/*  <li>
                        <NavLink to="/cleaner-alt-ical-v12" activeClassName="is-active" isActive={(match, location)=> {
                          if (match != null) {
                          return true;
                          }
                          return false;
                          }}>
                          <span className='menu-label'>V12 Ical</span>
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/cleaner-alt-ical-365villas" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>365Villas Ical</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cleaner-alt-ical-vrbo" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>VRBO Ical</span>
                        </NavLink>
                      </li>

                      {/* <li>
                        <NavLink to="/cleaner-alt-ical-webrezpro" activeClassName="is-active" isActive={(match, location)=> {
                          if (match != null) {
                          return true;
                          }
                          return false;
                          }}>
                          <span className='menu-label'>WebRez Pro Ical</span>
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/cleaner-alt-ical-wphotel" activeClassName="is-active"
                                 isActive={(match, location) => {
                                   if (match != null) {
                                     return true;
                                   }
                                   return false;
                                 }}>
                          <span className='menu-label'>WP Hotel Booking Ical</span>
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>}


                {
                  (loggedInUser?.resource_access?.setup?.roles?.includes('manage_inventory') && oldInventoryFeature) && (
                    <li className='menu-item'>
                      <NavLink to="/cleaner-inventory" activeClassName="is-active" isActive={(match, location) => {
                        if (match != null) {
                          return true;
                        }
                        return false;

                      }}>

                        <span className='menu-label'>{localised.inventory}</span>
                      </NavLink>
                    </li>
                  )
                }

                {
                  (loggedInUser?.resource_access?.setup?.roles?.includes('manage_inventory') && newInventoryFeature) && (
                    <li className='menu-item'>
                      <NavLink to="/cleaner-inventory-template" activeClassName="is-active"
                               isActive={(match, location) => {
                                 if (match != null) {
                                   return true;
                                 }
                                 return false;

                               }}>

                        <span className='menu-label'>{localised.inventoryTemplate}</span>
                      </NavLink>
                    </li>
                  )
                }


                {((loggedInUser?.resource_access?.setup?.roles?.includes('manage_service_checklist')) && (serviceChecklistAdditionalFeature)) && (
                  <li className='menu-item'>
                    <NavLink to="/cleaner-service-checklist" activeClassName="is-active">

                      <span className='menu-label'>{localised.serviceChecklist}</span>
                    </NavLink>
                  </li>
                )
                }


                {/* {((loggedInUser?.resource_access?.setup?.roles?.includes('manage_service_checklist')) && (serviceChecklistAdditionalFeature)) && (

                  <li>
                    <NavLink to="#" onClick={() => toggleSubMenu(3)}>
                      <span className='menu-label'>{localised.serviceChecklist}</span>
                      <span className='menu-arrow'>
                        {
                          subOpen === 3 ? (
                            < FiChevronDown />
                          ) : (
                            <FiChevronRight />
                          )
                        }
                      </span>
                    </NavLink >
                    <Collapse in={subOpen === 3}>
                      <ul className='sub-menu sub-menu-level'>
                        <li>
                          <NavLink to="/cleaner-service-checklist" activeClassName={subOpen === 3 ? 'is-active' : ''}>
                            <span className='menu-label' onClick={() => toggleSubMenu(3)}>{localised.templates}</span>
                            <span className='menu-arrow'>
                              {
                                subOpen === 4 ? (
                                  < FiChevronDown />
                                ) : (
                                  <FiChevronRight />
                                )
                              }
                            </span>
                          </NavLink >
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                )} */}


                {(loggedInUser?.resource_access?.setup?.roles?.includes('manage_services')) && (
                  <li>
                    <NavLink to="#" activeClassName={subOpen === 4 ? 'is-active' : ''}>
                      <span className='menu-label' onClick={() => toggleSubMenu(4)}>{localised.services}</span>
                      <span className='menu-arrow'>
                        {
                          subOpen === 4 ? (
                            < FiChevronDown/>
                          ) : (
                            <FiChevronRight/>
                          )
                        }
                      </span>
                    </NavLink>
                    <Collapse in={subOpen === 4}>
                      <ul className='sub-menu sub-menu-level'>
                        {(loggedInUser?.resource_access?.setup?.roles?.includes('manage_services')) && (
                          <li>
                            <NavLink to="/services" activeClassName="is-active" isActive={(match, location) => {
                              if (match != null) {
                                return true;
                              }
                              return false;
                            }}>
                              <span className='menu-label'>{localised.view}</span>
                            </NavLink>
                          </li>
                        )}
                        {(loggedInUser?.resource_access?.setup?.roles?.includes('create_services')) && (
                          <></>
                        )}
                        {serviceAutomationOnOff &&
                          <li>
                            <NavLink to="/services-automation">
                              <span className='menu-label'> {localised.viewAutomatedServices}</span>
                            </NavLink>
                          </li>
                        }
                      </ul>
                    </Collapse>
                  </li>
                )}

                {
                  (loggedInUser?.resource_access?.setup?.roles?.includes('manage_workorder')) && (workorderAdditionalFeature) && (
                    <li className='menu-item'>
                      <NavLink to="/cleaner-work-order-items" activeClassName="is-active" isActive={(match, location) => {
                        if (match != null) {
                          return true;
                        }
                        return false;

                      }}>

                        <span className='menu-label'>{localised.workOrderItems}</span>
                      </NavLink>
                    </li>
                  )
                }


                {
                  (loggedInUser?.resource_access?.setup?.roles?.includes('manage_support_tasks')) && supportTaskAdditionalFeature && (
                    <li className='menu-item'>
                      <NavLink to="/cleaner-support-tasks" activeClassName="is-active" isActive={(match, location) => {
                        if (match != null) {
                          return true;
                        }
                        return false;

                      }}>

                        <span className='menu-label'>{localised.supportTasks}</span>
                      </NavLink>
                    </li>
                  )
                }

                {
                  (loggedInUser?.resource_access?.setup?.roles?.includes('manage_workorder_department') && workorderAdditionalFeature && (sidebarCount?.isWorkOrderDepartment) && (isUserActive?.status_id == 1)) && (
                    <li className='menu-item'>
                      <NavLink to="/cleaner-workorder-department" activeClassName="is-active"
                               isActive={(match, location) => {
                                 if (match != null) {
                                   return true;
                                 }
                                 return false;

                               }}>

                        <span className='menu-label'>{localised.workorderDeparment}</span>
                      </NavLink>
                    </li>
                  )
                }

              </ul>
            </Collapse>
          </li>
        )}
      </ul>}
    </div>
  );
};
export default CleanerSidebar;
