import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";

import Breadcrumbs from "../../../atoms/Breadcrumbs";
import { LanguageContext } from "../../../containers/Language";
import Search from "../../../molecules/Cleaner/JobsAndRevenue/Search";
import { Line } from "react-chartjs-2";
import "./style.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import { isArray } from "../../../../utils/utils";
import apiService from "../../../../redux/apiService";
import { microServices } from "../../../../config/global";
import { toast } from "react-toastify";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const JobsAndRevenue = () => {
  const { localised } = useContext(LanguageContext);
  const loggedInUser = useSelector((state) => state.authentication.profile);
  const currency = loggedInUser?.currency;
  const [isCompare, setISCompare] = useState(false);
  const [countChart, setCountChart] = useState(null);
  const [revenueChart, setRevenueChart] = useState(null);
  const [timeChart, setTimeChart] = useState(null);
  const currencySymbol = loggedInUser?.currency?.symbol || "$";
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [compateMonthYr, setCompateMonthYr] = useState([]);
  const [isCompared, setIsCompared] = useState(false);
  const [netChartData, setNetChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [filters, setFilters] = useState({
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().endOf("month").format("YYYY-MM-DD"),
    groupBy: "week",
  });

  const filtersRef = useRef(filters);
  const tableRef = useRef(null);

  const prepareMonthYear = (month, year) => {
    const date = new Date(year, month - 1);
    const monthAbbreviation = date.toLocaleString('default', { month: 'short' });

    const yearFourDigits = year.toString();

    return `\u200B${monthAbbreviation} ${yearFourDigits}`;
  };

  async function getJobAndRevenueData(currentFilters) {
    filtersRef.current = currentFilters;
    let endpoint = `${microServices.base}/v1/reports/jobs-and-revenue`;
    try {
      if (!currentFilters.is_compare) {
        const jobsAndRevenueData = await apiService.post(endpoint, currentFilters);
        const { tableData } = prepareTableData(jobsAndRevenueData, localised);
        setTableData(tableData);
        const netChart = prepareNetChartData(jobsAndRevenueData);
        setNetChartData(netChart);
      } else {
        const prepareFromDate = (month, year) => `${year}-${String(month).padStart(2, '0')}-01`;
        const prepareToDate = (month, year) => {
          const lastDay = new Date(year, month, 0).getDate();
          return `${year}-${String(month).padStart(2, '0')}-${lastDay}`;
        };

        const compareMonthYear1 = prepareMonthYear(currentFilters.month1, currentFilters.year1);
        const compareMonthYear2 = prepareMonthYear(currentFilters.month2, currentFilters.year2);
        setCompateMonthYr({compareMonthYear1, compareMonthYear2});
        const area_id = currentFilters?.area_id ;
        const region_id = currentFilters?.region_id ; 
        const fromDate = prepareFromDate(currentFilters.month1, currentFilters.year1);
        const toDate = prepareToDate(currentFilters.month1, currentFilters.year1);
        const filters1 = { fromDate, toDate, groupBy: "week",region_id : region_id, area_id : area_id };
        const jobsAndRevenueData1 = await apiService.post(endpoint, filters1);
        const fromDate2 = prepareFromDate(currentFilters.month2, currentFilters.year2);
        const toDate2 = prepareToDate(currentFilters.month2, currentFilters.year2);
        const filters2 = { fromDate: fromDate2, toDate: toDate2, groupBy: "week",region_id : region_id, area_id : area_id  };
        const jobsAndRevenueData2 = await apiService.post(endpoint, filters2);
        const { tableData } = prepareTableData2(jobsAndRevenueData1, jobsAndRevenueData2, localised);
        setTableData(tableData);
        const netChartCompare = prepareCompareNetChartData(jobsAndRevenueData1, jobsAndRevenueData2, localised, currencySymbol, currentFilters);
        setNetChartData(netChartCompare);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    getJobAndRevenueData(filtersRef.current);
  }, [localised]);

  const formatNumber = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const formatForExport = (value) => {
    if (typeof value === "string" && value.startsWith(`-${currencySymbol}`)) {
      return `\u200B${value}`;
    } else if (typeof value === "number" && value < 0) {
      return `\u200B${currencySymbol}${Math.abs(value)}`;
    }
    return value;
  };  

  const prepareTableData = ( jobsAndRevenueData, localised) => {
    const headerData = jobsAndRevenueData.data.Header.data;
    const type = jobsAndRevenueData.data.Header.type;
    const weekKeys = Object.keys(headerData);
  
    const rowWeek = [type=="week" ? localised.week: localised.quarter];
    const rowDates = [localised.dates];
    const rowNumberOfServices = [localised.numberOfServices];
    const rowNumberOfReportedIssues = [localised.numberOfReportedIssues];
    const rowNewServicesScheduled = [localised.newServicesScheduled];
    const rowNumberOfWorkorders = [localised.numberOfWorkorders];
    const rowNewWorkordersScheduled = [localised.newWorkordersScheduled];
    const rowTotalServicesPerformed = [localised.totalServicesPerformed];
    const rowServiceRevenue = [localised.serviceRevenue];
    const rowServiceHousekeeperExpense = [localised.serviceHousekeeperExpense];
    const rowServiceAttendantExpense = [localised.serviceHouseAttendantExpense];
    const rowServiceInspectorExpense = [localised.serviceInspectorExpense];
    const rowTotalServiceExpense = [localised.totalServiceExpense];
    const rowServiceNet = [localised.serviceNet];
    const rowServiceMargin = [localised.serviceMargin];
    const rowWorkordersRevenue = [localised.workordersRevenue];
    const rowWorkorderContractorExpense = [localised.workorderContractorExpense];
    const rowWorkorderNet = [localised.workorderNet];
    const rowWorkorderMargin = [localised.workorderMargin];
    const rowNet = [localised.net];
    const rowTotalMargin = [localised.totalMargin];
  
    weekKeys.forEach((wk) => {
      const sequence = headerData[wk]?.Sequence || "";
      const label = headerData[wk]?.Label || "";
  
      rowWeek.push(sequence);
      rowDates.push(label);

      rowNumberOfServices.push(formatNumber(jobsAndRevenueData.data.no_of_service?.[wk] ?? "0"));
      rowNumberOfReportedIssues.push(formatNumber(jobsAndRevenueData.data.no_of_reported_issues?.[wk] ?? "0"));
      rowNewServicesScheduled.push(formatNumber(jobsAndRevenueData.data.new_service_scheduled?.[wk] ?? "0"));
      rowNumberOfWorkorders.push(formatNumber(jobsAndRevenueData.data.no_of_workorders?.[wk] ?? "0"));
      rowNewWorkordersScheduled.push(formatNumber(jobsAndRevenueData.data.new_wo_scheduled?.[wk] ?? "0"));
      rowTotalServicesPerformed.push(formatNumber(jobsAndRevenueData.data.total_service_performed?.[wk] ?? "0"));
      rowServiceRevenue.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.service_revenue?.[wk] ?? "0").toFixed(2))}`);
      rowServiceHousekeeperExpense.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.service_hk_expenses?.[wk] ?? "0").toFixed(2))}`);
      rowServiceAttendantExpense.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.service_ha_expenses?.[wk] ?? "0").toFixed(2))}`);
      rowServiceInspectorExpense.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.service_insp_expenses?.[wk] ?? "0").toFixed(2))}`);
      rowTotalServiceExpense.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.total_service_expenses?.[wk] ?? "0").toFixed(2))}`);
      rowServiceNet.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.service_net?.[wk] ?? "0").toFixed(2))}`);
      rowServiceMargin.push(`${formatNumber(parseFloat(jobsAndRevenueData.data.service_margin?.[wk] ?? "0").toFixed(2))}%`);
      rowWorkordersRevenue.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.wo_revenue?.[wk] ?? "0").toFixed(2))}`);
      rowWorkorderContractorExpense.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.wo_contract_expenses?.[wk] ?? "0").toFixed(2))}`);
      rowWorkorderNet.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.workorder_net?.[wk] ?? "0").toFixed(2))}`);
      rowWorkorderMargin.push(`${formatNumber(parseFloat(jobsAndRevenueData.data.wo_service_margin?.[wk] ?? "0").toFixed(2))}%`);
      rowNet.push(`${currencySymbol}${formatNumber(parseFloat(jobsAndRevenueData.data.net?.[wk] ?? "0").toFixed(2))}`);
      rowTotalMargin.push(`${formatNumber(parseFloat(jobsAndRevenueData.data.total_margin?.[wk] ?? "0").toFixed(2))}%`);
    });
  
    const tableData = [
      rowWeek,
      rowDates,
      rowNumberOfServices,
      rowNumberOfReportedIssues,
      rowNewServicesScheduled,
      rowNumberOfWorkorders,
      rowNewWorkordersScheduled,
      rowTotalServicesPerformed,
      rowServiceRevenue,
      rowServiceHousekeeperExpense,
      rowServiceAttendantExpense,
      rowServiceInspectorExpense,
      rowTotalServiceExpense,
      rowServiceNet,
      rowServiceMargin,
      rowWorkordersRevenue,
      rowWorkorderContractorExpense,
      rowWorkorderNet,
      rowWorkorderMargin,
      rowNet,
      rowTotalMargin,
    ];
  
    return { tableData };
  };

  const prepareTableData2 = (compareData1, compareData2, localised) => {
    const headerDataCurrent = compareData1.data.Header.data;
    const headerDataPrevious = compareData2.data.Header.data;

    const data1 = compareData1.data;
    const data2 = compareData2.data;

    const rowWeek = [localised.week];
    const rowDates = [localised.dates];
    const rowNoOfServices = [localised.numberOfServices];
    const rowReportedIssues = [localised.reportedIssues];
    const rowNewServicesScheduled = [localised.newServicesScheduled];
    const rowNoOfWorkorders = [localised.numberOfWorkorders];
    const rowNewWorkordersScheduled = [localised.newWorkordersScheduled];
    const rowTotalServicesPerformed = [localised.totalServicesPerformed];
    const rowServiceRevenue = [localised.serviceRevenue];
    const rowServiceHousekeeperExpense = [localised.serviceHousekeeperExpense];
    const rowServiceInspectorExpense = [localised.serviceInspectorExpense];
    const rowServiceAttendantExpense = [localised.serviceHouseAttendantExpense];
    const rowTotalServiceExpense = [localised.totalServiceExpense];
    const rowServiceNet = [localised.serviceNet];
    const rowServiceMargin = [localised.serviceMargin];
    const rowWorkordersRevenue = [localised.workordersRevenue];
    const rowWorkorderContractorExpense = [localised.workorderContractorExpense];
    const rowWorkorderNet = [localised.workorderNet];
    const rowWorkorderMargin = [localised.workorderMargin];
    const rowNet = [localised.net];
    const rowTotalMargin = [localised.totalMargin];

    const currentKeys = Object.keys(headerDataCurrent);
    const previousKeys = Object.keys(headerDataPrevious);

    const maxRows = Math.max(currentKeys.length, previousKeys.length);

    for (let i = 0; i < maxRows; i++) {
        const currentKey = currentKeys[i];
        const previousKey = previousKeys[i];

        const currentWeek = currentKey ? headerDataCurrent[currentKey]?.Sequence || "" : "";
        const previousWeek = previousKey ? headerDataPrevious[previousKey]?.Sequence || "" : "";
        rowWeek.push([currentWeek, previousWeek]);

        const currentDates = currentKey ? headerDataCurrent[currentKey]?.Label || "" : "";
        const previousDates = previousKey ? headerDataPrevious[previousKey]?.Label || "" : "";
        rowDates.push([currentDates, previousDates]);

        const currentServices = currentKey ? data1.no_of_service?.[currentKey] || 0 : 0;
        const previousServices = previousKey ? data2.no_of_service?.[previousKey] || 0 : 0;
        rowNoOfServices.push([formatNumber(currentServices), formatNumber(previousServices)]);

        const currentIssues = currentKey ? data1.no_of_reported_issues?.[currentKey] || 0 : 0;
        const previousIssues = previousKey ? data2.no_of_reported_issues?.[previousKey] || 0 : 0;
        rowReportedIssues.push([formatNumber(currentIssues), formatNumber(previousIssues)]);

        const currentScheduled = currentKey ? data1.new_service_scheduled?.[currentKey] || 0 : 0;
        const previousScheduled = previousKey ? data2.new_service_scheduled?.[previousKey] || 0 : 0;
        rowNewServicesScheduled.push([formatNumber(currentScheduled), formatNumber(previousScheduled)]);

        const currentWorkorders = currentKey ? data1.no_of_workorders?.[currentKey] || 0 : 0;
        const previousWorkorders = previousKey ? data2.no_of_workorders?.[previousKey] || 0 : 0;
        rowNoOfWorkorders.push([formatNumber(currentWorkorders), formatNumber(previousWorkorders)]);

        const currentNewWorkorders = currentKey ? data1.new_wo_scheduled?.[currentKey] || 0 : 0;
        const previousNewWorkorders = previousKey ? data2.new_wo_scheduled?.[previousKey] || 0 : 0;
        rowNewWorkordersScheduled.push([formatNumber(currentNewWorkorders), formatNumber(previousNewWorkorders)]);

        const currentPerformed = currentKey ? data1.total_service_performed?.[currentKey] || 0 : 0;
        const previousPerformed = previousKey ? data2.total_service_performed?.[previousKey] || 0 : 0;
        rowTotalServicesPerformed.push([formatNumber(currentPerformed), formatNumber(previousPerformed)]);

        const currentRevenue = currentKey ? data1.service_revenue?.[currentKey] || 0 : 0;
        const previousRevenue = previousKey ? data2.service_revenue?.[previousKey] || 0 : 0;
        rowServiceRevenue.push([`${currencySymbol}${formatNumber(parseFloat(currentRevenue).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousRevenue).toFixed(2))}`]);

        const currentHKExpense = currentKey ? data1.service_hk_expenses?.[currentKey] || 0 : 0;
        const previousHKExpense = previousKey ? data2.service_hk_expenses?.[previousKey] || 0 : 0;
        rowServiceHousekeeperExpense.push([`${currencySymbol}${formatNumber(parseFloat(currentHKExpense).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousHKExpense).toFixed(2))}`]);

        const currentInspExpense = currentKey ? data1.service_insp_expenses?.[currentKey] || 0 : 0;
        const previousInspExpense = previousKey ? data2.service_insp_expenses?.[previousKey] || 0 : 0;
        rowServiceInspectorExpense.push([`${currencySymbol}${formatNumber(parseFloat(currentInspExpense).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousInspExpense).toFixed(2))}`]);

        const currentAttendantExpense = currentKey ? data1.service_ha_expenses?.[currentKey] || 0 : 0;
        const previousAttendantExpense = previousKey ? data2.service_ha_expenses?.[previousKey] || 0 : 0;
        rowServiceAttendantExpense.push([`${currencySymbol}${formatNumber(parseFloat(currentAttendantExpense).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousAttendantExpense).toFixed(2))}`]);

        const currentTotalExpense = currentKey ? data1.total_service_expenses?.[currentKey] || 0 : 0;
        const previousTotalExpense = previousKey ? data2.total_service_expenses?.[previousKey] || 0 : 0;
        rowTotalServiceExpense.push([`${currencySymbol}${formatNumber(parseFloat(currentTotalExpense).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousTotalExpense).toFixed(2))}`]);

        const currentNetValue = currentKey ? data1.service_net?.[currentKey] || 0 : 0;
        const previousNetValue = previousKey ? data2.service_net?.[previousKey] || 0 : 0;
        rowServiceNet.push([`${currencySymbol}${formatNumber(parseFloat(currentNetValue).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousNetValue).toFixed(2))}`]);

        const currentMargin = currentKey ? data1.service_margin?.[currentKey] || 0 : 0;
        const previousMargin = previousKey ? data2.service_margin?.[previousKey] || 0 : 0;
        rowServiceMargin.push([`${formatNumber(parseFloat(currentMargin).toFixed(2))}%`, `${formatNumber(parseFloat(previousMargin).toFixed(2))}%`]);

        const currentWORevenue = currentKey ? data1.wo_revenue?.[currentKey] || 0 : 0;
        const previousWORevenue = previousKey ? data2.wo_revenue?.[previousKey] || 0 : 0;
        rowWorkordersRevenue.push([`${currencySymbol}${formatNumber(parseFloat(currentWORevenue).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousWORevenue).toFixed(2))}`]);

        const currentWOContractorExpense = currentKey ? data1.wo_contract_expenses?.[currentKey] || 0 : 0;
        const previousWOContractorExpense = previousKey ? data2.wo_contract_expenses?.[previousKey] || 0 : 0;
        rowWorkorderContractorExpense.push([`${currencySymbol}${formatNumber(parseFloat(currentWOContractorExpense).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousWOContractorExpense).toFixed(2))}`]);

        const currentWONet = currentKey ? data1.workorder_net?.[currentKey] || 0 : 0;
        const previousWONet = previousKey ? data2.workorder_net?.[previousKey] || 0 : 0;
        rowWorkorderNet.push([`${currencySymbol}${formatNumber(parseFloat(currentWONet).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousWONet).toFixed(2))}`]);

        const currentWOMargin = currentKey ? data1.wo_service_margin?.[currentKey] || 0 : 0;
        const previousWOMargin = previousKey ? data2.wo_service_margin?.[previousKey] || 0 : 0;
        rowWorkorderMargin.push([`${formatNumber(parseFloat(currentWOMargin).toFixed(2))}%`, `${formatNumber(parseFloat(previousWOMargin).toFixed(2))}%`]);

        const currentNetTotal = currentKey ? data1.net?.[currentKey] || 0 : 0;
        const previousNetTotal = previousKey ? data2.net?.[previousKey] || 0 : 0;
        rowNet.push([`${currencySymbol}${formatNumber(parseFloat(currentNetTotal).toFixed(2))}`, `${currencySymbol}${formatNumber(parseFloat(previousNetTotal).toFixed(2))}`]);

        const currentTotalMargin = currentKey ? data1.total_margin?.[currentKey] || 0 : 0;
        const previousTotalMargin = previousKey ? data2.total_margin?.[previousKey] || 0 : 0;
        rowTotalMargin.push([`${formatNumber(parseFloat(currentTotalMargin).toFixed(2))}%`, `${formatNumber(parseFloat(previousTotalMargin).toFixed(2))}%`]);
    }

    const tableData = [
        rowWeek,
        rowDates,
        rowNoOfServices,
        rowReportedIssues,
        rowNewServicesScheduled,
        rowNoOfWorkorders,
        rowNewWorkordersScheduled,
        rowTotalServicesPerformed,
        rowServiceRevenue,
        rowServiceHousekeeperExpense,
        rowServiceInspectorExpense,
        rowServiceAttendantExpense,
        rowTotalServiceExpense,
        rowServiceNet,
        rowServiceMargin,
        rowWorkordersRevenue,
        rowWorkorderContractorExpense,
        rowWorkorderNet,
        rowWorkorderMargin,
        rowNet,
        rowTotalMargin,
    ];

    return { tableData };
};

  const prepareNetChartData = (jobsAndRevenueData) => {
    const headerData = jobsAndRevenueData.data.Header.data || {};
    const netObj = jobsAndRevenueData.data.net || {};
    
    const labels = [];
    const netValues = [];
    
    const weekKeys = Object.keys(headerData);

    weekKeys.forEach((wk) => {
      const sequenceLabel = headerData[wk]?.Label || wk; 
      labels.push(sequenceLabel);
      const numericNet = parseFloat(netObj[wk] ?? 0);
      netValues.push(numericNet);
    });

    return {
      labels,
      datasets: [
        {
          label: `${localised.net} (${currencySymbol})`,
          data: netValues,
          fill: false,
          borderColor: "#2a72b5",
          tension: 0.1,
        },
      ],
    };
  };

  const prepareCompareNetChartData = (
    compareData1,
    compareData2,
    localised,
    currencySymbol,
    filters
  ) => {
    const headerData1 = compareData1?.data?.Header?.data || {};
    const headerData2 = compareData2?.data?.Header?.data || {};

    const netData1 = compareData1?.data?.net || {};
    const netData2 = compareData2?.data?.net || {};

    const keys1 = Object.keys(headerData1);
    const keys2 = Object.keys(headerData2);

    const maxLength = Math.max(keys1.length, keys2.length);

    const labels = [];
    const netValues1 = [];
    const netValues2 = [];

    for (let i = 0; i < maxLength; i++) {
      const key1 = keys1[i];
      const key2 = keys2[i];

      let label1 = key1 ? headerData1[key1]?.Label : "";
      let label2 = key2 ? headerData2[key2]?.Label : "";

      if (!label1 && label2) {
        label1 = label2;
      }
      // If both label1 and label2 are available, you can merge them or just pick one
      // For demonstration, let's just pick label1 as the primary label
      const finalLabel = label2 || `Week ${i + 1}`;

      labels.push(finalLabel);

      const val1 = key1 ? parseFloat(netData1[key1] || 0) : 0;
      const val2 = key2 ? parseFloat(netData2[key2] || 0) : 0;

      netValues1.push(val1);
      netValues2.push(val2);
    }

    const label1 = `${filters.year1} ${localised.net} (${currencySymbol})`;
    const label2 = `${filters.year2} ${localised.net} (${currencySymbol})`;

    return {
      labels,
      datasets: [
        {
          label: label1,
          data: netValues1,
          fill: false,
          borderColor: "#2a72b5",
          tension: 0.1,
        },
        {
          label: label2,
          data: netValues2,
          fill: false,
          borderColor: "rgba(75,192,192,1)",
          tension: 0.1,
        },
      ],
    };
  };

  
  const searchJobsAndRevenue = (filters) => {
    getJobAndRevenueData(filters);  
  }

  const resetSearchFilter = () => {
    getJobAndRevenueData(filters);  
  }
  
  return (
    <>
      <div className="container-fluid">
        <div className="d-flex align-items-center flex-wrap">
          <div className="list-header-left">
            <h1 className="page-title">{localised.jobsandRevenueReport}</h1>
            <Breadcrumbs
              mainpage={localised.home}
              currentpage={localised.jobsandRevenueReport}
            />
          </div>
        </div>
        <div className="search-container mb-3">
          <Search tableColumn={isCompare} setTableColumn={setISCompare}
            searchJobsAndRevenue={searchJobsAndRevenue}
            resetSearchFilter={resetSearchFilter} showWarningModal={showWarningModal}
            setShowWarningModal={setShowWarningModal}
            setIsCompared={setIsCompared} isCompared={isCompared}
          />
        </div>
        <div className="row justify-content-center bg-white m-0 mb-3">
          <div className="col-md-8">
            <div className="graph">
            <Line 
              data={netChartData} 
              options={{
                responsive: true,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      callback: function (value) {
                        return `${currencySymbol}${value}`;
                      },
                    },
                  },
                },
              }} 
            />
            </div>
          </div>
        </div>
        <div className="mb-1 mt-2 d-flex justify-content-end">
          <DownloadTableExcel
              filename="JobsAndRevenueReport"
              sheet="JobsAndRevenueReport"
              currentTableRef={tableRef.current}
            >
              <button className="btn btn-primary mb-3">{localised.export}</button>
          </DownloadTableExcel>
       </div>
        <div className="jobsrevenuetable">
          {
            !isCompared && 
            <Table bordered className="table" ref={tableRef}>
              <tbody>
                  {tableData.slice(0).map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                  >
                    <th style={{width: '260px', textAlign: "left", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', position: 'sticky', left:0, zIndex: 1, borderRight: '1px solid #e6e6e6',borderBottom:[2, 5, 7, 8,9, 13, 15, 17, 19].includes(rowIndex + 1) ? '1px solid black': 'none'}}>{row[0]}</th>
                    {row.slice(1).map((cell, cellIndex) => (
                      <td key={cellIndex} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',borderBottom:[2, 5, 7, 8,9, 13, 15, 17, 19].includes(rowIndex + 1) ? '1px solid black': 'none'}}>
                        {Array.isArray(cell) ? (
                          <>
                            <div>{cell[0]}</div>
                            <hr style={{ border: '0.5px solid black', margin: '5px 0' }} />
                            <div>{cell[1]}</div>
                          </>
                        ) : (
                        <>
                        <div className="text-center">{cell}</div>
                        </>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          }
          {
            isCompared && 
            <Table bordered className="table"  ref={tableRef}>
              <tbody>
                <tr style={{ borderBottom: 'none' }}>
                  <th style={{width: '250px', textAlign: "left"}} className="">{localised.week}</th> 
                  <th style={{width: '250px', textAlign: "left"}}></th>
                 
                  {tableData[0]?.slice(1)?.map((week, index) => (
                    <>
                      <td key={index} style={{textAlign: "center"}}>
                        {index + 1}
                      </td>
                    </>
                  ))}
                </tr>

                <tr style={{ borderBottom: '1px solid black' }}>
                  <th style={{width: '250px', textAlign: "left"}}></th> 
                  <th style={{textAlign: "center"}}>{localised.date}</th>
                  {tableData[1]?.slice(1)?.map((dates, index) => (
                    <>
                      <td key={index}>
                        <table className="w-100 text-center">
                          <tr><td style={{ borderBottom: '1px solid #d9d9d9'}}><div>{dates[0]}</div></td></tr>
                          <tr><td><div>{dates[1]}</div></td></tr>
                        </table>
                        {/* {dates[1]} */}
                      </td>
                    </>
                  ))}
                </tr>
                {tableData.slice(2).map((row, rowIndex) => ( 
                  <tr
                    key={rowIndex}
                    style={{
                      borderBottom:
                        [3,5,6,7,11,13,15,17].includes(rowIndex + 1)
                          ? '1px solid black'
                          : 'none',
                    }}
                  >
                    <th style={{width: '250px', textAlign: "left", alignContent: "center"}}>{row[0]}</th>
                    <td style={{padding: 0, alignContent: "center"}}>
                      <table className="w-100 text-center">
                        <tr><td style={{ borderBottom: '1px solid #d9d9d9', backgroundColor: '#e6e6e6'}}><div style={{padding: "10px"}}>{compateMonthYr.compareMonthYear1}</div></td></tr>
                        {/* <hr style={{ border: '0.5px solid black', margin: '5px 0' }} /> */}
                        <tr><td><div style={{padding: "10px"}}>{compateMonthYr.compareMonthYear2}</div></td></tr>
                      </table>
                    </td>

                    {row.slice(1).map((cell, cellIndex) => (
                      <td key={cellIndex} style={{padding: 0, alignContent: "center"}}>
                        {Array.isArray(cell) ? (
                          <>
                            <table className="w-100 text-center">
                              <tr><td style={{borderBottom: '1px solid #d9d9d9', margin: "2px",backgroundColor: '#e6e6e6'}}><div className="text-center" style={{padding: "10px"}}>{cell[0]}</div></td></tr>
                              {/* <hr style={{ border: '0.5px solid black', margin: '5px 0' }} /> */}
                              <tr><td style={{}}><div className="text-center" style={{padding: "10px"}}>{cell[1]}</div></td></tr>
                            </table>
                          </>
                        ) : (
                          cell
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          }
        </div>
      </div>

    </>
  );
};

export default JobsAndRevenue;
