export const AdditionalFeatureNames = {
  ReportedIssues: 'Reported Issues',
  SMS: 'SMS',
  SMSMessaging: 'SMS Messaging',
  GeolocationTracking: 'Geolocation Tracking',
  GuestManagement: 'Guest Management',
  AppointmentMessaging: 'Appointment Messaging',
}

export const checkCompanyHasAdditionalFeatureAccess = (
  userDetails,
  company_additionalfeatures_id,
) => {
  return userDetails?.some(
    item => item.additional_features_id === company_additionalfeatures_id,
  );
};

export const billableFeatureCost = (label) => {
  let costTitle = label;
  switch (label) {
    case 'Inventory':
      costTitle = 'inventory_feature_cost';
      break;
    case 'SMS Messaging':
      costTitle = 'sms_feature_cost';
      break;
    case 'QuickBooks':
      costTitle = 'qb_feature_in_percent';
      break;
    default:
      break;
  }
  return costTitle;
}


export const prepareAdditionalFeatureLabel = (label,isFromStaging = false) => {
  let costTitle = label;
  switch (label) {
    case 'Inventory':
      if(isFromStaging) {
        costTitle = 'Inventory Basic';
      } else {
        costTitle = 'Inventory Plus';
      }
      break;
      case 'SMS':
        costTitle = 'SMS Notifications';
        break;
      case 'SMS Messaging':
        costTitle = 'SMS Inbox';
        break;
      default:
      break;
  }
  return costTitle;
};

export const inventoryAdditionalFeatureId = 40;

export const SMSMessagingAdditionalFeatureId = 41;

export const QuickbooksAdditionalFeatureId = 46;

export const CleanerDirectorySearchExtendendQuotaAdditionalFeatureId = 47;

export const billableAdditionalFeature = [
    inventoryAdditionalFeatureId,
    SMSMessagingAdditionalFeatureId,
    QuickbooksAdditionalFeatureId
];

export const staffPlannerAdditionalFeatureId = 42;

export const resetAppointmentAdditionalFeatureId = 32;

export const commonAreaAdditionalFeatureId = 4;

export const billableNotInAdditionalFeature = [
  SMSMessagingAdditionalFeatureId,
  QuickbooksAdditionalFeatureId
];

export const HkTimeAnlysisAdditionalFeatureId = 5;

export const inspectionAdditionalFeatureId = 1;

export const houseAttendantAdditionalFeatureId = 3;

export const appointmentMessagingFeature = 45;

export const inPercentageAdditionalFeatureId  = [
  QuickbooksAdditionalFeatureId
];

export const isAnyIdPresent = (features) => {
  const isAnyIdPresent = billableAdditionalFeature.some((id) =>
    features.some((feature) => feature.additional_features_id === id)
  );
  return isAnyIdPresent;
}
