import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
/**
 * CompanyMiddleware can be used to call any Company related API services
 * @param {*} store 
 * @returns 
 */
const CompanyMiddleware = (store) => (next) => async (action) => {
  // Our middleware
  const result = next(action);
  let endpoint = microServices.base + '/v1/admin';
  switch (action.type) {

    case "FETCH_CLEANER_ASSOCIATES":
      endpoint = microServices.base + '/v1/dropdown/cleaner/associates'+'?per_page=0';
      store.dispatch(isLoader(true));
      APIservice
        .get(endpoint)
        .then((response) => {
          if (response && response.data) {
            let cleanerAssociates = prepareAssociateList(response);
            store.dispatch({
              type: 'SET_CLEANER_ASSOCIATES',
              payload: {
                data: cleanerAssociates
              }
            });
          }
        })
        .catch((error) => {
          let err = { componentName: 'cleanersPm', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
      case "FETCH_MANAGER_ASSOCIATES":
      endpoint = microServices.base + '/v1/dropdown/propertymanager/associates'+'?per_page=0';
      if (action?.payload?.hasOwnProperty('payment_mode')) {
        
        endpoint = endpoint+'&payment_mode=' + action.payload.payment_mode;
      }
      store.dispatch(isLoader(true));
      APIservice
        .get(endpoint)
        .then((response) => {
          if (response && response.data) {
            if (action?.payload?.hasOwnProperty('isPmLock')) {
              let managerAssociates = prepareAssociateList(response);
              store.dispatch({
                type: 'SET_PMLOCK_MANAGER_ASSOCIATES',
                payload: {
                  data: managerAssociates
                }
              });
            }
            
            else{
              let managerAssociates = prepareAssociateList(response);
              store.dispatch({
                type: 'SET_MANAGER_ASSOCIATES',
                payload: {
                  data: managerAssociates
                }
              });
            }
          }
        })
        .catch((error) => {
          let err = { componentName: 'PM_Cleaner', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
     
      
      case "GET_MANAGER_PAYMENT_METHOD":
        store.dispatch(isLoader(true));
        endpoint  = microServices.base+'/v1/company/manager/associates/paymentmode/'+action.payload;
        APIservice
        .get(endpoint)
        .then((response)=>{
          if(response && response.data){
            store.dispatch({ 
            type: 'SET_DATA_FOR_EDIT', 
            payload: {
              data:response.data.data
              }
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:'GET_CLEANER',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;

      
      case "CHANGE_BILLING_METHD":
        store.dispatch(isLoader(true));
        endpoint  = microServices.base+'/v1/company/manager/associates/paymentmode/'+action.payload.formData.relation_id;
        APIservice
          .put(endpoint, action.payload.formData)
          .then((response) => {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'success',
                message: 'Billing mode changed successfully!',
                // componentName: 'CleanerList'
              }
            });
          })
          .catch((error) => {
            if (error.response.status !== 422) {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'danger',
                  message: 'Unable to save user information!',
                }
              });
            } else {
              let err = { componentName: 'CleanerList', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

      case "FETCH_COMPANY_INFO":
        store.dispatch(isLoader(true));
        endpoint  = microServices.base+'/v1/company/account/profile';
        APIservice
        .get(endpoint)
        .then((response)=>{
          if(response && response.data){
            store.dispatch({ 
              type: 'SET_COMPANY_INFO', 
              payload: {
                data:response.data.data
              }
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:'GET_COMPANY_INFO',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;

      case "UPDATE_COMPANY_INFO":
        store.dispatch(isLoader(true));
        endpoint  = microServices.base+'/v1/company/account/profile';
        APIservice
        .put(endpoint, action.payload.formData)
        .then((response)=>{
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              componentName:'UPDATE_COMPANY_INFO',
              variant: 'success',
              message: 'Profile Updated Successfully!'
            }
          });
        })
        .catch((error)=>{
          let err = {componentName:'UPDATE_COMPANY_INFO',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;

      case "FETCH_COMPANY_PHOTOS":
        store.dispatch(isLoader(true));
        endpoint = microServices.base + "/v1/company/account/profile/photos";
        APIservice.get(endpoint)
          .then((response) => {
            if (response && response.data) {
              store.dispatch({
                type: "SET_COMPANY_PHOTOS",
                payload: {
                  data: response.data.data,
                },
              });
            }
          })
          .catch((error) => {
            let err = {
              componentName: "GET_COMPANY_PHOTOS",
              statusCode: error.response.status,
              data: error.response.data,
            };
            store.dispatch(setError(err));
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          });
        break;

      case "INITIALIZE_PROFILE_PICTURE":
        endpoint = microServices.base+'/v1/company/setting/profile-picture/'+action.payload.id+'?_method=put';
        store.dispatch(isLoader(true));
        APIservice
        .post(endpoint, action.payload.formData)
          .then((response)=>{
            store.dispatch({ 
              type: 'UPLOAD_PROFILE_PICTURE',
              message: 'Profile Picture Uploded successfully.',
              payload: { 
                'uploadUrl':response.data.data.upload,
                'uploadImagedata':action.payload.formData
              }
              });
          })
          .catch((error)=>{
            let err = {componentName:'ProfilePicture',statusCode: error.response.status,data: error.response.data};
            store.dispatch(setError(err));
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
  break;
  case "UPLOAD_PROFILE_PICTURE":
    endpoint = action.payload.uploadUrl;
    store.dispatch(isLoader(true));
    APIservice
    .postMultipartData(endpoint,action.payload.uploadImagedata)
    .then(()=>{
      store.dispatch({ 
        type: 'SHOW_ALERT', 
        payload: {
          variant:'success',
          message: (action?.message) ? action.message : 'Profile Picture Uploded successfully.',
          componentName:(action?.componentName) ? action.componentName : 'UploadProfilePicture',
        }
      });
     })
    .catch((error)=>{
        let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
        store.dispatch(setError(err));
    }).finally(()=>{
        store.dispatch(isLoader(false));
    })
    break;
    case "FETCH_PROFILE_PICTURE":
      endpoint = microServices.base+'/v1/company/setting/profile-picture/'+action.payload.id;
      store.dispatch(isLoader(true));
      APIservice
        .get(endpoint)
        .then((response)=>{
          if(response && response.data){
            store.dispatch({ 
            type: 'SET_PROFILE_PICTURE', 
            payload: {
              data:response.data.data
              }
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:'Inventory',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
    break;
    case "DELETE_PROFILE_PICTURE":
              endpoint = microServices.base+'/v1/company/setting/profile-picture/';
              store.dispatch(isLoader(true));
                APIservice
                  .put(endpoint+'/delete/'+action.payload.id)
                  .then((response)=>{
                   
                      store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                          variant:'success',
                          message: 'Profile Picture deleted successfully!',
                        }
                      });
                    
                  })
                  .catch((error)=>{
                      if (error.response.status !== 422) {
                        store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                            variant:'danger',
                            message: 'Unable to Delete Profiele Picture!',
                          }
                        })}
                        else {
                          let err = {componentName:'deleteProfilePicture',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                        }
                      })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  });
              break;

              case "FETCH_PAYPAL_ADDRESS":
                store.dispatch(isLoader(true));
                endpoint  = microServices.base+'/v1/company/account/paypal';
                APIservice
                .get(endpoint)
                .then((response)=>{
                  if(response && response.data){
                    store.dispatch({
                     type: 'SET_PAYPAL_ADDRESS', 
                      payload: {
                        data:response.data.data
                        }
                    });
                  }
                })
                .catch((error)=>{
                let err = {componentName:'GET_PAYPAL_ADDRESS',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
              break;
              
         case "UPDATE_PAYPAL_ADDRESS":
                store.dispatch(isLoader(true));
                endpoint  = microServices.base+'/v1/company/account/paypal';
                APIservice
                .put(endpoint, action.payload.formData)
                .then((response)=>{
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant: 'success',
                      message: 'Paypal Email Address Updated Successfully!',
                      componentName: 'UpdatePaypalEmailAddress'
                    }
                  });
                })
                .catch((error)=>{
                  let err = {componentName:'PUT_PAYPAL_ADDRESS',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
                break;
                case "FETCH_COMPANY_SETTING":
                  endpoint = microServices.base+'/v1/company/setting/account/'+action.payload.company_id;
                  store.dispatch(isLoader(true));
                  APIservice
                    .get(endpoint)
                    .then((response)=>{
                      if(response && response.data){
                        store.dispatch({ 
                        type: 'SET_COMPANY_SETTING', 
                        payload: {
                          data:response.data.data
                          }
                        });
                      }
                    })
                    .catch((error)=>{
                      let err = {componentName:'CompanySetting',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
                case "UPDATE_COMPANY_SETTING":
                  endpoint = microServices.base+'/v1/company/setting/account/'+action.payload.company_id+'?_method=put';
                  store.dispatch(isLoader(true));
                  APIservice
                    .post(endpoint,action.payload.formData)
                      .then((response)=>{
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'success',
                            componentName:'UpdateCompanySetting',
                            message: 'Company Setting Updated Successfully!'
                          }
                        });
                    })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'danger',
                              message: 'Unable to save Company Setting information!',
                            }
                          });
                        } else {
                          let err = { componentName: 'UpdateCompanySetting', statusCode: error.response.status, data: error.response.data };
                          store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
                case "FETCH_INVOICE_SETTING":
                  endpoint = microServices.base+'/v1/company/setting/invoice-settings/'+action.payload.company_id;
                  store.dispatch(isLoader(true));
                  APIservice
                    .get(endpoint)
                    .then((response)=>{
                      if(response && response.data){
                        store.dispatch({ 
                        type: 'SET_INVOICE_SETTING', 
                        payload: {
                          data:response.data.data
                          }
                        });
                      }
                    })
                    .catch((error)=>{
                      let err = {componentName:'InvoiceSetting',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
                case "UPDATE_INVOICE_SETTING":
                  endpoint = microServices.base+'/v1/company/setting/invoice-settings/'+action.payload.company_id;
                  store.dispatch(isLoader(true));
                  APIservice
                    .put(endpoint,action.payload.initalizeData)
                      .then((response)=>{
                        if(!response.data.data.upload)
                        {
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'success',
                            componentName:'UpdateInvoiceSetting',
                            message: 'Invoice Setting Updated Successfully!'
                          }
                        });
                      }
                      else{
                        store.dispatch({ 
                          type: 'UPLOAD_PROFILE_PICTURE',
                          message: 'Invoice Setting Updated Successfully!',
                          componentName:'UpdateInvoiceSetting',
                          payload: { 
                            'uploadUrl':response.data.data.upload,
                            'uploadImagedata':action.payload.formData,
                          },
                          })
                      }
                    })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'danger',
                              message: 'Unable to save Company Setting information!',
                            }
                          });
                        } else {
                          let err = { componentName: 'InvoiceSetting', statusCode: error.response.status, data: error.response.data };
                          store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
                case "FETCH_APPOINTMENT_PREVIEW_SETTING":
                  endpoint = microServices.base+'/v1/company/setting/appointment-preview/'+action.payload.company_id;
                  store.dispatch(isLoader(true));
                  APIservice
                    .get(endpoint)
                    .then((response)=>{
                      if(response && response.data){
                        store.dispatch({ 
                        type: 'SET_APPOINTMENT_PREVIEW_SETTING',
                        payload: {
                          data:response.data.data
                          }
                        });
                      }
                    })
                    .catch((error)=>{
                      let err = {componentName:'AppointmentPreviewSetting',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
                case "UPDATE_APPOINTMENT_PREVIEW_SETTING":
                  endpoint = microServices.base+'/v1/company/setting/appointment-preview/'+action.payload.company_id;
                  store.dispatch(isLoader(true));
                  APIservice
                    .put(endpoint,
                      {
                        appt_preview: action.payload.formData
                      })
                      .then((response)=>{
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'success',
                            componentName:'UpdateAppointmentPreviewSetting',
                            message: 'Appointment Preview Setting Updated Successfully!'
                          }
                        });
                    })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'danger',
                              message: 'Unable to save Appointment Preview Setting information!',
                            }
                          });
                        } else {
                          let err = { componentName: 'AppointmentPreviewSetting', statusCode: error.response.status, data: error.response.data };
                          store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
              case "UPDATE_USER_SETTING":
                endpoint = microServices.base + `/v1/user/${action.payload.user_id}/settings`;
                store.dispatch(isLoader(true));
                APIservice
                  .put(endpoint, action.payload)
                  .then((response) => {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'success',
                        message: 'User settings saved successfully.',
                      }
                    });
                    if(typeof action.callback === 'function'){
                      action.callback(response);
                    }
                  })
                  .catch((error) => {
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                          variant: 'danger',
                          message: 'Unable to save User Setting information!',
                        }
                      });
                    } else {
                      let err = {statusCode: error.response.status, data: error.response.data};
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  })
                break;
                case "FETCH_APPT_LIST_COMPANY_INFO":
                  endpoint = microServices.base + '/v1/company/'+action.payload.comptype+'/associates?filter[company_id]='+action.payload.company_id;
                  APIservice
                    .get(endpoint)
                    .then((response) => {
                      if (response && response.data) {
                        store.dispatch({
                          type: 'SET_APPT_LIST_COMPANY_INFO',
                          payload: {
                            data: response.data.data[0]
                          }
                        });
                      }
                    })
                    .catch((error) => {
                      let err = { componentName: 'pmInfo', statusCode: error.response.status, data: error.response.data };
                      store.dispatch(setError(err));
                    })
          break;
          case "FETCH_MANAGER_ASSOCIATES_NEW":
        let perPagePM = '';
        if (action.payload.hasOwnProperty('perPage')) {
          perPagePM = '&per_page=' + '/v1/company/property-manager/associates?sort=company_name' + perPagePM;
        }

        endpoint = microServices.base + action.payload.url + perPagePM;
        store.dispatch(isLoader(true));
        APIservice
          .get(endpoint)
          .then((response) => {
            if (response && response.data) {
              let managerAssociates = prepareAssociateList(response);
              store.dispatch({
                type: 'SET_MANAGER_ASSOCIATES',
                payload: {
                  data: managerAssociates
                }
              });
            }
          })
          .catch((error) => {
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_MANAGER_ASSOCIATES', 
                payload: {
                  data: []
                },
              });
            } else {
              let err = { componentName: 'PM_Cleaner', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            }
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })
        break;
        case "UPDATE_COMPANY_INTEGRATION":
                store.dispatch(isLoader(true));
                endpoint  = microServices.base+'/v1/company/integration/';
                APIservice
                .post(endpoint+action.payload.type, action.payload.formData)
                .then((response)=>{
                  if (response) {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: action.payload?.variant ? action.payload.variant : 'success',
                        message: (action.payload.type=='brightside-workorder') ? `${action.payload.message}`  : `${action.payload.message} connection successfully saved!`,
                        componentName:'UpdateCompanyIntegration'
                      }
                    });
                  }
                })
                .catch((error)=>{
                  let err = {componentName:'UpdateCompanyIntegration',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
                break;

        case "UPDATE_ITRIP_CONTRACTOR":
          endpoint  = microServices.base+'/v1/company/manager/associates/itripContractor/'+action.payload.formData.manager_cleaner_id;
          APIservice
            .put(endpoint, action.payload.formData)
            .then((response) => {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'success',
                  componentName: 'UpdateItripIntegration'
                }
              });
            })
            .catch((error) => {
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'danger',
                    message: 'Unable to save user information!',
                    componentName: 'UpdateItripIntegration'
                  }
                });
              } else {
                let err = { componentName: 'UpdateItripIntegration', statusCode: error.response.status, data: error.response.data };
                store.dispatch(setError(err));
              }
            })
        break;
        case "FETCH_LEFT_SIDEBAR_COUNT":
          endpoint = microServices.base + '/v1/sidebar/count';
          APIservice
            .get(endpoint)
            .then((response) => {
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_LEFT_SIDEBAR_COUNT',
                  payload: {
                    data: response.data.data
                  }
                });
              }
            })
            .catch((error) => {
              let err = { componentName: 'LeftSidebar', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
        break;

        case "FETCH_SCHEDULE_STAFF_SETTING": {
          endpoint = microServices.base + '/v1/company/schedule-staff/setting';
          APIservice.get(endpoint)
            .then((response) => {
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_SCHEDULE_STAFF_SETTING',
                  payload: {
                    data: response.data.data
                  }
                });
              }
            })
            .catch((error) => {
              let err = { componentName: 'ScheduleStaffSetting', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
          break;          
        }

        case "HANDLE_SCHEDULE_STAFF_SETTING": {
          endpoint = microServices.base + '/v1/company/schedule-staff/setting/handle';
          store.dispatch(isLoader(true));
          APIservice.post(endpoint, action.payload.formData)
            .then((response)=>{
              if (response) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'success',
                    message: 'Schedule staff settings saved successfully!',
                    componentName:'ScheduleStaffSetting'
                  }
                });
              }
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'danger',
                    message: error?.response?.data?.message || 'Unable to save Schedule staff setting!',
                  }
                });
              } else {
                let err = {componentName:'ScheduleStaffSetting',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));  
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;          
        }

        case "DROPDOWN_SCHEDULE_STAFF_SHIFTS": {
          endpoint = microServices.base + '/v1/dropdown/schedule-staff-shifts';
          APIservice.get(endpoint)
            .then((response) => {
              if (response && response.data) {
                let scheduleStaffShiftsDropdown = prepareScheduleStaffShiftsDropdown(response.data);
                store.dispatch({
                  type: 'SET_DROPDOWN_SCHEDULE_STAFF_SHIFTS',
                  payload: {
                    data: scheduleStaffShiftsDropdown
                  }
                });
              }
            })
            .catch((error) => {
              let err = { componentName: 'DefaultScheduleTab', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
          break;
        }

        case "FETCH_EMAIL_SETTINGS": {
          endpoint = microServices.base + '/v1/company/staff/' + action.payload.user_id +'/email-setting?per_page=50';
          store.dispatch(isLoader(true));
          APIservice.get(endpoint)
            .then((response) => {
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_EMAIL_SETTING',
                  payload: {
                    data: response.data.data
                  }
                });
              }
            })
            .catch((error) => {
            	let err = { componentName: 'EmailSetting', statusCode: error.response.status, data: error.response.data };
            	store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })  
          break;
        }

        case "UPDATE_EMAIL_SETTING": {
          endpoint = microServices.base + '/v1/company/staff/' + action.payload.userId +'/email-setting';
          store.dispatch(isLoader(true));
          APIservice.put(endpoint, action.payload.formData)
          .then((response) => {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'success',
                componentName: 'UpdateEmailSetting',
                message: 'Email Settings updated successfully!'
              }
            });
          })
          .catch((error) => {
            if (error.response.status !== 422) {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'danger',
                  message: 'Unable to update email information!',
                  componentName: 'UpdateEmailSetting'
                }
              });
            } else {
              let err = { componentName: 'UpdateEmailSetting', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
          break;
        }

        case "FETCH_PUSH_NOTIFICATIONS": {
          endpoint = microServices.base + '/v1/company/staff/' + action.payload.user_id +'/push-notification?per_page=0';
          APIservice.get(endpoint)
            .then((response) => {
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_PUSH_NOTIFICATIONS',
                  payload: {
                    data: response.data.data
                  }
                });
              }
            })
            .catch((error) => {
            	let err = { componentName: 'PushNotifications', statusCode: error.response.status, data: error.response.data };
            	store.dispatch(setError(err));
            })
          break;
        }

        case "UPDATE_PUSH_NOTIFICATIONS": {
          endpoint = microServices.base + '/v1/company/staff/' + action.payload.userId +'/push-notification';
          store.dispatch(isLoader(true));
          APIservice.put(endpoint, action.payload.formData)
          .then((response) => {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'success',
                componentName: 'UpdatePushNotifications',
                message: 'Push Settings Updated Successfully!'
              }
            });
          })
          .catch((error) => {
            if (error.response.status !== 422) {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'danger',
                  message: 'Unable to update push information!',
                  componentName: 'UpdatePushNotifications'
                }
              });
            } else {
              let err = { componentName: 'UpdatePushNotifications', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
          break;
        }

        case "FETCH_STAFF_DEFAULT_SCHEDULE": {
          endpoint = microServices.base + '/v1/company/staff/default-schedule?filter[user_id]='+action?.payload?.user_id;
          APIservice.get(endpoint)
            .then((response) => {
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_STAFF_DEFAULT_SCHEDULE',
                  payload: {
                    data: response.data.data
                  }
                });
              }
            })
            .catch((error) => {
              let err = { componentName: 'DefaultScheduleTab', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;          
        }

        case "ALTER_STAFF_DEFAULT_SCHEDULE": {
          endpoint = microServices.base + '/v1/company/staff/default-schedule/alter';
          store.dispatch(isLoader(true));
          APIservice.post(endpoint, action.payload.formData)
            .then((response)=>{
              if (response) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'success',
                    componentName:'DefaultScheduleTab'
                  }
                });
              }
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'danger',
                    message: error?.response?.data?.message || 'Unable to save Default schedule!',
                    componentName:'DefaultScheduleTab'
                  }
                });
              } else {
                let err = {componentName:'DefaultScheduleTab',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));  
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
        }

        case "ADD_SCHEDULE_STAFF_LISTING": {
          let filters = action?.payload?.filters || '?';
          endpoint = `${microServices.base}/v1/company/schedule-staff/add-staff-list/${action.payload.params.week_start_date}${filters}&per_page=0`;
          store.dispatch(isLoader(true));
          APIservice.get(endpoint)
            .then((response)=>{
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_ADD_STAFF_LIST',
                  payload: {
                    data: response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'AddScheduleStaffListing',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
        }

        case "ADD_SCHEDULE_STAFF": {
          endpoint = microServices.base + '/v1/company/schedule-staff/add-staff';
          store.dispatch(isLoader(true));
          APIservice.post(endpoint, action.payload.formData)
            .then((response)=>{
              if (response) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'success',
                    componentName:'AddScheduleStaff',
                    message: 'Staff added successfully!',
                  }
                });
              }
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'danger',
                    message: error?.response?.data?.message || 'Unable to Add staff!',
                    componentName:'AddScheduleStaff'
                  }
                });
              } else {
                let err = {componentName:'AddScheduleStaff', statusCode: error.response.status, data: error.response.data};
                store.dispatch(setError(err));  
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
        }
        case "FETCH_MESSAGE_COUNT":
          endpoint = microServices.notification + '/v1/emaillog/unread-count';
          APIservice
            .get(endpoint)
            .then((response) => {
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_HEADER_MESSAGE_COUNT',
                  payload: {
                    data: response.data
                  }
                });
              }
            })
            .catch((error) => {
              let err = { componentName: 'HeaderCountData', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
        break;

        case "FETCH_INTEGRATION_CLIENTS":
          endpoint = microServices.base + '/v1/company/integration/list/'+ action.payload.integration +'?per_page=0';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint)
            .then((response) => {
              if (response && response.data.data) {
                store.dispatch({
                  type: 'SET_INTEGRATION_CLIENTS',
                  payload: {
                    data: response.data.data
                  }
                });
              }
            })
            .catch((error) => {
              let err = { componentName: 'IntegrationClient', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;

        case "GET_INTEGRATION_PROPERTIES_FEED":
          endpoint = microServices.integration + '/v1/integration-reader/' + action.payload.company_id + '/' + action.payload.integration +'?start_date=' + action.payload.start_date + '&end_date=' + action.payload.end_date + '&old_rc_id=' + action.payload.old_rc_id + '&property_id=' + action.payload.property_id;
          if(action.payload.integration === "vacasa") {
            endpoint = endpoint + '&manager_id=' + action.payload.manager_id;
          }
          if(action.payload.integration === "rmscloud") {
            endpoint = endpoint + '&category_id=' + action.payload.category_id + '&area_id=' + action.payload.area_id;
          }
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint)
            .then((response) => {
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_INTEGRATION_PROPERTIES',
                  payload: {
                    data: response.data
                  }
                });
              }
            })
            .catch((error) => {
              let err = { componentName: 'IntegrationClientFeed', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break; 

        case "COPY_PREV_SCHEDULE_STAFF_LISTING": {
          let filters = action?.payload?.filters ? `?${action?.payload?.filters}` : `?`;
          endpoint = `${microServices.base}/v1/company/schedule-staff/copy-prev-list${filters}&per_page=0`;
          store.dispatch(isLoader(true));
          APIservice.get(endpoint)
            .then((response)=>{
              if (response && response.data) {
                store.dispatch({
                  type: 'SET_COPY_PREV_SCHEDULE_STAFF_LIST',
                  payload: {
                    data: response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'CopyPrevScheduleStaffListing',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
        }

        case "SCHEDULE_STAFF_TIMESHEET_EXPORT": {          
          endpoint = action?.payload?.selectedLanguage ? 
          microServices.base + '/v1/'+action?.payload?.selectedLanguage+'/company/schedule-staff/time-sheet-export/app'+action.payload.filters+'&per_page=0' : 
          microServices.base + '/v1/company/schedule-staff/time-sheet-export/app'+action.payload.filters+'&per_page=0';          
          let fileName = action?.payload?.fileName ? action?.payload?.fileName : "Timesheet";
          let ext = ".xlsx";
          if(action?.payload?.report) {
            endpoint =  action?.payload?.selectedLanguage ? microServices.base + '/v1/'+action?.payload?.selectedLanguage+'/reports/export/timesheet'+action.payload.filters+'&per_page=0' : microServices.base + '/v1/reports/export/timesheet'+action.payload.filters+'&per_page=0';
            fileName =  action?.payload?.fileName ? action?.payload?.fileName : "timesheet";
          }
          APIservice.getBlobDataWithParams(endpoint)
              .then((response) => {
                  const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = blobUrl;
                  link.setAttribute('download', fileName + ext);
                  document.body.appendChild(link);
                  link.click(); 
                  link.parentNode.removeChild(link);
              })
              .catch((error) => {
                  if (error.response.status !== 422) {
                      store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                              variant:'danger',
                              message: 'Unable to download report!',
                              componentName: 'ScheduleStaffTimesheetExport',
                          }
                      });
                  } else {
                      let err = {componentName: 'ScheduleStaffTimesheetExport', statusCode: error.response.status, data: error.response.data};
                      store.dispatch(setError(err));
                  }
              })
              .finally(() => {});
          break;
        }

      case "FETCH_VACASA_PM":
        endpoint = microServices.base + '/v1/dropdown/cleaner/associates'+'?per_page=0';
          if(action.payload?.cleaner_id) {
            endpoint = endpoint + `&cleaner_id=${action.payload?.cleaner_id}`;
          }
        store.dispatch(isLoader(true));
        APIservice
        .get(endpoint)
        .then((response) => {
          if (response && response.data) {
            response.data.data = response.data.data.filter((managerCleaner) => {
              return managerCleaner.vacasa_client_id ? true : false;
            });
            let cleanerAssociates = prepareAssociateList(response);
            store.dispatch({
              type: 'SET_VACASA_PM_DROPDOWN',
              payload: {
                data: cleanerAssociates
              }
            });
          }
        })
        .catch((error) => {
          let err = { componentName: 'cleanersPm', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
        case "SCHEDULE_STAFF_EXPORT": {          
          endpoint = microServices.base + '/v1/'+action.payload.selectedLanguage+'/company/schedule-staff/export'+action.payload.filters+'&per_page=0';          
          let fileName = action.payload.fileName;
          let ext = ".xlsx";
          APIservice.getBlobDataWithParams(endpoint)
              .then((response) => {
                  const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = blobUrl;
                  link.setAttribute('download', fileName + ext);
                  document.body.appendChild(link);
                  link.click(); 
                  link.parentNode.removeChild(link);
              })
              .catch((error) => {
                  if (error.response.status !== 422) {
                      store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                              variant:'danger',
                              message: 'Unable to download report!',
                              componentName: 'ScheduleStaffExport',
                          }
                      });
                  } else {
                      let err = {componentName: 'ScheduleStaffExport', statusCode: error.response.status, data: error.response.data};
                      store.dispatch(setError(err));
                  }
              })
              .finally(() => {});
          break;
        }

        case "DELETE_SCHEDULE_STAFF_LOGS_SHIFT": {
          endpoint = `${microServices.base}/v1/company/schedule-staff/shift/logs/${action.payload.parent_staff_time_track_id}`;
          store.dispatch(isLoader(true));
          APIservice.delete(endpoint)
            .then((response) => {
              if(response) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                      variant: 'success',
                      message: 'Shift logs deleted successfully',
                      componentName: 'DeteteScheduleStaffLogsShift'
                  }
                });
              }
            })
            .catch((error) => {
                if (error.response.status !== 422) {
                    store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                            variant: 'danger',
                            message: 'Unable to delete shift logs'
                        }
                    });
                } else {
                    let err = { componentName: 'DeteteScheduleStaffLogsShift', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                }
              }
            )
            .finally(() => {
              store.dispatch(isLoader(false));
            })
          ;
          break;
        }

        case "APPROVE_SHIFT_LOGS_TIME": {
          endpoint = `${microServices.base}/v1/company/schedule-staff/shift/log/approve/multiple`;
          store.dispatch(isLoader(true));
          APIservice.post(endpoint, action.payload.formData)
            .then((response) => {
              if(response) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                      variant: 'success',
                      message: 'Shift log approved successfully',
                      componentName: 'ApproveShiftLogsTime'
                  }
                });
              }
            })
            .catch((error) => {
                if (error.response.status !== 422) {
                    store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                            variant: 'danger',
                            message: 'Unable to approve shift log'
                        }
                    });
                } else {
                    let err = { componentName: 'ApproveShiftLogsTime', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                }
              }
            )
            .finally(() => {
              store.dispatch(isLoader(false));
            })
          ;
          break;
        }
        case "UPDATE_LMPM_COMPANY_INTEGRATION":{
          store.dispatch(isLoader(true));
                endpoint  = microServices.base+'/v1/company/integration/';
                APIservice
                .post(endpoint+action.payload.type, action.payload.formData)
                .then((response)=>{
                  if (response) {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: action.payload?.variant ? action.payload.variant : 'success',
                        message: `${action.payload.message} Connection Successfully saved`,
                        componentName:'updateLmpmCompanyIntegration'
                      }
                    });
                  }
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SHOW_ALERT', 
                      payload: {
                        variant:'danger',
                        message: 'Invalid LMPM Details',
                        componentName:'updateLmpmCompanyIntegration'
                      }
                    });
                  }
                  else{
                    let err = {componentName:'updateLmpmCompanyIntegration',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  }
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
                break;
        }
        case "UPDATE_SOJO_INTEGRATION":
                store.dispatch(isLoader(true));
                endpoint  = microServices.base+'/v1/property/sojo/updateSojoConfiguration/'+action.payload.properties_id;
                APIservice
                .post(endpoint, action.payload.formData)
                .then((response)=>{
                  if (response) {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'success',
                        // message: `${action.payload.message} Connection Successfully saved`,
                        componentName:'updateSojoIntegration'
                      }
                    });
                  }
                })
                .catch((error)=>{
                  let err = {componentName:'updateSojoIntegration',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
                break;
      case "STAFF_ASSIGNMENT_PROGRESS":
      endpoint = `${microServices.base}/v1/company/staff/assignment-tracking/${action.payload.type}`;
      if(action.payload?.filters) {
        endpoint+=`?${action.payload.filters}`
      }
      let showLoader = (action.payload?.showLoader == 2) ? false : true;
      store.dispatch(isLoader(showLoader));
      APIservice
        .get(endpoint)
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: action.payload.action_name,
              payload: {
                data: response.data.data,
                target: response.data.target
              }
            });
          }
        })
        .catch((error) => {
          let err = { componentName: 'AssignmentTracking', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break; 

        case "ASSIGN_COLLECTION_METHOD":
            endpoint = microServices.base+'/v1/company/manager/associates/collectionMethod';
            APIservice
            .post(endpoint, action.payload.formData)
                .then((response)=>{
                })
                .catch((error)=>{
                  let err = {componentName:'AssignCollectionMethod', statusCode: error.response.status, data: error.response.data};
                  store.dispatch(setError(err));
                })
        break;
                case "UPDATE_VACATION_RENT_PRO_COMPANY_INTEGRATION":
                {
                  store.dispatch(isLoader(true));
                        endpoint  = microServices.base+'/v1/company/integration/';
                        APIservice
                        .post(endpoint+action.payload.type, action.payload.formData)
                        .then((response)=>{
                          if (response) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: action.payload?.variant ? action.payload.variant : 'success',
                                message: `${action.payload.message} Connection Successfully saved`,
                                componentName:'updateVacationRentProCompanyIntegration'
                              }
                            });
                          }
                        })
                        .catch((error)=>{
                          if (error.response.status !== 422) {
                            store.dispatch({ 
                              type: 'SHOW_ALERT', 
                              payload: {
                                variant:'danger',
                                message: 'Invalid Vacation Rent Pro Details',
                                componentName:'updateVacationRentProCompanyIntegration'
                              }
                            });
                          }
                          else{
                            let err = {componentName:'updateVacationRentProCompanyIntegration',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                        break;
                }
                case "GET_QB_CLIENTS": {
                  endpoint = `${microServices.base}/v1/invoice/qb/mapping/client`;
                  store.dispatch(isLoader(true));
                  APIservice.get(endpoint)
                    .then((response) => {
                      if (response && response.data) {
                        store.dispatch({
                          type: "SET_QB_CLINTS",
                          payload: {
                            data: response.data.data,
                          },
                        });
                      }
                    })
                    .catch((error) => {
                      let err = {
                        componentName: "getQbClients",
                        statusCode: error.response.status,
                        data: error.response.data,
                      };
                      store.dispatch(setError(err));
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;
                }
                case "STORE_QUICKBOOK_CLIENTS": {
                  let endUrl =  microServices.base + "/v1/invoice/qb/client/";
                  APIservice.post(endUrl+action.payload.companyId,{qb_cust_id :action.payload.selectedId})
                    .then((response) => {
                      if (response ) {
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'success',
                            message: "Clients mapped Successfully!",
                            rowIndex:action.payload.rowIndex,
                            componentName: 'MappedQuickbookClients',
                          }
                        });
                      }
                    })
                    .catch((error) => {
                      let err = {
                        componentName: "MappedQuickbookClients",
                        statusCode: error.response.status,
                        data: error.response.data,
                        
                      };
                      store.dispatch(setError(err));
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                    break;
                  } 
                  case "FETCH_ASSIGNMENT_TRACKING_INFO":
                    endpoint = microServices.base+'/v1/company/cleaner/view-assignment-tracking/'+action.payload.company_id;
                    store.dispatch(isLoader(true));
                    APIservice
                      .get(endpoint)
                      .then((response)=>{
                        if(response && response.data){
                          if(action?.payload?.isCleaner) {
                            store.dispatch({ 
                              type: 'SET_CLEANER_ASSIGNMENT_TRACKING_INFO', 
                              payload: {
                                data:response.data.data
                                }
                              });
                          } else {
                            store.dispatch({ 
                            type: 'SET_ASSIGNMENT_TRACKING_INFO', 
                            payload: {
                              data:response.data.data
                              }
                            });
                          }
                        }
                      })
                      .catch((error)=>{
                        let err = {componentName:'AssignmentTrackingInfo',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                  break;
                  case "UPDATE_ASSIGNMENT_TRACKING_INFO": 
                  endpoint = microServices.base + '/v1/company/cleaner/update-assignment-tracking/' + action.payload.company_id;
                  store.dispatch(isLoader(true));
                  APIservice.put(endpoint, action.payload.formData)
                  .then((response) => {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'success',
                        componentName: 'UpdateAssignmentTracking',
                        message: 'Assignment Tracking Information Updated Successfully!'
                      }
                    });
                  })
                  .catch((error) => {
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                          variant: 'danger',
                          message: 'Unable to Assignment tracking information!',
                          componentName: 'UpdateAssignmentTracking'
                        }
                      });
                    } else {
                      let err = { componentName: 'UpdateAssignmentTracking', statusCode: error.response.status, data: error.response.data };
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  })
                  break;

                case "UPDATE_COMPANY_HAS_AUTOPAY": 
                  endpoint = microServices.base + action.payload.url;
                  store.dispatch(isLoader(true));
                  APIservice.put(endpoint, action.payload.formData)
                  .then((response) => {
                    if(action?.message?.isMessageShow) {
                      store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                          variant: 'success',
                          componentName:action.payload.componentName,
                          message: action.payload.successMessage,
                        }
                      });
                    }
                  })
                  .catch((error) => {
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                          variant: 'danger',
                          message: 'Unable to Save information!',
                        }
                      });
                    } else {
                      let err = { componentName:action.payload.componentName, statusCode: error.response.status, data: error.response.data };
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  })
                  break;
            case "CREATE_DO_NOT_BILL":

              store.dispatch(isLoader(true));
              endpoint  = microServices.base+'/v1/company/manage-dnb';
              APIservice
              .post(endpoint, action.payload.formData)
              .then((response)=>{
                if (response) {
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant:'success',
                      message: `Subscription Saved Successfully`,
                      componentName:'CreatedCompanySubscription'
                    }
                  });
                }
              })
              .catch((error)=>{
                let err = {componentName:'CreatedCompanySubscription',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
              break;


            case "UPDATE_DO_NOT_BILL":
              
              store.dispatch(isLoader(true));
              endpoint  = microServices.base+'/v1/company/manage-dnb/'+action.payload.id;
              APIservice
              .put(endpoint, action.payload.formData)
              .then((response)=>{
                if (response) {
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant:'success',
                      message: `Subscription Updated Successfully`,
                      componentName:'UpdatedCompanySubscription'
                    }
                  });
                }
              })
              .catch((error)=>{
                let err = {componentName:'UpdatedCompanySubscription',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              });
              break;
              
                  case "HHS_WEEKLY_INVOICE_REPORT":
                    endpoint = microServices.base + '/v1/reports/hhs-weekly-invoice-report';
                    store.dispatch(isLoader(true));
                    APIservice.
                    getBlobDataForMultipleRecord(endpoint, action.payload.formData)
                    .then((response)=>{
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "hhs_weekly_invoice_"}_${Date.now()}.xlsx`);
                        document.body.appendChild(link);
                        link.click(); 
                        link.parentNode.removeChild(link);
                    })
                    .catch((error)=>{
                        if (error.response.status !== 422) {
                            store.dispatch({ 
                                type: 'SHOW_ALERT', 
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                    componentName: 'hhsWeeklyInvoiceReport',
                                }
                            });
                        } else {
                            let err = {componentName:'hhsWeeklyInvoiceReport',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                        store.dispatch(isLoader(false));
                    });
                break;

                case "HHS_EMPLOYEE_PIECE_REPORT": {
                  endpoint = `${microServices.base}/v1/company/staff/hhs_staff_piece_rate_tracker?${action.payload.filters}`;
                  store.dispatch(isLoader(true));
                  APIservice.getBlobDataWithParams(endpoint)
                    .then((response)=>{
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "hhs_employee_piece"}_${Date.now()}.csv`);
                        document.body.appendChild(link);
                        link.click(); 
                        link.parentNode.removeChild(link);
                    })
                    .catch((error)=>{
                        if (error.response.status !== 422) {
                            store.dispatch({ 
                                type: 'SHOW_ALERT', 
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                    componentName: 'HHSEmployeePieceReport',
                                }
                            });
                        } else {
                            let err = {componentName:'HHSEmployeePieceReport',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                        store.dispatch(isLoader(false));
                    });
                  break;
                }

                case "UPDATE_HK_PERFORAMANCE_COUNT":
                  endpoint = microServices.base+'/v1/company/staff/hk-performance-report';
                  APIservice
                  .post(endpoint, action.payload)
                      .then((response)=>{
                        if (response && response.data) {
                          store.dispatch({
                            type: 'SET_HK_PERFORM_COUNT',
                            payload: {
                              data: response.data
                            }
                          });
                        }
                      })
                      .catch((error)=>{
                        let err = {componentName:'StaffHKPerformanceCount', statusCode: error.response.status, data: error.response.data};
                        store.dispatch(setError(err));
                      })
                break;

                case "GET_COMPANY_SUBSCRIPTION":
                  endpoint = microServices.base+'/v1/company/manage-dnb/'+action.payload.company_id;
                  store.dispatch(isLoader(true));
                  APIservice
                    .get(endpoint)
                    .then((response)=>{
                      if(response && response.data){
                        store.dispatch({ 
                        type: 'SET_COMPANY_SUBSCRIPTION', 
                        payload: {
                          data:response.data.data
                          }
                        });
                      }
                    })
                    .catch((error)=>{
                      let err = {componentName:'companySubscrptionInfo',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
                case "UPDATE_PM_SERVICE_FEATURE":
                  endpoint = microServices.base+action.payload.url;
                  APIservice
                  .put(endpoint, action.payload.formData)
                      .then((response)=>{
                        if (response && response.data) {
                          store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant:'success',
                                message: action.payload.successMessage,
                                componentName:  action.payload.componentName,
                            }
                        });
                        }
                      })
                      .catch((error) => {
                        if (error.response.status !== 422) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'danger',
                              message: 'Unable to Save!',
                              componentName: 'addServiceFeature'
                            }
                          });
                        } else {
                          let err = { componentName: 'addServiceFeature', statusCode: error.response.status, data: error.response.data };
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })

                break;

              case "UPLOAD_SERVICE_TRACK_REPORT_TO_MERGE":
                endpoint = microServices.base + '/v1/merged/serviceTrackerReport';
                store.dispatch(isLoader(true));
                APIservice
                  .post(endpoint, action.payload.formData)
                  .then((response) => {
                    store.dispatch({
                      type: 'DOWNLOAD_MERGED_REPORT',
                      payload: { 'uploadFiledata': action.payload.formData }
                    });
                  })
                  .catch((error) => {
                    let err = { componentName: 'MergedServiceTrackerReport', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                  }).finally(() => {
                    store.dispatch(isLoader(false));
                  })
                break;

                case "DOWNLOAD_MERGED_REPORT":
                  endpoint = microServices.base+'/v1/merged/serviceTrackerReport';
                  store.dispatch(isLoader(true));
                  APIservice.
                  getBlobDataForMultipleRecord(endpoint, action.payload.uploadFiledata)
                  .then((response)=>{
                      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement('a');
                      link.href = blobUrl;
                      link.setAttribute('download', `${'mergedServiceTrackerReport'}_${Date.now()}.xlsx`);
                      document.body.appendChild(link);
                      link.click(); 
                      link.parentNode.removeChild(link);
                      store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: { 
                          variant:'success',
                          message: 'Merged Service Tracker Report downloaded successfully.',
                          componentName:"MergedServiceTrackerReport"
                        }
                      });
                  })
                  .catch((error)=>{
                          let err = {componentName:'MergedServiceTrackerReport',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                  })
                  .finally(()=>{
                      store.dispatch(isLoader(false));
                  });
              break;
              case "DROPDOWN_PROPELR_TEMPLATE":
                  endpoint = microServices.base+'/v1/dropdown/propelr-templates';
                      APIservice
                        .get(endpoint)
                        .then((response)=>{
                        
                          store.dispatch({ 
                            type: 'SET_DROPDOWN_PROPELR_TEMPLATE', 
                            payload: response?.data?.data,
                          });
                        })
                        .catch((error)=>{
                        })
                        
                    break;
              case "UPDATE_UPLISTING_COMPANY_INTEGRATION":
                {
                  store.dispatch(isLoader(true));
                        endpoint  = microServices.base+'/v1/company/integration/';
                        APIservice
                        .post(endpoint+action.payload.type, action.payload.formData)
                        .then((response)=>{
                          if (response) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: action.payload?.variant ? action.payload.variant : 'success',
                                message: `${action.payload.message} Connection Successfully saved`,
                                componentName:'updateUplistingCompanyIntegration'
                              }
                            });
                          }
                        })
                        .catch((error)=>{
                          if (error.response.status !== 422) {
                            store.dispatch({ 
                              type: 'SHOW_ALERT', 
                              payload: {
                                variant:'danger',
                                message: 'Invalid Uplisting Details',
                                componentName:'updateUplistingCompanyIntegration'
                              }
                            });
                          }
                          else{
                            let err = {componentName:'updateUplistingCompanyIntegration',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                        break;
                }

              case "UPDATE_HOSTFULLY_COMPANY_INTEGRATION":{
                store.dispatch(isLoader(true));
                      endpoint  = microServices.base+'/v1/company/integration/';
                      APIservice
                      .post(endpoint+action.payload.type, action.payload.formData)
                      .then((response)=>{
                        if (response) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: action.payload?.variant ? action.payload.variant : 'success',
                              message: `${action.payload.message} Connection Successfully saved`,
                              componentName:'updateHostfullyCompanyIntegration'
                            }
                          });
                        }
                      })
                      .catch((error)=>{
                        if (error.response.status !== 422) {
                          store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                              variant:'danger',
                              message: 'Invalid Hostfully Details',
                              componentName:'updateHostfullyCompanyIntegration'
                            }
                          });
                        }
                        else{
                          let err = {componentName:'updateHostfullyCompanyIntegration',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                        }
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                      break;
              }

      case "FETCH_INTEGRATION_PROPERTY_LIST":
        endpoint = microServices.base + '/v1/company/integration/dropdown/propertyList/'+action.payload?.type+'/'+action.payload?.manager_id;
        store.dispatch(isLoader(true));
        APIservice
          .get(endpoint)
          .then((response) => {
            if (response.status === 200) {
              let iMax = response.data;
              let propertiesDropdowns = [];
              iMax.forEach(property => {
                if(action.payload?.type=='track' || action.payload?.type=='trackWorkorder'){
                  propertiesDropdowns.push({ value: property.id, label: `${property.name} [${property.id}]` })
                }else{
                  propertiesDropdowns.push({ value: property._id, label: `${property.nickname} [${property._id}]` })
                }
              })
              store.dispatch({
                type: 'SET_INTEGRATION_PROPERTY_LIST_DROPDOWN',
                payload: {
                  data: propertiesDropdowns
                }
              });
            }
          })
          .catch((error) => {
            let err = { componentName: 'cleanersPm', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })
        break;
              case "UPDATE_AUTOSHARE_SUBTASK_IN_BULK":
                endpoint = microServices.base+'/v1/company/property-managers/subtask';
                APIservice
                  .put(endpoint,action.payload.formData)
                    .then((response)=>{
                      store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                          variant: 'success',
                          componentName:'UpdateAutoshareSubtaskBulk',
                          message: action?.payload?.successMessage
                        }
                      });
                  })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'danger',
                            message:  action?.payload?.failMessage,
                          }
                        });
                      } else {
                        let err = { componentName: 'UpdateAutoshareSubtaskBulk', statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                      }
                  })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  })
              break;

              case "UPDATE_WIZARD_STATUS":
                store.dispatch(isLoader(true));
                endpoint  = microServices.base + '/v1/update/wizardstatus/'+action.payload.status;
                APIservice
                  .put(endpoint, action.payload.formData)
                  .then((response) => {
                    let wizard_status = action.payload.status;
                    store.dispatch({
                      type: 'SET_WIZARD_STATUS',
                      payload: wizard_status,
                    });
                    store.dispatch({
                      type: 'GET_LOGGED_USER_DETAILS'
                    });
                })
                  .catch((error) => {
                    store.dispatch({
                      type: 'SET_WIZARD_STATUS',
                      payload: ''
                    });
                  })
                  .finally(()=>{
                    store.dispatch(isLoader(false));
                  })
            break;
            case "UPDATE_LODGIFY_COMPANY_INTEGRATION":
                {
                  store.dispatch(isLoader(true));
                        endpoint  = microServices.base+'/v1/company/integration/';
                        APIservice
                        .post(endpoint+action.payload.type, action.payload.formData)
                        .then((response)=>{
                          if (response) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: action.payload?.variant ? action.payload.variant : 'success',
                                message: `${action.payload.message} Connection Successfully saved`,
                                componentName:'updateLodgifyCompanyIntegration'
                              }
                            });
                          }
                        })
                        .catch((error)=>{
                          if (error.response.status !== 422) {
                            store.dispatch({ 
                              type: 'SHOW_ALERT', 
                              payload: {
                                variant:'danger',
                                message: 'Invalid Lodgify Details',
                                componentName:'updateLodgifyCompanyIntegration'
                              }
                            });
                          }
                          else{
                            let err = {componentName:'updateLodgifyCompanyIntegration',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                          }
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                        break;
                }
                case "ADD_STAFF_PLANNER_LISTING": {
                  let filters = action?.payload?.filters || '?';
                  endpoint = `${microServices.base}/v1/company/staff-planner/add-staff-list/${action.payload.params.date}${filters}&per_page=0`;                
                  store.dispatch(isLoader(true));
                  APIservice.get(endpoint)
                    .then((response) => {
                      if (response && response.data) {
                        store.dispatch({
                          type: 'SET_ADD_STAFF_PLANNER_LIST',
                          payload: {
                            data: response.data.data
                          }
                        });
                      }
                    })
                    .catch((error) => {
                      let err = {
                        componentName: 'AddStaffPlannerListing',
                        statusCode: error.response.status,
                        data: error.response.data
                      };
                      store.dispatch(setError(err));
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                
                  break;
                }
                case "ADD_STAFF_PLANNER_STAFF": {
                  endpoint = microServices.base + '/v1/company/staff-planner/add-staff';
                  store.dispatch(isLoader(true));
                  APIservice.post(endpoint, action.payload.formData)
                    .then((response) => {
                      if (response) {
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'success',
                            componentName: 'AddStaffPlannerStaff',
                            message: action.payload.successMessage,
                          }
                        });
                      }
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'danger',
                            message: action?.payload?.failMessage || 'Unable to add staff!',
                            componentName: 'AddStaffPlannerStaff'
                          }
                        });
                      } else {
                        let err = { componentName: 'AddStaffPlannerStaff', statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;
                }

                case "UPDATE_ALLURADIRECT_COMPANY_INTEGRATION": {
                  store.dispatch(isLoader(true));
                  endpoint = microServices.base + '/v1/company/integration/configuration/';
                  APIservice
                    .post(endpoint + action.payload.type, action.payload.formData)
                    .then((response) => {
                      if (response) {
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: action.payload?.variant ? action.payload.variant : 'success',
                            message: `${action.payload.message} Connection Successfully saved`,
                            componentName: 'updateAlluraDirectCompanyIntegration'
                          }
                        });
                      }
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'danger',
                            message: 'Invalid Credentials',
                            componentName: 'updateAlluraDirectCompanyIntegration'
                          }
                        });
                      }
                      else {
                        let err = { componentName: 'updateAlluraDirectCompanyIntegration', statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    })
                  break;
                }

                case "HHS_WEEKLY_INVOICE_NEW_REPORT":
                  endpoint = microServices.base + '/v2/reports/hhs-weekly-invoice-report';
                  store.dispatch(isLoader(true));
                  APIservice.
                    getBlobDataForMultipleRecord(endpoint, action.payload.formData)
                    .then((response) => {
                      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement('a');
                      link.href = blobUrl;
                      link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "hhs_weekly_invoice_"}_${Date.now()}.xlsx`);
                      document.body.appendChild(link);
                      link.click();
                      link.parentNode.removeChild(link);
                    })
                    .catch((error) => {
                      if (error.response.status !== 422) {
                        store.dispatch({
                          type: 'SHOW_ALERT',
                          payload: {
                            variant: 'danger',
                            message: 'Unable to Download Report!',
                            componentName: 'hhsWeeklyInvoiceNewReport',
                          }
                        });
                      } else {
                        let err = { componentName: 'hhsWeeklyInvoiceNewReport', statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                  break;

                  case "HHS_EMPLOYEE_PIECE_RATE_REPORT": {
                    endpoint = `${microServices.base}/v1/company/staff/hhs_employee_piece_rate_report?${action.payload.filters}`;
                    store.dispatch(isLoader(true));
                    APIservice.getBlobDataWithParams(endpoint)
                      .then((response)=>{
                          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "hhs_employee_piece_rate_report"}_${Date.now()}.csv`);
                          document.body.appendChild(link);
                          link.click(); 
                          link.parentNode.removeChild(link);
                      })
                      .catch((error)=>{
                          if (error.response.status !== 422) {
                              store.dispatch({ 
                                  type: 'SHOW_ALERT', 
                                  payload: {
                                      variant:'danger',
                                      message: 'Unable to Download Report!',
                                      componentName: 'HHSEmployeePieceReport',
                                  }
                              });
                          } else {
                              let err = {componentName:'HHSEmployeePieceReport',statusCode: error.response.status,data: error.response.data};
                              store.dispatch(setError(err));
                          }
                      })
                      .finally(()=>{
                          store.dispatch(isLoader(false));
                      });
                    break;
                  }

            

    default:
      break;
  }
  return result;
};


// PREPARE DROPDOWNS
const prepareAssociateList = (response) => {
  let ActiveAssociates = [];
  let InActiveAssociates = [];

  let AssociateList = [{
    label: "Active",
    options: ActiveAssociates,
  },
  {
    label: "Inactive",
    options: InActiveAssociates,
  },
  ];
  response.data.data.map((data) => {
    if (data.status.status_id == '1' && data?.company_status != '2') {
      ActiveAssociates.push({
        value: data.company.id,
        label: data.company.company_name,
        status : 1
      });
    }
    else if (data.status.status_id == '2' || data?.company_status == '2') {
      InActiveAssociates.push({
        value: data.company.id,
        label: data.company.company_name,
        status : 2
      });
    }
  });
  return AssociateList;
}

// DROPDOWN_SCHEDULE_STAFF_SHIFTS
const prepareScheduleStaffShiftsDropdown = (response) => {
  let preparedDropdown = [];
  response.data.map((item) => {
    preparedDropdown.push({
        value: item?.shift_id,
        label: item?.shift_nick_name,
        shift_start: item?.shift_start,
        shift_end: item?.shift_end,
        type: item?.type,
      });
  });
  return preparedDropdown;
}

export default CompanyMiddleware;